@charset "UTF-8";

//primeng variablen
:root {
 --surface-a: #1e1e1e;
--surface-b: #121212;
--surface-c: #272727;
--surface-d: #383838;
--surface-e: #1e1e1e;
--surface-f: #1e1e1e;
--text-color: rgba(255, 255, 255, 0.87);
--text-color-secondary: rgba(255, 255, 255, 0.6);
--primary-color: #64b5f6;
--primary-color-text: #212529;
--font-family: 'Open Sans', sans-serif;
}

//Variablen die auch global verwendet werden
:root
{
  /*Background allgemein*/
  --backgroundCommonContent: rgba(30, 30, 30);;
  /*Background disabled allgemein*/
  --backgroundCommonContentDisabled: dimgray;
  /*Background disabled Button*/
  --backgroundButtonDisabled: #7eb7e6;
  /*disabled color algemein*/
  --colorCommonContentDisabled: snow;
  /*Unter Überschrift in Tabelle */
  --colorTableHeader: lightgray;
  /*Menufarben Navigation*/
  --colorMenu: white;
  --backgroundMenu: rgb(31, 25, 25);;
  --colorMenuIcon: white;

  /*Menu Ende*/

  /*Aktives Item */
  --colorItemActive: #64b5f6;
  /*Backround aktiv*/
  --backgroundActive: #64b5f6;
  /*Trenner Liste */
  --colorDivider: snow;
  /*Aktion wie Add im Header einer Seite z.B. medientipps*/
  --colorActionButtonIcon: snow;

}


//lipo sass zuordnung für diese MAIN-CSS

/*Background allgemein*/
$backgroundCommonContent: var(--backgroundCommonContent);
/*Background disabled allgemein*/
$backgroundCommonContentDisabled: var(--backgroundCommonContentDisabled);
/*Background disabled Button*/
$backgroundButtonDisabled: var(--backgroundCommonContent);
/*disabled color algemein*/
$colorCommonContentDisabled: var(--colorCommonContentDisabled);
/*Unter Überschrift in Tabelle */
$colorTableHeader: var(--colorTableHeader);
/*Menufarben Navigation*/
// $colorMenu:  var(--colorMenu);
$backgroundMenu: var(--backgroundMenu);
$colorMenuIcon: var(--colorMenuIcon);
/*Menu Ende*/

/*Aktives Item */
$colorItemActive: var(--colorItemActive);
/*Backround aktiv*/
$backgroundActive:  var(--backgroundActive);
/*Trenner Liste */
$colorDivider: var(--colorDivider);
/*Aktion wie Add im Header einer Seite z.B. medientipps*/
$colorActionButtonIcon: var(--colorActionButtonIcon);


$paddingControlFactor:  1 !default ;
$marginControlFactor: 1 !default ;
$paddingRightFactor: 1 !default ;
$widthFactor: 1 !default ;
$heightFactor: 1 !default ;
$borderFactor: 1 !default ;


//https://blog.logrocket.com/how-to-create-better-themes-with-css-variables-5a3744105c74/
//https://sass-lang.com/documentation/variables
/*Fontsizes*/
$fontSizeDefault: var(--fontSizeDefault) !default;
$font-family: 'Open Sans', sans-serif;
$fontSizeBigger: 1.286rem !default;
$fontSizeSmaller: 0.875rem !default;
/**/

//colors
$colorforeground_Default: rgba(255, 255, 255, 0.87);
$color_background_Default: #1e1e1e;
$box_shadow: #93cbf9;
$black: #1e1e1e;
$color_border: #383838;
$box_shadow_2: #383838;
$color_paddingtop_1: #383838;
$black_40:  rgba(0, 0, 0, 0.4); // prüfen
$color_border_warn: #ef9a9a;
$color_active_1: #b3b3b3;
$color_highlight: #64b5f6;
// Daten
$data_background: #1e1e1e;
$checkbox_background: #6b6969;
$color_border_3: #6b6969;
$button_back_3: #1e1e1e;
$color_button_icon_1: #b3b3b3;

$color_picker_panel: #1e1e1e;
$color_picker_border: #383838;

$color_hover_enabled: #046fa1;
$color_rating_cancel: #f48fb1;
$color_active_4: #81d4fa;
$colorbackgr_button_enabled_hover: rgba(197, 225, 165, 0.04);
$color_backgr_button_active: rgba(197, 225, 165, 0.16);
$black_20: rgba(0, 0, 0, 0); // ?? passt wohl in anderen dark themas auch black
$black_14: rgba(0, 0, 0, 0.14);
$black_12: rgba(0, 0, 0, 0.12);
$color_active_secondary: #78909c;
$color_hover_secondary: #69838f;
$color_box_focus_secondary: #a1b1ba;
$colorback_active_secondry: #455a64;
$color_back_button_hover_enabled: rgba(100, 181, 246, 0.04);
$color_back_buttontext_active: rgba(100, 181, 246, 0.16);
$color_button_success: #c5e1a5;
$color_back_button_hover_success: #b2d788;
$color_box_buttonsuccess: #aae5aa;
$color_san_felix_approx: #aae5aa;
$color_apple_4_approx: rgba(52, 168, 53, 0.04);
$color_apple_16_approx: rgba(52, 168, 53, 0.16);
$color_back_warn: #ffe082;
$color_back_button_warning: #ffd65c;
$color_box_warning: #ffeab4;
$color_meteor_approx: #d38b10;
$colorback_buttontext_hover_enabled: rgba(100, 181, 246, 0.04);
$colorback_buttontext_active: rgba(100, 181, 246, 0.16);
$colorback_buttonhelp_active:#ce93d8;
$color_buttonhelp_box: #ce93d8;
$color_violet_eggplant_4_approx: rgba(156, 39, 176, 0.04);
$color_violet_eggplant_16_approx: rgba(156, 39, 176, 0.16);
$color_crimson_approx: #e91224;
$color_monza_approx: #c01120;
$color_sundown_approx: #f9b4ba;
$color_crimson_4_approx: rgba(233, 18, 36, 0.04);
$color_crimson_16_approx: rgba(233, 18, 36, 0.16);
$color_alabaster_approx: #656363;
$color_prussian_blue_approx: #0d5086;
$colorback_highlight_hover: #272727;
$black_24: rgba(0, 0, 0, 0.24);
$color_border_stepsnumber: #dee2e6;
$color_jordy_blue_approx: #7fbcec;
$color_surf_approx: #b7d8b7;
$color_message: dimgrey;;
$colorback_warn_message: #b6a87f;;
$color_message_hover: rgba(255, 255, 255, 0.3);
$color_athens_gray_approx: #ebedef;
$colorback_hover_4: rgba(255, 255, 255, 0.1);
$color_gallery: #f8f9fa;
$black_30: rgba(0, 0, 0, 0.3);
$colorback_galleryonitem: rgba(0, 0, 0, 0.5);
$colorbckgr_gallery_hover: #4c4c4c;
$colorbck_gallery_container: rgba(0, 0, 0, 0.9);
$color_link: rgba(100, 181, 246, 0.16);
$color_hover_link: rgba(100, 181, 246, 0.3616);


//fonts
$font_0: $font-family;
$font_icons: "PrimeIcons" !important;



//@extend-elements
//original selectors
//.p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next
%extend_1 {
	width: (2rem * ($borderFactor));
	height: (2rem * ($borderFactor));
	color: $color_border_3;
	border: 0 none;
	background: transparent;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 50%;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

//original selectors
//.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover
%extend_2 {
	color: $color_highlight;
	border-color: transparent;
	background: transparent;
}

//original selectors
//.p-datepicker .p-datepicker-header .p-datepicker-prev:focus, .p-datepicker .p-datepicker-header .p-datepicker-next:focus
%extend_3 {
	outline: 0 none;
	outline-offset: 0;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0 0 0.2rem $box_shadow;
}



* {
	//Instead of the line below you could use @include box-sizing($bs)
	box-sizing: border-box;
}
.p-component {
	font-family: $font_0;
	font-size: $fontSizeDefault;
	font-weight: normal;
	&:disabled {
		opacity: 0.5;
	}
}
.p-component-overlay {
	background-color: $black_40;
	//Instead of the line below you could use @include transition-duration($duration-1, $duration-2, $duration-3, $duration-4, $duration-5, $duration-6, $duration-7, $duration-8, $duration-9, $duration-10)
	transition-duration: 0.2s;
}
.p-disabled {
	opacity: 0.5;
}
.p-error {
	color: $color_border_warn;
}
.p-text-secondary {
	color: $color_active_1;
}
.pi {
	font-size: $fontSizeDefault;
}
.p-link {
	font-size: $fontSizeDefault;
	font-family: $font_0;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0 0.2rem $box_shadow;
	}
}
.p-autocomplete {
  .p-autocomplete-input
  {
    padding-right: (1.858rem  *($paddingRightFactor));
  }
	.p-autocomplete-loader {
		right: (0.429rem *($paddingRightFactor));
	}
	.p-autocomplete-multiple-container {
		padding: (0.2145rem * ($paddingControlFactor)) (0.429rem *($paddingControlFactor));
		.p-autocomplete-input-token {
			padding: (0.2145rem * ($paddingControlFactor)) 0;
			input {
				font-family: $font_0;
				font-size: $fontSizeDefault;
				color: $colorforeground_Default;
				padding: 0;
				margin: 0;
			}
		}
		.p-autocomplete-token {
			padding: (0.2145rem * ($paddingControlFactor)) (0.429rem *($paddingControlFactor));
			margin-right: (0.5rem * ($marginControlFactor));
			background: $color_highlight;
			color: $black;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			.p-autocomplete-token-icon {
				margin-left: (0.5rem * ($marginControlFactor));
			}
		}
		&:not(.p-disabled) {
			&:hover {
				border-color: $colorforeground_Default;
			}
			&.p-focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
				border-color: $color_highlight;
			}
		}
	}
	&.p-autocomplete-dd .p-autocomplete-loader {
		right: (2.786rem *($paddingRightFactor));
	}
}
.p-autocomplete-clear-icon {
  position: absolute;
  top: 50%;
  margin-top: -.5rem;
  cursor: pointer;
  right: (0.429rem *($paddingRightFactor));
}

.p-autocomplete-clearable {
  position: relative;
}


.p-autocomplete-panel {
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0.188rem 6px 0 $box_shadow_2;
	.p-autocomplete-items {
		padding: 0;
		.p-autocomplete-item {
			margin: 0;
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			border: 0 none;
			color: $colorforeground_Default;
			background: transparent;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			&:hover {
				color: $colorforeground_Default;
				background: $color_hover_enabled;
			}
			&.p-highlight {
				color: $black;
				background: $color_highlight;
			}
		}
		.p-autocomplete-item-group {
			margin: 0;
			padding: (0.857rem * ($paddingControlFactor));
			color: $colorforeground_Default;
			background: $data_background;
			font-weight: 700;
		}
	}
}
.p-datepicker {
	padding: (0.857rem * ($paddingControlFactor));
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border_3;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&:not(&.p-datepicker-inline) {
			background: $black;
			border: (0.063rem * ($borderFactor)) solid $color_border;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0.188rem 6px 0 $box_shadow_2;
			.p-datepicker-header {
				background: $black;
        color: $colorforeground_Default;
			}
		}
		&:not(&.p-disabled) {
			table td span:not(.p-highlight):not(.p-disabled) {
				&:hover {
					background: $color_hover_enabled;
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $box_shadow;
				}
			}
			.p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled) {
				&:hover {
          color: $colorforeground_Default;
          background: $color_hover_enabled;
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $box_shadow;
				}
			}
		}

	.p-datepicker-header  {
		padding: (0.5rem * ($paddingControlFactor));
		color: $colorforeground_Default;
		background: $black;
		font-weight: 700;
		margin: 0;
		border-bottom: 0 none;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-datepicker-prev {
			@extend %extend_1;
			&:focus {
				@extend %extend_3;
			}
			&:enabled:hover {
				@extend %extend_2;
			}
		}
		.p-datepicker-next {
			@extend %extend_1;
			&:focus {
				@extend %extend_3;
			}
			&:enabled:hover {
				@extend %extend_2;
			}
		}
		.p-datepicker-title {
      color: $colorforeground_Default;
			line-height: (2rem * ($heightFactor));
			select {
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $box_shadow;
					border-color: $color_highlight;
				}
			}
			.p-datepicker-month .p-datepicker-year {
				margin-right: (0.5rem * ($marginControlFactor));
			}
		}
	}
	table {
		font-size: $fontSizeDefault;
		margin-right: (0.857rem * ($marginControlFactor)) 0;
		th {
			padding: (0.5rem * ($paddingControlFactor));
			> span {
				width: (2.5rem * ($widthFactor));
				height: (2.5rem * ($heightFactor));
			}
		}
		td {
			padding: (0.5rem * ($paddingControlFactor));
			> span {
				width: (2.5rem * ($widthFactor));
				height: (2.5rem * ($heightFactor));
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 0.188rem;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, box-shadow 0.2s;
				border: (0.063rem * ($borderFactor)) solid transparent;
				&.p-highlight {
					color: $black;
					background: $color_highlight;
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $box_shadow;
				}
			}
			&.p-datepicker-today > span {
				background: $button_back_3;
				color: $colorforeground_Default;
				border-color: transparent;
				&.p-highlight {
					color: $black;
					background: $color_highlight;
				}
			}
		}
	}
	.p-datepicker-buttonbar {
		padding: (0.857rem * ($paddingControlFactor)) 0 (0.429rem * ($paddingControlFactor)) 0;
		border-top: (0.063rem * ($borderFactor)) solid $color_paddingtop_1;
		.p-button {
			width: auto;
		}
	}
	.p-timepicker {
		border-top: (0.063rem * ($borderFactor)) solid $color_paddingtop_1;
		padding: (0.857rem * ($paddingControlFactor)) 0 (0.429rem * ($paddingControlFactor)) 0;
		button {
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			color: $color_border_3;
			border: 0 none;
			background: transparent;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
			&:last-child {
				margin-top: (0.2rem * ($marginControlFactor));
			}
			&:enabled:hover {
				color: $color_highlight;
				border-color: transparent;
				background: transparent;
			}
		}
		span {
			font-size: ($fontSizeBigger);
		}
		> div {
			padding: 0 (0.429rem *($paddingControlFactor) );
		}
	}
	.p-monthpicker {
    color: $colorforeground_Default;
		margin-right: (0.857rem * ($marginControlFactor)) 0;
		.p-monthpicker-month {
			padding: (0.5rem * ($paddingControlFactor));
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			&.p-highlight {
				color: $black;
				background: $color_highlight;
			}
		}
	}
	&.p-datepicker-timeonly .p-timepicker {
		border-top: 0 none;
	}
	&.p-datepicker-multiple-month .p-datepicker-group {
		border-right: (0.063rem * ($borderFactor)) solid $color_paddingtop_1;
		padding-right: (0.857rem *($paddingControlFactor) );
		padding-left: (0.857rem *($paddingControlFactor) );
		padding-top: 0;
		padding-bottom: 0;
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
			border-right: 0 none;
		}
	}
}


//primeng 13
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: $colorforeground_Default;
  background: $black_12;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  font-weight: 700;
  padding: (0.5rem * ($paddingControlFactor));
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: $colorforeground_Default;
  background: $color_hover_enabled;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right:(0.5rem * ($marginControlFactor));
}

.p-datepicker .p-monthpicker {
  margin: (0.857rem * ($marginControlFactor)) 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: (0.5rem * ($paddingControlFactor));
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: 0.188rem;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: $colorforeground_Default;
  background: $color_highlight;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  color: $colorforeground_Default;
  background: $color_highlight;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $box_shadow;
}

.p-datepicker .p-yearpicker {
  margin-right: (0.857rem * ($marginControlFactor)) 0;
}
.p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: (0.5rem * ($paddingControlFactor));
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: 0.188rem;
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: $colorforeground_Default;
  background: $color_hover_enabled;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: $color_hover_enabled;
  color: $colorforeground_Default;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $box_shadow;;
}



@media screen and (max-width: 769px) {
	.p-datepicker table {
		th {
			padding: (0.25rem *($paddingControlFactor) );
		}
		td {
			padding: (0.25rem *($paddingControlFactor) );
		}
	}
}
.p-cascadeselect {
	background: $black;
	border: (0.063rem * ($borderFactor)) solid $color_border_3;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-cascadeselect-label {
		background: transparent;
		border: 0 none;
		padding: (0.429rem *($paddingControlFactor)) (0.429rem*($paddingControlFactor));
		&.p-placeholder {
			color: $color_button_icon_1;
		}
		&:enabled:focus {
			outline: 0 none;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: none;
		}
	}
	.p-cascadeselect-trigger {
		background: transparent;
		color: $color_active_1;
		width: (2.357rem *($widthFactor));
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
	}
	&:not(.p-disabled) {
		&:hover {
			border-color: $colorforeground_Default;
		}
		&.p-focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $box_shadow;
			border-color: $color_highlight;
		}
	}
}
.p-cascadeselect-panel {
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0.188rem 6px 0 $box_shadow_2;
	min-width: (12.5rem *($widthFactor) );
	.p-cascadeselect-items {
		padding: 0;
		.p-cascadeselect-item {
			margin: 0;
			border: 0 none;
			color: $colorforeground_Default;
			background: transparent;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			.p-cascadeselect-item-content {
				padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: inset 0 0 0 0.2rem $box_shadow;
				}
			}
			&.p-highlight {
				color: $black;
				background: $color_highlight;
			}
			.p-cascadeselect-group-icon {
				font-size: ($fontSizeSmaller);
			}
			&:not(.p-highlight):not(.p-disabled):hover {
				color: $colorforeground_Default;
        background: $color_hover_enabled;
			}
		}
	}
}
.p-input-filled {
	.p-cascadeselect {
		background: $data_background;
		&:not(.p-disabled) {
			&:hover {
				background-color: $data_background;
			}
			&.p-focus {
				background-color: $data_background;
			}
		}
	}
	.p-checkbox {
		.p-checkbox-box {
			background-color: $checkbox_background;
			&.p-highlight {
				background: $color_highlight;
			}
		}
		&:not(.p-checkbox-disabled) .p-checkbox-box {
			&:hover {
				background-color: $data_background;
			}
			&.p-highlight:hover {
				background: $color_hover_enabled;
			}
		}
	}
	.p-dropdown {
		background: $data_background;
		&:not(.p-disabled) {
			&:hover {
				color: $colorforeground_Default;
        background: $color_hover_enabled;
			}
			&.p-focus {
				background-color: $data_background;
			}
		}
	}
	.p-inputtext {
		background-color: $data_background;
		&:enabled {
			&:hover {
				background-color: $data_background;
			}
			&:focus {
				background-color: $data_background;
			}
		}
	}
	.p-multiselect {
		background: $data_background;
		&:not(.p-disabled) {
			&:hover {
				background-color: $data_background;
			}
			&.p-focus {
				background-color: $data_background;
			}
		}
	}
	.p-radiobutton .p-radiobutton-box {
		background-color: $data_background;
		&.p-highlight {
			background: $color_highlight;
			&:not(.p-disabled):hover {
				background: $color_hover_enabled;
			}
		}
		&:not(.p-disabled):hover {
			background-color: $data_background;
		}
	}
}
.p-checkbox {
	width: (1.25rem * $widthFactor);
	height: (1.25rem * $heightFactor);
	.p-checkbox-box {
		border: (0.063rem * ($borderFactor)) solid $color_border_3;
		background: $checkbox_background;
		width: (1.25rem * $widthFactor);
		height: (1.25rem * $heightFactor);
		color: $colorforeground_Default;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		.p-checkbox-icon {
			//Instead of the line below you could use @include transition-duration($duration-1, $duration-2, $duration-3, $duration-4, $duration-5, $duration-6, $duration-7, $duration-8, $duration-9, $duration-10)
			transition-duration: 0.2s;
			color: $black;
			font-size: (0.875rem * $paddingControlFactor);
		}
		&.p-highlight {
			border-color: $color_highlight;
			background: $color_highlight;
		}
	}
	&:not(.p-checkbox-disabled) .p-checkbox-box {
		&:hover {
			border-color: $colorforeground_Default;
		}
		&.p-focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $box_shadow;
			border-color: $color_highlight;
		}
		&.p-highlight:hover {
			border-color: $color_hover_enabled;
			background: $color_hover_enabled;
			color: $black;
		}
	}
}
.p-checkbox-label {
	margin-left: (0.5rem * ($marginControlFactor));
}
.p-highlight {
	.p-checkbox .p-checkbox-box {
		border-color: $black;
	}
	.p-radiobutton .p-radiobutton-box {
		border-color: $black;
	}
	.p-rating .p-rating-icon {
		color: $black;
	}
}
.p-colorpicker-preview {
	width: (2rem * ($widthFactor));
	height: (2rem * ($heightFactor));
}
.p-fluid {
	.p-button {
		width: 100%;
	}
	.p-button-icon-only {
		width: (2.357rem *($widthFactor));
	}
	.p-buttonset {
		display: flex;
		.p-button {
			flex: 1;
		}
	}
	.p-colorpicker-preview.p-inputtext {
		width: (2rem * ($widthFactor));
		height: (2rem * ($heightFactor));
	}
	.p-inputgroup .p-button {
		width: auto;
		&.p-button-icon-only {
			width: (2.357rem *($widthFactor) );
		}
	}
}
.p-colorpicker-panel {
	background: $color_picker_panel;
	border-color: $color_picker_border;
	.p-colorpicker-color-handle {
		border-color: $black;
	}
	.p-colorpicker-hue-handle {
		border-color: $black;
	}
}
.p-colorpicker-overlay-panel {
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0.188rem 6px 0 $box_shadow_2;
}
.p-dropdown {
	background: $black;
	border: (0.063rem * ($borderFactor)) solid $color_border_3;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-dropdown-label {

		background: transparent;
		border: 0 none;
		&.p-placeholder {
			color: $color_button_icon_1;
		}
		&:enabled:focus {
			outline: 0 none;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: none;
		}
	}
	.p-dropdown-trigger {
		background: transparent;
		color: $color_active_1;
		width: (2.357rem *($widthFactor) );
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
	}
	.p-dropdown-clear-icon {
		color: $color_active_1;
    right: (2.357rem *($paddingRightFactor));
	}
	&:not(.p-disabled) {
		&:hover {
			border-color: $colorforeground_Default;
		}
		&.p-focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $box_shadow;
			border-color: $color_highlight;
		}
	}
	&.p-dropdown-clearable .p-dropdown-label {
		padding-right: (1.429rem * ($paddingControlFactor));
	}
}
.p-dropdown-panel {
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0.188rem 6px 0 $box_shadow_2;
	.p-dropdown-header {
		padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
		border-bottom: (0.063rem * ($borderFactor)) solid $color_background_Default;
		color: $colorforeground_Default;
		background: $black;
		margin: 0;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-dropdown-filter {
			padding-right: (1.429rem * ($paddingControlFactor));
		}
		.p-dropdown-filter-icon {
			right: (0.429rem *($paddingRightFactor));
			color: $color_active_1;
		}
	}
	.p-dropdown-items {
		padding: 0;
		.p-dropdown-item {
			margin: 0;
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			border: 0 none;
			color: $colorforeground_Default;
			background: transparent;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			&.p-highlight {
				color: $black;
				background: $color_highlight;
			}
			&:not(.p-highlight):not(.p-disabled):hover {
        color: $colorforeground_Default;
        background: $color_hover_enabled;

			}
		}
		.p-dropdown-empty-message {
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			color: $colorforeground_Default;
			background: transparent;
		}
		.p-dropdown-item-group {
			margin: 0;
			padding: (0.857rem * ($paddingControlFactor));
			color: $colorforeground_Default;
			background: $data_background;
			font-weight: 700;
		}
	}
}
.p-editor-container {
	.p-editor-toolbar {
		background: $data_background;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		&.ql-snow {
			border: (0.063rem * ($borderFactor)) solid $color_border;
			.ql-stroke {
				stroke: $color_active_1;
			}
			.ql-fill {
				fill: $color_active_1;
			}
			.ql-picker {
				.ql-picker-label {
					border: 0 none;
					color: $color_active_1;
					&:hover {
						color: $colorforeground_Default;
						.ql-stroke {
							stroke: $colorforeground_Default;
						}
						.ql-fill {
							fill: $colorforeground_Default;
						}
					}
				}
				&.ql-expanded {
					.ql-picker-label {
						color: $colorforeground_Default;
						.ql-stroke {
							stroke: $colorforeground_Default;
						}
						.ql-fill {
							fill: $colorforeground_Default;
						}
					}
					.ql-picker-options {
						background: $black;
						border: (0.063rem * ($borderFactor)) solid $color_border;
						//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
						box-shadow: 0 0.188rem 6px 0 $box_shadow_2;
						//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
						border-radius: 0.188rem;
						padding: 0;
						.ql-picker-item {
							color: $colorforeground_Default;
							&:hover {
								color: $colorforeground_Default;
								background: $color_background_Default;
							}
						}
					}
					&:not(.ql-icon-picker) .ql-picker-item {
						padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
					}
				}
			}
		}
	}
	.p-editor-content {
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		&.ql-snow {
			border: (0.063rem * ($borderFactor)) solid $color_border;
		}
		.ql-editor {
			background: $black;
			color: $colorforeground_Default;
			//Instead of the line below you could use @include border-bottom-right-radius($radius)
			border-bottom-right-radius: 0.188rem;
			//Instead of the line below you could use @include border-bottom-left-radius($radius)
			border-bottom-left-radius: 0.188rem;
		}
	}
	.ql-snow.ql-toolbar {
		button {
			&:hover {
				color: $colorforeground_Default;
				.ql-stroke {
					stroke: $colorforeground_Default;
				}
				.ql-fill {
					fill: $colorforeground_Default;
				}
			}
			&:focus {
				color: $colorforeground_Default;
				.ql-stroke {
					stroke: $colorforeground_Default;
				}
				.ql-fill {
					fill: $colorforeground_Default;
				}
			}
			&.ql-active {
				color: $color_highlight;
				.ql-stroke {
					stroke: $color_highlight;
				}
				.ql-fill {
					fill: $color_highlight;
				}
				.ql-picker-label {
					color: $color_highlight;
				}
			}
		}
		.ql-picker-label.ql-active {
			color: $color_highlight;
			.ql-stroke {
				stroke: $color_highlight;
			}
			.ql-fill {
				fill: $color_highlight;
			}
			.ql-picker-label {
				color: $color_highlight;
			}
		}
		.ql-picker-item.ql-selected {
			color: $color_highlight;
			.ql-stroke {
				stroke: $color_highlight;
			}
			.ql-fill {
				fill: $color_highlight;
			}
			.ql-picker-label {
				color: $color_highlight;
			}
		}
	}
}
.p-inputgroup-addon {
	background: $color_background_Default;
	color: $color_active_1;
	border-top: (0.063rem * ($borderFactor)) solid $color_border_3;
	border-left: (0.063rem * ($borderFactor)) solid $color_border_3;
	border-bottom: (0.063rem * ($borderFactor)) solid $color_border_3;
	padding: (0.429rem *($paddingControlFactor)) (0.429rem *($paddingControlFactor));
	min-width: (2.357rem *($widthFactor));
	&:last-child {
		border-right: (0.063rem * ($borderFactor)) solid $color_border_3;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
	}
	&:first-child {
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
	}
}
.p-inputgroup {
	> {
		.p-component {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			margin: 0;
			&:focus {
				z-index: 1;
				~ label {
					z-index: 1;
				}
			}
			+ .p-inputgroup-addon {
				border-left: 0 none;
			}
		}
		.p-float-label > .p-component {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			margin: 0;
			&:focus {
				z-index: 1;
				~ label {
					z-index: 1;
				}
			}
			+ .p-inputgroup-addon {
				border-left: 0 none;
			}
		}
	}
	button {
		&:first-child {
			//Instead of the line below you could use @include border-top-left-radius($radius)
			border-top-left-radius: 0.188rem;
			//Instead of the line below you could use @include border-bottom-left-radius($radius)
			border-bottom-left-radius: 0.188rem;
		}
		&:last-child {
			//Instead of the line below you could use @include border-top-right-radius($radius)
			border-top-right-radius: 0.188rem;
			//Instead of the line below you could use @include border-bottom-right-radius($radius)
			border-bottom-right-radius: 0.188rem;
		}
	}
	input {
		&:first-child {
			//Instead of the line below you could use @include border-top-left-radius($radius)
			border-top-left-radius: 0.188rem;
			//Instead of the line below you could use @include border-bottom-left-radius($radius)
			border-bottom-left-radius: 0.188rem;
		}
		&:last-child {
			//Instead of the line below you could use @include border-top-right-radius($radius)
			border-top-right-radius: 0.188rem;
			//Instead of the line below you could use @include border-bottom-right-radius($radius)
			border-bottom-right-radius: 0.188rem;
		}
	}
	.p-float-label {
		&:first-child input {
			//Instead of the line below you could use @include border-top-left-radius($radius)
			border-top-left-radius: 0.188rem;
			//Instead of the line below you could use @include border-bottom-left-radius($radius)
			border-bottom-left-radius: 0.188rem;
		}
		&:last-child input {
			//Instead of the line below you could use @include border-top-right-radius($radius)
			border-top-right-radius: 0.188rem;
			//Instead of the line below you could use @include border-bottom-right-radius($radius)
			border-bottom-right-radius: 0.188rem;
		}
	}
}
.p-inputswitch {
	width: (3rem * $marginControlFactor);
	height: (1.75rem *$marginControlFactor);
	.p-inputswitch-slider {
		background: $color_border;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 1.875rem;
		&:before {
			background: $black;
      width: (1.25rem * $marginControlFactor);
			height: (1.25rem * $marginControlFactor);
			left: (0.25rem * $marginControlFactor);
			margin-top: (-0.625rem * $marginControlFactor);
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include transition-duration($duration-1, $duration-2, $duration-3, $duration-4, $duration-5, $duration-6, $duration-7, $duration-8, $duration-9, $duration-10)
			transition-duration: 0.2s;
		}

	}
	&.p-inputswitch-checked {
		.p-inputswitch-slider {
			background: $color_highlight;
			&:before {
				//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
				transform: translateX(( 1.25rem *  $marginControlFactor));
				background: $color_border;

			}
		}
		&:not(.p-disabled):hover .p-inputswitch-slider {
			background: $color_hover_enabled;
		}
	}
	&.p-focus .p-inputswitch-slider {
		outline: 0 none;
		outline-offset: 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0 0.2rem $box_shadow;
	}
	&:not(.p-disabled):hover .p-inputswitch-slider {
		background: $color_hover_enabled;
	}
}
.p-inputtext {
	font-family: $font_0;
	font-size: $fontSizeDefault;
	color: $colorforeground_Default;
	background: $black;
	padding: (0.429rem *($paddingControlFactor)) (0.429rem *($paddingControlFactor));
	border: (0.063rem * ($borderFactor)) solid $color_border_3;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	appearance: none;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&:enabled {
		&:hover {
			border-color: $colorforeground_Default;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $box_shadow;
			border-color: $color_highlight;
		}
	}
	&.p-inputtext-sm {
		font-size: ($fontSizeSmaller);
		padding: (0.375375rem * $paddingControlFactor) (0.375375rem * $paddingControlFactor);
	}
	&.p-inputtext-lg {
		font-size: $fontSizeBigger;
		padding: (0.53625rem * $paddingControlFactor)  ( 0.53625rem * $paddingControlFactor);
	}
	&.ng-dirty.ng-invalid {
		border-color: $color_border_warn;
	}
}
.p-float-label {
	> label {
		left: (0.429rem * $marginControlFactor);
		color: $color_button_icon_1;
		//Instead of the line below you could use @include transition-duration($duration-1, $duration-2, $duration-3, $duration-4, $duration-5, $duration-6, $duration-7, $duration-8, $duration-9, $duration-10)
		transition-duration: 0.2s;
	}
	> .ng-invalid.ng-dirty + label {
		color: $color_border_warn;
	}
}
.p-input-icon-left {
	> i:first-of-type {
		left: (0.429rem * $marginControlFactor);
		color: $color_active_1;
	}
	> .p-inputtext {
		padding-left: (1.858rem * $paddingControlFactor);
	}
	&.p-float-label > label {
		left: (1.858rem * $paddingControlFactor);
	}
}
.p-input-icon-right {
	> i:last-of-type {
		right: (0.429rem *($paddingRightFactor));
		color: $color_active_1;
	}
	> .p-inputtext {
		padding-right: (1.858rem * $paddingControlFactor);
	}
}
::-webkit-input-placeholder {
	color: $color_button_icon_1;
}
:-moz-placeholder {
	color: $color_button_icon_1;
}
::-moz-placeholder {
	color: $color_button_icon_1;
}
:-ms-input-placeholder {
	color: $color_button_icon_1;
}
.p-listbox {
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border_3;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-listbox-header {
		padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
		border-bottom: (0.063rem * ($borderFactor)) solid $color_background_Default;
		color: $colorforeground_Default;
		background: $black;
		margin: 0;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-listbox-filter {
			padding-right: (1.429rem * ($paddingControlFactor));
		}
		.p-listbox-filter-icon {
			right: (0.429rem *($paddingRightFactor));
			color: $color_active_1;
		}
		.p-checkbox {
			margin-right: (0.5rem * ($marginControlFactor));
		}
	}
	.p-listbox-list {
		padding: 0;
		.p-listbox-item {
			margin: 0;
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			border: 0 none;
			color: $colorforeground_Default;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			&.p-highlight {
				color: $black;
				background: $color_highlight;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 0 0 0.2rem $box_shadow;
			}
			.p-checkbox {
				margin-right: (0.5rem * ($marginControlFactor));
			}
		}
		.p-listbox-item-group {
			margin: 0;
			padding: (0.857rem * ($paddingControlFactor));
			color: $colorforeground_Default;
			background: $data_background;
			font-weight: 700;
		}
	}
	&:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
		color: $colorforeground_Default;
		background: $color_background_Default;
	}
}
.p-multiselect {
	background: $black;
	border: (0.063rem * ($borderFactor)) solid $color_border_3;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-multiselect-label {
		padding: (0.429rem *($paddingControlFactor)) (0.429rem *($paddingControlFactor));
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		&.p-placeholder {
			color: $color_button_icon_1;
		}
	}
	.p-multiselect-trigger {
		background: transparent;
		color: $color_active_1;
		width: (2.357rem *($widthFactor) );
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
	}
	&:not(.p-disabled) {
		&:hover {
			border-color: $colorforeground_Default;
		}
		&.p-focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $box_shadow;
			border-color: $color_highlight;
		}
	}
	&.p-multiselect-chip .p-multiselect-token {
    padding: (0.2145rem * ($paddingControlFactor)) (0.429rem *($paddingControlFactor));
		margin-right: (0.5rem * ($marginControlFactor));
    background: $color_highlight;
		color: $black;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
		.p-multiselect-token-icon {
			margin-left: (0.5rem * ($marginControlFactor));
		}
	}
}
.p-multiselect-panel {
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0.188rem 6px 0 $box_shadow_2;
	.p-multiselect-header {
		padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
		border-bottom: (0.063rem * ($borderFactor)) solid $color_background_Default;
		color: $colorforeground_Default;
		background: $black;
		margin: 0;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-multiselect-filter-container {
			.p-inputtext {
				padding-right: (1.429rem * ($paddingControlFactor));
			}
			.p-multiselect-filter-icon {
				right: (0.429rem *($paddingRightFactor));
				color: $color_active_1;
			}
		}
		.p-checkbox {
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-multiselect-close {
      margin-left: (0.5rem * ($marginControlFactor));
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			color: $color_border_3;
			border: 0 none;
			background: transparent;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
			&:enabled:hover {
				color: $color_highlight;
				border-color: transparent;
				background: transparent;
			}
		}
	}
	.p-multiselect-items {
		padding: 0;
		.p-multiselect-item {
			margin: 0;
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			border: 0 none;
			color: $colorforeground_Default;
			background: transparent;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			&.p-highlight {
				color: $black;
				background: $color_highlight;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 0 0 0.2rem $box_shadow;
			}
			.p-checkbox {
				margin-right: (0.5rem * ($marginControlFactor));
			}
			&:not(.p-highlight):not(.p-disabled):hover {
				color: $colorforeground_Default;
        background: $color_hover_enabled;
			}
		}
		.p-multiselect-item-group {
			margin: 0;
			padding: (0.857rem * ($paddingControlFactor));
			color: $colorforeground_Default;
			background: $data_background;
			font-weight: 700;
		}
		.p-multiselect-empty-message {
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			color: $colorforeground_Default;
			background: transparent;
		}
	}
}



p-multiselect.p-multiselect-clearable .p-multiselect-label-container {
  padding-right: (1.429rem * ($paddingControlFactor));
}

p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon {
  color: $colorforeground_Default;
  right: (2.357rem *($paddingRightFactor));
}


.p-password-panel {
	padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0.188rem 6px 0 $box_shadow_2;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-password-meter {
		margin-bottom: (0.5rem *($marginControlFactor) );
	}
}
.p-radiobutton {
	width: (1.25rem * $widthFactor);
	height: (1.25rem * $heightFactor);
	.p-radiobutton-box {
		border: (0.063rem * ($borderFactor)) solid $color_border_3;
		background: $black;
		width: (1.25rem * $borderFactor);
		height: (1.25rem *$borderFactor);
		color: $colorforeground_Default;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50%;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		.p-radiobutton-icon {
			width: (0.75rem * $widthFactor);
			height: (0.75rem * $heightFactor);
			//Instead of the line below you could use @include transition-duration($duration-1, $duration-2, $duration-3, $duration-4, $duration-5, $duration-6, $duration-7, $duration-8, $duration-9, $duration-10)
			transition-duration: 0.2s;
			background-color: $black;
		}
		&.p-highlight {
			border-color: $color_highlight;
			background: $color_highlight;
			&:not(.p-disabled):hover {
				border-color: $color_hover_enabled;
				background: $color_hover_enabled;
				color: $black;
			}
		}
		&:not(.p-disabled) {
			&.p-focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
				border-color: $color_highlight;
			}
			&:not(.p-highlight):hover {
				border-color: $colorforeground_Default;
			}
		}
	}
}
.p-radiobutton-label {
	margin-left: (0.5rem * ($marginControlFactor));
}
.p-rating {
	.p-rating-icon {
		color: $colorforeground_Default;
		margin-left: (0.5rem * ($marginControlFactor));
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		font-size: 1.143rem;
		&.p-rating-cancel {
			color: $color_rating_cancel;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $box_shadow;
		}
		&:first-child {
			margin-left: 0;
		}
		&.pi-star {
			color: $color_highlight;
		}
	}
	&:not(.p-disabled):not(.p-readonly) .p-rating-icon {
		&:hover {
			color: $color_highlight;
		}
		&.p-rating-cancel:hover {
			color: $color_active_4;
		}
	}
}
.p-slider {
	background: $color_border;
	border: 0 none;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&.p-slider-horizontal {
		height: (0.286rem * $heightFactor);
		.p-slider-handle {
			margin-top: -0.5715rem;
			margin-left: -0.5715rem;
		}
	}
	&.p-slider-vertical {
		width: (0.286rem * $widthFactor);
		.p-slider-handle {
			margin-left: -0.5715rem;
			margin-bottom: -0.5715rem;
		}
	}
	.p-slider-handle {
		height: (1.143rem * $heightFactor);
		width: (1rem * $widthFactor);
		background: $black;
		border: 2px solid $color_button_icon_1;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 100%;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $box_shadow;
		}
	}
	.p-slider-range {
		background: $color_highlight;
	}
	&.p-slider-animate {
		&.p-slider-horizontal {
			.p-slider-handle {
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
			}
			.p-slider-range {
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: width 0.2s;
			}
		}
		&.p-slider-vertical {
			.p-slider-handle {
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
			}
			.p-slider-range {
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: height 0.2s;
			}
		}
	}
	&:not(.p-disabled) .p-slider-handle:hover {
		background: 2px solid $color_button_icon_1;
		border-color: $color_highlight;
	}
}
.p-button {
	color: $black;
	background: $color_highlight;
	border: (0.063rem * ($borderFactor)) solid $color_highlight;
	padding: (0.429rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	font-size: $fontSizeDefault;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&:enabled {
		&:hover {
			background: $color_hover_enabled;
			color: $black;
			border-color: $color_hover_enabled;
		}
		&:active {
			background: $color_hover_enabled;
			color: $black;
			border-color: $color_hover_enabled;
		}
	}
	&.p-button-outlined {
		background-color: transparent;
		color: $color_highlight;
		border: (0.063rem * ($borderFactor)) solid;
		&:enabled {
			&:hover {
				background: $colorbackgr_button_enabled_hover;
				color: $color_highlight;
				border: (0.063rem * ($borderFactor)) solid;
			}
			&:active {
				background: $color_backgr_button_active;
				color: $color_highlight;
				border: (0.063rem * ($borderFactor)) solid;
			}
		}
		&.p-button-plain {
			color: $color_active_1;
			border-color: $color_active_1;
			&:enabled {
				&:hover {
					background: $color_background_Default;
					color: $color_active_1;
				}
				&:active {
					background: $button_back_3;
					color: $color_active_1;
				}
			}
		}
	}
	&.p-button-text {
		background-color: transparent;
		color: $color_highlight;
		border-color: transparent;
		&:enabled {
			&:hover {
				background: $colorbackgr_button_enabled_hover;
				color: $color_highlight;
				border-color: transparent;
			}
			&:active {
				background: $color_backgr_button_active;
				color: $color_highlight;
				border-color: transparent;
			}
		}
		&.p-button-plain {
			color: $color_active_1;
			&:enabled {
				&:hover {
					background: $color_background_Default;
					color: $color_active_1;
				}
				&:active {
					background: $button_back_3;
					color: $color_active_1;
				}
			}
		}
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0 0.2rem $box_shadow;
	}
	.p-button-icon-left {
		margin-right: (0.5rem * ($marginControlFactor));
	}
	.p-button-icon-right {
		margin-left: (0.5rem * ($marginControlFactor));
	}
	.p-button-icon-bottom {
		margin-top: (0.5rem *($marginControlFactor) );
	}
	.p-button-icon-top {
		margin-bottom: (0.5rem *($marginControlFactor) );
	}
	.p-badge {
		margin-left: (0.5rem * ($marginControlFactor));
		min-width: (2rem * ($borderFactor));
		height: (2rem * ($borderFactor));
		line-height: (1rem * ($heightFactor));
		color: $color_highlight;
		background-color: $black;
	}
	&.p-button-raised {
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0.188rem (0.063rem * ($borderFactor)) -2px $black_20, 0 2px 2px 0 $black_14, 0 (0.063rem * ($borderFactor)) 0.313rem 0 $black_12;
	}
	&.p-button-rounded {
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 2rem;
	}
	&.p-button-icon-only {
		width: (2.357rem *($widthFactor) );
		padding: (0.429rem * $paddingControlFactor) 0;
		.p-button-icon-left {
			margin: 0;
		}
		.p-button-icon-right {
			margin: 0;
		}
		&.p-button-rounded {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			height: (2.357rem * $heightFactor);
		}
	}
	&.p-button-sm {
		font-size: ($fontSizeSmaller);
		padding: (0.375375rem *$paddingControlFactor) (0.875rem *$paddingControlFactor);
		.p-button-icon {
			font-size: ($fontSizeSmaller);
		}
	}
	&.p-button-lg {
		font-size: ($fontSizeBigger);
		padding: (0.53625rem * $paddingControlFactor) (1.25rem * $paddingControlFactor);
		.p-button-icon {
			font-size: ($fontSizeBigger);
		}
	}
	&.p-button-secondary {
		color: $black;
		background: $color_active_secondary;
		border: (0.063rem * ($borderFactor)) solid $color_active_secondary;
		&:enabled {
			&:hover {
				background: $color_hover_secondary;
				color: $black;
				border-color: $color_hover_secondary;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_box_focus_secondary;
			}
			&:active {
				background: $colorback_active_secondry;
				color: $black;
				border-color: $colorback_active_secondry;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_active_secondary;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_back_button_hover_enabled;
					color: $color_active_secondary;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_back_buttontext_active;
					color: $color_active_secondary;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_active_secondary;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_back_button_hover_enabled;
					border-color: transparent;
					color: $color_active_secondary;
				}
				&:active {
					background: $color_back_buttontext_active;
					border-color: transparent;
					color: $color_active_secondary;
				}
			}
		}
	}
	&.p-button-info {
		color: $black;
		background: $color_highlight;
		border: (0.063rem * ($borderFactor)) solid $color_highlight;
		&:enabled {
			&:hover {
				background: $color_hover_enabled;
				color: $black;
				border-color: $color_hover_enabled;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
			&:active {
				background: $color_hover_enabled;
				color: $black;
				border-color: $color_hover_enabled;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_highlight;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $colorbackgr_button_enabled_hover;
					color: $color_highlight;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_backgr_button_active;
					color: $color_highlight;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_highlight;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $colorbackgr_button_enabled_hover;
					border-color: transparent;
					color: $color_highlight;
				}
				&:active {
					background: $color_backgr_button_active;
					border-color: transparent;
					color: $color_highlight;
				}
			}
		}
	}
	&.p-button-success {
		color: $black;
		background: $color_button_success;
		border: (0.063rem * ($borderFactor)) solid $color_button_success;
		&:enabled {
			&:hover {
				background: $color_back_button_hover_success;
				color: $black;
				border-color: $color_back_button_hover_success;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_box_buttonsuccess;
			}
			&:active {
				background: $color_san_felix_approx;
				color: $black;
				border-color: $color_san_felix_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_button_success;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_apple_4_approx;
					color: $color_button_success;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_apple_16_approx;
					color: $color_button_success;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_button_success;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_apple_4_approx;
					border-color: transparent;
					color: $color_button_success;
				}
				&:active {
					background: $color_apple_16_approx;
					border-color: transparent;
					color: $color_button_success;
				}
			}
		}
	}
	&.p-button-warning {
		color: $colorforeground_Default;
		background: $color_back_warn;
		border: (0.063rem * ($borderFactor)) solid $color_back_warn;
		&:enabled {
			&:hover {
				background: $color_back_button_warning;
				color: $colorforeground_Default;
				border-color: $color_back_button_warning;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_box_warning;
			}
			&:active {
				background: $color_meteor_approx;
				color: $colorforeground_Default;
				border-color: $color_meteor_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_back_warn;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $colorback_buttontext_hover_enabled;
					color: $color_back_warn;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $colorback_buttontext_active;
					color: $color_back_warn;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_back_warn;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $colorback_buttontext_hover_enabled;
					border-color: transparent;
					color: $color_back_warn;
				}
				&:active {
					background: $colorback_buttontext_active;
					border-color: transparent;
					color: $color_back_warn;
				}
			}
		}
	}
	&.p-button-help {
		color: $black;
		background: $color_active_4;
		border: (0.063rem * ($borderFactor)) solid $color_active_4;
		&:enabled {
			&:hover {
				background: $colorback_buttonhelp_active;
				color: $black;
				border-color: $colorback_buttonhelp_active;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_buttonhelp_box;
			}
			&:active {
				background: $colorback_buttonhelp_active;
				color: $black;
				border-color: $colorback_buttonhelp_active;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_active_4;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_violet_eggplant_4_approx;
					color: $color_active_4;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_violet_eggplant_16_approx;
					color: $color_active_4;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_active_4;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_violet_eggplant_4_approx;
					border-color: transparent;
					color: $color_active_4;
				}
				&:active {
					background: $color_violet_eggplant_16_approx;
					border-color: transparent;
					color: $color_active_4;
				}
			}
		}
	}
	&.p-button-danger {
		color: $black;
		background: $color_crimson_approx;
		border: (0.063rem * ($borderFactor)) solid $color_crimson_approx;
		&:enabled {
			&:hover {
				background: $color_monza_approx;
				color: $black;
				border-color: $color_monza_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_sundown_approx;
			}
			&:active {
				background: $color_border_warn;
				color: $black;
				border-color: $color_border_warn;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_crimson_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_crimson_4_approx;
					color: $color_crimson_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_crimson_16_approx;
					color: $color_crimson_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_crimson_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_crimson_4_approx;
					border-color: transparent;
					color: $color_crimson_approx;
				}
				&:active {
					background: $color_crimson_16_approx;
					border-color: transparent;
					color: $color_crimson_approx;
				}
			}
		}
	}
	&.p-button-link {
		color: $color_hover_enabled;
		background: transparent;
		border: transparent;
		&:enabled {
			&:hover {
				background: transparent;
				color: $color_hover_enabled;
				border-color: transparent;
				.p-button-label {
					text-decoration: underline;
				}
			}
			&:focus {
				background: transparent;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
				border-color: transparent;
			}
			&:active {
				background: transparent;
				color: $color_hover_enabled;
				border-color: transparent;
			}
		}
	}
}
.p-buttonset {
	&.p-button-secondary > .p-button {
		color: $black;
		background: $color_active_secondary;
		border: (0.063rem * ($borderFactor)) solid $color_active_secondary;
		&:enabled {
			&:hover {
				background: $color_hover_secondary;
				color: $black;
				border-color: $color_hover_secondary;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_box_focus_secondary;
			}
			&:active {
				background: $colorback_active_secondry;
				color: $black;
				border-color: $colorback_active_secondry;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_active_secondary;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_back_button_hover_enabled;
					color: $color_active_secondary;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_back_buttontext_active;
					color: $color_active_secondary;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_active_secondary;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_back_button_hover_enabled;
					border-color: transparent;
					color: $color_active_secondary;
				}
				&:active {
					background: $color_back_buttontext_active;
					border-color: transparent;
					color: $color_active_secondary;
				}
			}
		}
	}
	&.p-button-info > .p-button {
		color: $black;
		background: $color_highlight;
		border: (0.063rem * ($borderFactor)) solid $color_highlight;
		&:enabled {
			&:hover {
				background: $color_hover_enabled;
				color: $black;
				border-color: $color_hover_enabled;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
			&:active {
				background: $color_hover_enabled;
				color: $black;
				border-color: $color_hover_enabled;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_highlight;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $colorbackgr_button_enabled_hover;
					color: $color_highlight;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_backgr_button_active;
					color: $color_highlight;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_highlight;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $colorbackgr_button_enabled_hover;
					border-color: transparent;
					color: $color_highlight;
				}
				&:active {
					background: $color_backgr_button_active;
					border-color: transparent;
					color: $color_highlight;
				}
			}
		}
	}
	&.p-button-success > .p-button {
		color: $black;
		background: $color_button_success;
		border: (0.063rem * ($borderFactor)) solid $color_button_success;
		&:enabled {
			&:hover {
				background: $color_back_button_hover_success;
				color: $black;
				border-color: $color_back_button_hover_success;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_box_buttonsuccess;
			}
			&:active {
				background: $color_san_felix_approx;
				color: $black;
				border-color: $color_san_felix_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_button_success;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_apple_4_approx;
					color: $color_button_success;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_apple_16_approx;
					color: $color_button_success;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_button_success;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_apple_4_approx;
					border-color: transparent;
					color: $color_button_success;
				}
				&:active {
					background: $color_apple_16_approx;
					border-color: transparent;
					color: $color_button_success;
				}
			}
		}
	}
	&.p-button-warning > .p-button {
		color: $colorforeground_Default;
		background: $color_back_warn;
		border: (0.063rem * ($borderFactor)) solid $color_back_warn;
		&:enabled {
			&:hover {
				background: $color_back_button_warning;
				color: $colorforeground_Default;
				border-color: $color_back_button_warning;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_box_warning;
			}
			&:active {
				background: $color_meteor_approx;
				color: $colorforeground_Default;
				border-color: $color_meteor_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_back_warn;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $colorback_buttontext_hover_enabled;
					color: $color_back_warn;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $colorback_buttontext_active;
					color: $color_back_warn;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_back_warn;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $colorback_buttontext_hover_enabled;
					border-color: transparent;
					color: $color_back_warn;
				}
				&:active {
					background: $colorback_buttontext_active;
					border-color: transparent;
					color: $color_back_warn;
				}
			}
		}
	}
	&.p-button-help > .p-button {
		color: $black;
		background: $color_active_4;
		border: (0.063rem * ($borderFactor)) solid $color_active_4;
		&:enabled {
			&:hover {
				background: $colorback_buttonhelp_active;
				color: $black;
				border-color: $colorback_buttonhelp_active;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_buttonhelp_box;
			}
			&:active {
				background: $colorback_buttonhelp_active;
				color: $black;
				border-color: $colorback_buttonhelp_active;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_active_4;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_violet_eggplant_4_approx;
					color: $color_active_4;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_violet_eggplant_16_approx;
					color: $color_active_4;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_active_4;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_violet_eggplant_4_approx;
					border-color: transparent;
					color: $color_active_4;
				}
				&:active {
					background: $color_violet_eggplant_16_approx;
					border-color: transparent;
					color: $color_active_4;
				}
			}
		}
	}
	&.p-button-danger > .p-button {
		color: $black;
		background: $color_crimson_approx;
		border: (0.063rem * ($borderFactor)) solid $color_crimson_approx;
		&:enabled {
			&:hover {
				background: $color_monza_approx;
				color: $black;
				border-color: $color_monza_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_sundown_approx;
			}
			&:active {
				background: $color_border_warn;
				color: $black;
				border-color: $color_border_warn;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_crimson_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_crimson_4_approx;
					color: $color_crimson_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_crimson_16_approx;
					color: $color_crimson_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_crimson_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_crimson_4_approx;
					border-color: transparent;
					color: $color_crimson_approx;
				}
				&:active {
					background: $color_crimson_16_approx;
					border-color: transparent;
					color: $color_crimson_approx;
				}
			}
		}
	}
}
.p-splitbutton {
	&.p-button-secondary > .p-button {
		color: $black;
		background: $color_active_secondary;
		border: (0.063rem * ($borderFactor)) solid $color_active_secondary;
		&:enabled {
			&:hover {
				background: $color_hover_secondary;
				color: $black;
				border-color: $color_hover_secondary;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_box_focus_secondary;
			}
			&:active {
				background: $colorback_active_secondry;
				color: $black;
				border-color: $colorback_active_secondry;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_active_secondary;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_back_button_hover_enabled;
					color: $color_active_secondary;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_back_buttontext_active;
					color: $color_active_secondary;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_active_secondary;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_back_button_hover_enabled;
					border-color: transparent;
					color: $color_active_secondary;
				}
				&:active {
					background: $color_back_buttontext_active;
					border-color: transparent;
					color: $color_active_secondary;
				}
			}
		}
	}
	&.p-button-info > .p-button {
		color: $black;
		background: $color_highlight;
		border: (0.063rem * ($borderFactor)) solid $color_highlight;
		&:enabled {
			&:hover {
				background: $color_hover_enabled;
				color: $black;
				border-color: $color_hover_enabled;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
			&:active {
				background: $color_hover_enabled;
				color: $black;
				border-color: $color_hover_enabled;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_highlight;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $colorbackgr_button_enabled_hover;
					color: $color_highlight;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_backgr_button_active;
					color: $color_highlight;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_highlight;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $colorbackgr_button_enabled_hover;
					border-color: transparent;
					color: $color_highlight;
				}
				&:active {
					background: $color_backgr_button_active;
					border-color: transparent;
					color: $color_highlight;
				}
			}
		}
	}
	&.p-button-success > .p-button {
		color: $black;
		background: $color_button_success;
		border: (0.063rem * ($borderFactor)) solid $color_button_success;
		&:enabled {
			&:hover {
				background: $color_back_button_hover_success;
				color: $black;
				border-color: $color_back_button_hover_success;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_box_buttonsuccess;
			}
			&:active {
				background: $color_san_felix_approx;
				color: $black;
				border-color: $color_san_felix_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_button_success;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_apple_4_approx;
					color: $color_button_success;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_apple_16_approx;
					color: $color_button_success;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_button_success;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_apple_4_approx;
					border-color: transparent;
					color: $color_button_success;
				}
				&:active {
					background: $color_apple_16_approx;
					border-color: transparent;
					color: $color_button_success;
				}
			}
		}
	}
	&.p-button-warning > .p-button {
		color: $colorforeground_Default;
		background: $color_back_warn;
		border: (0.063rem * ($borderFactor)) solid $color_back_warn;
		&:enabled {
			&:hover {
				background: $color_back_button_warning;
				color: $colorforeground_Default;
				border-color: $color_back_button_warning;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_box_warning;
			}
			&:active {
				background: $color_meteor_approx;
				color: $colorforeground_Default;
				border-color: $color_meteor_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_back_warn;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $colorback_buttontext_hover_enabled;
					color: $color_back_warn;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $colorback_buttontext_active;
					color: $color_back_warn;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_back_warn;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $colorback_buttontext_hover_enabled;
					border-color: transparent;
					color: $color_back_warn;
				}
				&:active {
					background: $colorback_buttontext_active;
					border-color: transparent;
					color: $color_back_warn;
				}
			}
		}
	}
	&.p-button-help > .p-button {
		color: $black;
		background: $color_active_4;
		border: (0.063rem * ($borderFactor)) solid $color_active_4;
		&:enabled {
			&:hover {
				background: $colorback_buttonhelp_active;
				color: $black;
				border-color: $colorback_buttonhelp_active;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_buttonhelp_box;
			}
			&:active {
				background: $colorback_buttonhelp_active;
				color: $black;
				border-color: $colorback_buttonhelp_active;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_active_4;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_violet_eggplant_4_approx;
					color: $color_active_4;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_violet_eggplant_16_approx;
					color: $color_active_4;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_active_4;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_violet_eggplant_4_approx;
					border-color: transparent;
					color: $color_active_4;
				}
				&:active {
					background: $color_violet_eggplant_16_approx;
					border-color: transparent;
					color: $color_active_4;
				}
			}
		}
	}
	&.p-button-danger > .p-button {
		color: $black;
		background: $color_crimson_approx;
		border: (0.063rem * ($borderFactor)) solid $color_crimson_approx;
		&:enabled {
			&:hover {
				background: $color_monza_approx;
				color: $black;
				border-color: $color_monza_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_sundown_approx;
			}
			&:active {
				background: $color_border_warn;
				color: $black;
				border-color: $color_border_warn;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_crimson_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_crimson_4_approx;
					color: $color_crimson_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_crimson_16_approx;
					color: $color_crimson_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_crimson_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_crimson_4_approx;
					border-color: transparent;
					color: $color_crimson_approx;
				}
				&:active {
					background: $color_crimson_16_approx;
					border-color: transparent;
					color: $color_crimson_approx;
				}
			}
		}
	}
}
.p-carousel {
	.p-carousel-content {
		.p-carousel-prev {
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			color: $color_border_3;
			border: 0 none;
			background: transparent;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			margin: (0.5rem * ($marginControlFactor));
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
			&:enabled:hover {
				color: $color_highlight;
				border-color: transparent;
				background: transparent;
			}
		}
		.p-carousel-next {
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			color: $color_border_3;
			border: 0 none;
			background: transparent;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			margin: (0.5rem * ($marginControlFactor));
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
			&:enabled:hover {
				color: $color_highlight;
				border-color: transparent;
				background: transparent;
			}
		}
	}
	.p-carousel-indicators {
		padding: (1rem *($paddingControlFactor) );
		.p-carousel-indicator {
			margin-right: (0.5rem * ($marginControlFactor));
			margin-bottom: (0.5rem *($marginControlFactor) );
			button {
				background-color: $data_background;
				width: (2rem * ($widthFactor));
				height: (0.5rem * $heightFactor);
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 0;
				&:hover {
					background: $color_background_Default;
				}
			}
			&.p-highlight button {
				background: $color_highlight;
				color: $black;
			}
		}
	}
}
.p-datatable {
	.p-paginator-top {
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
	}
	.p-paginator-bottom {
		border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
	}
	.p-datatable-header {
		background: $data_background;
		color: $colorforeground_Default;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		font-weight: 700;
	}
	.p-datatable-footer {
		background: $black;
		color: $colorforeground_Default;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		font-weight: normal;
	}
	.p-sortable-column {
		.p-sortable-column-icon {
			color: $color_active_1;
			margin-left: (0.5rem * ($marginControlFactor));
		}
		.p-sortable-column-badge {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			height: (1.143rem * ($heightFactor));
			min-width: (1.143rem * ($widthFactor));
			line-height: (1.143rem * ($heightFactor));
			color: $black;
			background: $color_highlight;
			margin-left: (0.5rem * ($marginControlFactor));
		}
		&.p-highlight {
			background: $color_highlight;
			color: $black;
			.p-sortable-column-icon {
				color: $black;
			}
			&:hover {
				background: $color_highlight;
				color: $black;
				.p-sortable-column-icon {
					color: $black;
				}
			}
		}
		&:focus {
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 0 0 0.2rem $box_shadow;
			outline: 0 none;
		}
		&:not(.p-highlight):hover {
			background: $color_background_Default;
			color: $colorforeground_Default;
			.p-sortable-column-icon {
				color: $colorforeground_Default;
			}
		}
	}
	.p-column-resizer-helper {
		background: $color_highlight;
	}
	.p-datatable-scrollable-header {
		background: $data_background;
	}
	.p-datatable-scrollable-footer {
		background: $data_background;
	}
	.p-datatable-loading-icon {
		font-size: 2rem;
	}
	&.p-datatable-gridlines {
		.p-datatable-header {
			border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		}
		.p-datatable-footer {
			border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		}
		.p-paginator-top {
			border-width: 0 (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		}
		.p-paginator-bottom {
			border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		}
		.p-datatable-thead > tr > th {
			border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		}
		.p-datatable-tbody > tr > td {
			border-width: (0.063rem * ($borderFactor));
		}
		.p-datatable-tfoot > tr > td {
			border-width: (0.063rem * ($borderFactor));
		}
	}
	&.p-datatable-sm {
		.p-datatable-header {
			padding: (0.72845rem *($paddingControlFactor) ) (0.85rem *($paddingControlFactor) );
		}
		.p-datatable-footer {
			padding: (0.48535rem *($paddingControlFactor) ) (0.85rem *($paddingControlFactor) );
		}
		.p-datatable-thead > tr > th {
			padding: (0.48535rem *($paddingControlFactor) ) (0.72845rem *($paddingControlFactor) );
		}
		.p-datatable-tbody > tr > td {
			padding: (0.48535rem *($paddingControlFactor) ) (0.72845rem *($paddingControlFactor) );
		}
		.p-datatable-tfoot > tr > td {
			padding: (0.48535rem *($paddingControlFactor) ) (0.85rem *($paddingControlFactor) );
		}
	}
  &.p-datatable-xs {
    .p-datatable-header {
      padding: (0.35rem *($paddingControlFactor) ) (0.4rem *($paddingControlFactor) );
    }
    .p-datatable-footer {
      padding: (0.24rem *($paddingControlFactor) ) (0.4rem *($paddingControlFactor) );
    }
    .p-datatable-thead > tr > th {
      padding: (0.24rem *($paddingControlFactor) ) (0.35rem *($paddingControlFactor) );
    }
    .p-datatable-tbody > tr > td {
      padding: (0.24rem *($paddingControlFactor) ) (0.35rem *($paddingControlFactor) );
    }
    .p-datatable-tfoot > tr > td {
      padding: (0.24rem *($paddingControlFactor) ) (0.4rem *($paddingControlFactor) );
    }
  }
	&.p-datatable-lg {
		.p-datatable-header {
			padding: (1.07125rem *($paddingControlFactor) ) (1.25rem *($paddingControlFactor) );
		}
		.p-datatable-footer {
			padding: (0.71375rem *($paddingControlFactor) ) (1.25rem *($paddingControlFactor) );
		}
		.p-datatable-thead > tr > th {
			padding: (0.71375rem *($paddingControlFactor) ) (1.07125rem *($paddingControlFactor) );
		}
		.p-datatable-tbody > tr > td {
			padding: (0.71375rem *($paddingControlFactor) ) (1.07125rem *($paddingControlFactor) );
		}
		.p-datatable-tfoot > tr > td {
			padding: (0.71375rem *($paddingControlFactor) ) (1.25rem *($paddingControlFactor) );
		}
	}
	.p-datatable-thead > tr > th {
		text-align: left;
		padding: (0.571rem *($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_border;
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		font-weight: 700;
		color: $colorforeground_Default;
		background: $data_background;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
	}
	.p-datatable-tfoot > tr > td {
		text-align: left;
		padding: (0.571rem *($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_border;
		border-width: (0.063rem * ($borderFactor));
		font-weight: 700;
		color: $colorforeground_Default;
		background: $data_background;
	}
	.p-datatable-tbody > tr {
		background: $black;
		color: $colorforeground_Default;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		outline-color: $box_shadow;
		> td {
			text-align: left;
			border: (0.063rem * ($borderFactor)) solid $color_border;
			border-width: (0.063rem * ($borderFactor));
			padding: (0.571rem *($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
			.p-row-toggler {
				width: (2rem * ($borderFactor));
				height: (2rem * ($borderFactor));
				color: $color_border_3;
				border: 0 none;
				background: transparent;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 50%;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $box_shadow;
				}
				&:enabled:hover {
					color: $color_highlight;
					border-color: transparent;
					background: transparent;
				}
			}
			.p-row-editor-init {
				width: (2rem * ($borderFactor));
				height: (2rem * ($borderFactor));
				color: $color_border_3;
				border: 0 none;
				background: transparent;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 50%;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $box_shadow;
				}
				&:enabled:hover {
					color: $color_highlight;
					border-color: transparent;
					background: transparent;
				}
			}
			.p-row-editor-save {
				width: (2rem * ($borderFactor));
				height: (2rem * ($borderFactor));
				color: $color_border_3;
				border: 0 none;
				background: transparent;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 50%;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				margin-right: (0.5rem * ($marginControlFactor));
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $box_shadow;
				}
				&:enabled:hover {
					color: $color_highlight;
					border-color: transparent;
					background: transparent;
				}
			}
			.p-row-editor-cancel {
				width: (2rem * ($borderFactor));
				height: (2rem * ($borderFactor));
				color: $color_border_3;
				border: 0 none;
				background: transparent;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 50%;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $box_shadow;
				}
				&:enabled:hover {
					color: $color_highlight;
					border-color: transparent;
					background: transparent;
				}
			}
		}
		&.p-highlight {
			background: $color_highlight;
			color: $black;
		}
		&.p-datatable-dragpoint-top > td {
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 2px 0 0 $color_highlight;
		}
		&.p-datatable-dragpoint-bottom > td {
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 -2px 0 0 $color_highlight;
		}
	}
	&.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
		background: $color_background_Default;
		color: $colorforeground_Default;
	}
	&.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
		background: $color_alabaster_approx;
		&.p-highlight {
			background: $color_highlight;
			color: $black;
			.p-row-toggler {
				color: $black;
				&:hover {
					color: $black;
				}
			}
		}
	}
}
.p-dataview {
	.p-paginator-top {
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
	}
	.p-paginator-bottom {
		border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
	}
	.p-dataview-header {
		background: $data_background;
		color: $colorforeground_Default;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		font-weight: 700;
	}
	.p-dataview-content {
		background: $black;
		color: $colorforeground_Default;
		border: 0 none;
		padding: 0;
	}
	.p-dataview-footer {
		background: $black;
		color: $colorforeground_Default;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		font-weight: normal;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
	}
	.p-dataview-loading-icon {
		font-size: 2rem;
	}
	.p-dataview-emptymessage {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	}
	&.p-dataview-list .p-dataview-content > .grid > div {
		border: (0.063rem * ($borderFactor)) solid $color_paddingtop_1;
		border-width: (0.063rem * ($borderFactor));
	}
}
.p-column-filter-row {
	.p-column-filter-menu-button {
		margin-left: (0.5rem * ($marginControlFactor));
	}
	.p-column-filter-clear-button {
		margin-left: (0.5rem * ($marginControlFactor));
	}
}
.p-column-filter-menu-button {
	width: (2rem * ($borderFactor));
	height: (2rem * ($borderFactor));
	color: $color_border_3;
	border: 0 none;
	background: transparent;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 50%;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	&:hover {
		color: $color_highlight;
		border-color: transparent;
		background: transparent;
	}
	&.p-column-filter-menu-button-open {
		background: transparent;
		color: $color_highlight;
		&:hover {
			background: transparent;
			color: $color_highlight;
		}
	}
	&.p-column-filter-menu-button-active {
		background: $color_highlight;
		color: $black;
		&:hover {
			background: $color_highlight;
			color: $black;
		}
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0 0.2rem $box_shadow;
	}
}
.p-column-filter-clear-button {
	width: (2rem * ($borderFactor));
	height: (2rem * ($borderFactor));
	color: $color_border_3;
	border: 0 none;
	background: transparent;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 50%;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	&:hover {
		color: $color_highlight;
		border-color: transparent;
		background: transparent;
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0 0.2rem $box_shadow;
	}
}
.p-column-filter-overlay {
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0.188rem 6px 0 $box_shadow_2;
	min-width: (12.5rem *($widthFactor) );
	.p-column-filter-row-items {
		padding: 0;
		.p-column-filter-row-item {
			margin: 0;
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			border: 0 none;
			color: $colorforeground_Default;
			background: transparent;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			&.p-highlight {
				color: $black;
				background: $color_highlight;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 0 0 0.2rem $box_shadow;
			}
			&:not(.p-highlight):not(.p-disabled):hover {
				color: $colorforeground_Default;
        background: $color_hover_enabled;
			}
		}
		.p-column-filter-separator {
			border-top: (0.063rem * ($borderFactor)) solid $color_paddingtop_1;
			margin: 0;
		}
	}
}
.p-column-filter-overlay-menu {
	.p-column-filter-operator {
		padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
		border-bottom: (0.063rem * ($borderFactor)) solid $color_background_Default;
		color: $colorforeground_Default;
		background: $black;
		margin: 0;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
	}
	.p-column-filter-constraint {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border-bottom: (0.063rem * ($borderFactor)) solid $color_paddingtop_1;
		.p-column-filter-matchmode-dropdown {
			margin-bottom: (0.5rem *($marginControlFactor) );
		}
		.p-column-filter-remove-button {
			margin-top: (0.5rem *($marginControlFactor) );
		}
		&:last-child {
			border-bottom: 0 none;
		}
	}
	.p-column-filter-add-rule {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	}
	.p-column-filter-buttonbar {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	}
}
.fc {
	.fc-view-container {
		th {
			background: $data_background;
			border: (0.063rem * ($borderFactor)) solid $color_border;
			color: $colorforeground_Default;
		}
		td {
			&.fc-widget-content {
				background: $black;
				border: (0.063rem * ($borderFactor)) solid $color_border;
				color: $colorforeground_Default;
			}
			&.fc-head-container {
				border: (0.063rem * ($borderFactor)) solid $color_border;
			}
		}
		.fc-row {
			border-right: (0.063rem * ($borderFactor)) solid $color_border;
		}
		.fc-event {
			background: $color_hover_enabled;
			border: (0.063rem * ($borderFactor)) solid $color_hover_enabled;
			color: $black;
		}
		.fc-divider {
			background: $data_background;
			border: (0.063rem * ($borderFactor)) solid $color_border;
		}
	}
	.fc-toolbar {
		.fc-button {
			color: $black;
			background: $color_highlight;
			border: (0.063rem * ($borderFactor)) solid $color_highlight;
			font-size: $fontSizeDefault;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			display: flex;
			align-items: center;
			&:enabled {
				&:hover {
					background: $color_hover_enabled;
					color: $black;
					border-color: $color_hover_enabled;
				}
				&:active {
					background: $color_hover_enabled;
					color: $black;
					border-color: $color_hover_enabled;
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
						box-shadow: 0 0 0 0.2rem $box_shadow;
					}
				}
			}
			.fc-icon-chevron-left {
				font-family: $font_icons;
				text-indent: 0;
				font-size: $fontSizeDefault;
				&:before {
					content: "";
				}
			}
			.fc-icon-chevron-right {
				font-family: $font_icons;
				text-indent: 0;
				font-size: $fontSizeDefault;
				&:before {
					content: "";
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
			&.fc-dayGridMonth-button {
				background: $button_back_3;
				border: (0.063rem * ($borderFactor)) solid $button_back_3;
				color: $colorforeground_Default;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
				&:hover {
					background: $color_border;
					border-color: $color_border;
					color: $colorforeground_Default;
				}
				&.fc-button-active {
					background: $color_highlight;
					border-color: $color_highlight;
					color: $black;
					&:hover {
						background: $color_hover_enabled;
						border-color: $color_hover_enabled;
						color: $black;
					}
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $box_shadow;
					z-index: 1;
				}
			}
			&.fc-timeGridWeek-button {
				background: $button_back_3;
				border: (0.063rem * ($borderFactor)) solid $button_back_3;
				color: $colorforeground_Default;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
				&:hover {
					background: $color_border;
					border-color: $color_border;
					color: $colorforeground_Default;
				}
				&.fc-button-active {
					background: $color_highlight;
					border-color: $color_highlight;
					color: $black;
					&:hover {
						background: $color_hover_enabled;
						border-color: $color_hover_enabled;
						color: $black;
					}
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $box_shadow;
					z-index: 1;
				}
			}
			&.fc-timeGridDay-button {
				background: $button_back_3;
				border: (0.063rem * ($borderFactor)) solid $button_back_3;
				color: $colorforeground_Default;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
				&:hover {
					background: $color_border;
					border-color: $color_border;
					color: $colorforeground_Default;
				}
				&.fc-button-active {
					background: $color_highlight;
					border-color: $color_highlight;
					color: $black;
					&:hover {
						background: $color_hover_enabled;
						border-color: $color_hover_enabled;
						color: $black;
					}
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $box_shadow;
					z-index: 1;
				}
			}
		}
		.fc-button-group .fc-button {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			&:first-child {
				//Instead of the line below you could use @include border-top-left-radius($radius)
				border-top-left-radius: 0.188rem;
				//Instead of the line below you could use @include border-bottom-left-radius($radius)
				border-bottom-left-radius: 0.188rem;
			}
			&:last-child {
				//Instead of the line below you could use @include border-top-right-radius($radius)
				border-top-right-radius: 0.188rem;
				//Instead of the line below you could use @include border-bottom-right-radius($radius)
				border-bottom-right-radius: 0.188rem;
			}
		}
	}
}
.p-orderlist {
	.p-orderlist-controls {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		.p-button {
			margin-bottom: (0.5rem *($marginControlFactor) );
		}
	}
	.p-orderlist-header {
		background: $data_background;
		color: $colorforeground_Default;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border-bottom: 0 none;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-orderlist-title {
			font-weight: 700;
		}
	}
	.p-orderlist-filter-container {
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		background: $black;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		border-bottom: 0 none;
		.p-orderlist-filter-input {
			padding-right: (1.429rem * ($paddingControlFactor));
		}
		.p-orderlist-filter-icon {
			right: (0.429rem *($paddingRightFactor));
			color: $color_active_1;
		}
	}
	.p-orderlist-list {
		border: (0.063rem * ($borderFactor)) solid $color_border;
		background: $black;
		color: $colorforeground_Default;
		padding: 0;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		.p-orderlist-item {
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			margin: 0;
			border: 0 none;
			color: $colorforeground_Default;
			background: transparent;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: transform 0.2s, background-color 0.2s, box-shadow 0.2s;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 0 0 0.2rem $box_shadow;
			}
			&.p-highlight {
				color: $black;
				background: $color_highlight;
			}
			&:not(.p-highlight):hover {
				background: $color_background_Default;
				color: $colorforeground_Default;
			}
		}
		.p-orderlist-droppoint.p-orderlist-droppoint-highlight {
			background-color: $color_hover_enabled;
		}
	}
}
@media screen and (max-width: 960px) {
	.p-orderlist {
		flex-direction: column;
		.p-orderlist-controls {
			padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
			flex-direction: row;
			.p-button {
				margin-right: (0.5rem * ($marginControlFactor));
				margin-bottom: 0;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	.p-picklist {
		flex-direction: column;
		.p-picklist-buttons {
			padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
			flex-direction: row;
			.p-button {
				margin-right: (0.5rem * ($marginControlFactor));
				margin-bottom: 0;
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.p-picklist-transfer-buttons {
			.pi-angle-right:before {
				content: "";
			}
			.pi-angle-double-right:before {
				content: "";
			}
			.pi-angle-left:before {
				content: "";
			}
			.pi-angle-double-left:before {
				content: "";
			}
		}
	}
	.p-menubar {
		position: relative;
		.p-menubar-button {
			display: flex;
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			color: $colorforeground_Default;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:hover {
				color: $colorforeground_Default;
				background: $color_background_Default;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
		}
		.p-menubar-root-list {
			position: absolute;
			display: none;
			padding: 0;
			background: $black;
			border: (0.063rem * ($borderFactor)) solid $color_border;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 6px 0 $box_shadow_2;
			width: 100%;
			.p-menu-separator {
				border-top: (0.063rem * ($borderFactor)) solid $color_paddingtop_1;
				margin: 0;
			}
			.p-submenu-icon {
				font-size: ($fontSizeSmaller);
			}
			.p-submenu-list {
				width: 100%;
				position: static;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: none;
				border: 0 none;
				.p-submenu-icon {
					//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
					transition: transform 0.2s;
					//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
					transform: rotate(90deg);
				}
				.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
					//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
					transform: rotate(-90deg);
				}
			}
			.p-menuitem {
				width: 100%;
				position: static;
			}
			> .p-menuitem {
				width: 100%;
				position: static;
				> .p-menuitem-link {
					padding: (0.857rem * ($paddingControlFactor));
					color: $colorforeground_Default;
					//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
					border-radius: 0;
					//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
					transition: background-color 0.2s, box-shadow 0.2s;
					//Instead of the line below you could use @include user-select($select)
					user-select: none;
					.p-menuitem-text {
						color: $colorforeground_Default;
					}
					.p-menuitem-icon {
						color: $colorforeground_Default;
						margin-right: (0.5rem * ($marginControlFactor));
					}
					.p-submenu-icon {
						color: $colorforeground_Default;
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
						box-shadow: inset 0 0 0 0.2rem $box_shadow;
					}
					&:not(.p-disabled):hover {
						background: $color_background_Default;
						.p-menuitem-text {
							color: $colorforeground_Default;
						}
						.p-menuitem-icon {
							color: $colorforeground_Default;
						}
						.p-submenu-icon {
							color: $colorforeground_Default;
						}
					}
					> .p-submenu-icon {
						margin-left: auto;
						//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
						transition: transform 0.2s;
					}
				}
				&.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
					//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
					transform: rotate(-180deg);
				}
			}
			ul li {
				a {
					padding-left: 2.571rem;
				}
				ul li {
					a {
						padding-left: 4.285rem;
					}
					ul li {
						a {
							padding-left: 5.999rem;
						}
						ul li {
							a {
								padding-left: 7.713rem;
							}
							ul li a {
								padding-left: 9.427rem;
							}
						}
					}
				}
			}
		}
		&.p-menubar-mobile-active .p-menubar-root-list {
			display: flex;
			flex-direction: column;
			top: 100%;
			left: 0;
			z-index: 1;
		}
	}
}
.p-organizationchart {
	.p-organizationchart-node-content {
		border: (0.063rem * ($borderFactor)) solid $color_border;
		background: $black;
		color: $colorforeground_Default;
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		&.p-highlight {
			background: $color_highlight;
			color: $black;
			.p-node-toggler i {
				color: $color_prussian_blue_approx;
			}
		}
		.p-node-toggler {
			background: inherit;
			color: inherit;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
		}
		&.p-organizationchart-selectable-node:not(.p-highlight):hover {
			background: $color_background_Default;
			color: $colorforeground_Default;
		}
	}
	.p-organizationchart-line-down {
		background: $color_border;
	}
	.p-organizationchart-line-left {
		border-right: (0.063rem * ($borderFactor)) solid $color_border;
		border-color: $color_border;
	}
	.p-organizationchart-line-top {
		border-top: (0.063rem * ($borderFactor)) solid $color_border;
		border-color: $color_border;
	}
}
.p-paginator {
	background: $data_background;
	color: $colorforeground_Default;
	border: solid $color_border;
	border-width: (0.063rem * ($borderFactor));
	padding: 0;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-paginator-first {
		background-color: transparent;
		border: 0 none;
		color: $color_active_1;
		min-width: (2.357rem *($widthFactor));
		height: (2.357rem * $heightFactor);
		margin: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		&:not(.p-disabled):not(.p-highlight):hover {
			background: $color_background_Default;
			border-color: transparent;
			color: $colorforeground_Default;
		}
	}
	.p-paginator-prev {
		background-color: transparent;
		border: 0 none;
		color: $color_active_1;
		min-width: (2.357rem *($widthFactor));
		height: (2.357rem *$heightFactor);
		margin: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		&:not(.p-disabled):not(.p-highlight):hover {
			background: $color_background_Default;
			border-color: transparent;
			color: $colorforeground_Default;
		}
	}
	.p-paginator-next {
		background-color: transparent;
		border: 0 none;
		color: $color_active_1;
		min-width: (2.357rem *($widthFactor));
		height: (2.357rem * $heightFactor);
		margin: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		&:not(.p-disabled):not(.p-highlight):hover {
			background: $color_background_Default;
			border-color: transparent;
			color: $colorforeground_Default;
		}
	}
	.p-paginator-last {
		background-color: transparent;
		border: 0 none;
		color: $color_active_1;
		min-width: (2.357rem *($widthFactor));
		height: (2.357rem *$heightFactor);
		margin: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		&:not(.p-disabled):not(.p-highlight):hover {
			background: $color_background_Default;
			border-color: transparent;
			color: $colorforeground_Default;
		}
	}
	.p-dropdown {
		margin-left: (0.5rem * ($marginControlFactor));
		height: (2.357rem * $heightFactor);
		.p-dropdown-label {
      margin-top: auto;
      margin-bottom: auto;

			padding-right: 0;
		}
	}
	.p-paginator-current {
		background-color: transparent;
		border: 0 none;
		color: $color_active_1;
		min-width: (2.357rem *($widthFactor));
		height: (2.357rem * $heightFactor);
		margin: 0;
		padding: 0 (0.5rem *($paddingControlFactor) );
	}
	.p-paginator-pages .p-paginator-page {
		background-color: transparent;
		border: 0 none;
		color: $color_active_1;
		min-width: (2.357rem *($widthFactor));
		height: (2.357rem * $heightFactor);
		margin: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		&.p-highlight {
			background: $color_highlight;
			border-color: $color_highlight;
			color: $black;
		}
		&:not(.p-highlight):hover {
			background: $color_background_Default;
			border-color: transparent;
			color: $colorforeground_Default;
		}
	}
}
.p-picklist {
	.p-picklist-buttons {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		.p-button {
			margin-bottom: (0.5rem *($marginControlFactor) );
		}
	}
	.p-picklist-header {
		background: $data_background;
		color: $colorforeground_Default;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border-bottom: 0 none;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-picklist-title {
			font-weight: 700;
		}
	}
	.p-picklist-filter-container {
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		background: $black;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		border-bottom: 0 none;
		.p-picklist-filter-input {
			padding-right: (1.429rem * ($paddingControlFactor));
		}
		.p-picklist-filter-icon {
			right: (0.429rem *($paddingRightFactor));
			color: $color_active_1;
		}
	}
	.p-picklist-list {
		border: (0.063rem * ($borderFactor)) solid $color_border;
		background: $black;
		color: $colorforeground_Default;
		padding: 0;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		.p-picklist-item {
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			margin: 0;
			border: 0 none;
			color: $colorforeground_Default;
			background: transparent;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: transform 0.2s, background-color 0.2s, box-shadow 0.2s;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 0 0 0.2rem $box_shadow;
			}
			&.p-highlight {
				color: $black;
				background: $color_highlight;
			}
			&:not(.p-highlight):hover {
				background: $color_background_Default;
				color: $colorforeground_Default;
			}
		}
		.p-picklist-empty-message {
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			color: $colorforeground_Default;
		}
		.p-picklist-droppoint.p-picklist-droppoint-highlight {
			background-color: $color_hover_enabled;
		}
	}
}
.p-timeline {
	.p-timeline-event-marker {
		border: 0 none;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50%;
		width: (1rem * $widthFactor);
		height: (1rem * $heightFactor);
		background-color: $color_highlight;
	}
	.p-timeline-event-connector {
		background-color: $color_border;
	}
	&.p-timeline-vertical {
		.p-timeline-event-opposite {
			padding: 0 1rem;
		}
		.p-timeline-event-content {
			padding: 0 1rem;
		}
		.p-timeline-event-connector {
			width: 2px;
		}
	}
	&.p-timeline-horizontal {
		.p-timeline-event-opposite {
			padding: (1rem *($paddingControlFactor) ) 0;
		}
		.p-timeline-event-content {
			padding: (1rem *($paddingControlFactor) ) 0;
		}
		.p-timeline-event-connector {
			height: (2px * $heightFactor);
		}
	}
}
.p-tree {
	border: (0.063rem * ($borderFactor)) solid $color_border;
	background: $black;
	color: $colorforeground_Default;
	padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-tree-filter-container {
		margin-bottom: (0.5rem *($marginControlFactor) );
		.p-tree-filter {
			width: 100%;
			padding-right: (1.429rem * ($paddingControlFactor));
		}
		.p-tree-filter-icon {
			right: (0.429rem *($paddingRightFactor));
			color: $color_active_1;
		}
	}
	.p-treenode-children {
		padding: 0 0 0 (1rem * $paddingControlFactor);
	}
	.p-tree-loading-icon {
		font-size: 2rem;
	}
	.p-tree-container .p-treenode {
		padding: (0.143rem *($paddingControlFactor) );
		.p-treenode-content {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			padding: (0.429rem *($paddingControlFactor) );
			.p-tree-toggler {
				margin-right: (0.5rem * ($marginControlFactor));
				width: (2rem * ($borderFactor));
				height: (2rem * ($borderFactor));
				color: $color_border_3;
				border: 0 none;
				background: transparent;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 50%;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $box_shadow;
				}
				&:enabled:hover {
					color: $color_highlight;
					border-color: transparent;
					background: transparent;
				}
			}
			.p-treenode-icon {
				margin-right: (0.5rem * ($marginControlFactor));
				color: $color_active_1;
			}
			.p-checkbox {
				margin-right: (0.5rem * ($marginControlFactor));
				.p-indeterminate .p-checkbox-icon {
					color: $colorforeground_Default;
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
			&.p-highlight {
				background: $color_highlight;
				color: $black;
				.p-tree-toggler {
					color: $black;
					&:hover {
						color: $black;
					}
				}
				.p-treenode-icon {
					color: $black;
					&:hover {
						color: $black;
					}
				}
			}
			&.p-treenode-dragover {
				background: $color_background_Default;
				color: $colorforeground_Default;
			}
			&.p-treenode-selectable:not(.p-highlight):hover {
				background: $color_background_Default;
				color: $colorforeground_Default;
			}
		}
	}
	.p-treenode-droppoint.p-treenode-droppoint-active {
		background-color: $color_hover_enabled;
	}
	&.p-tree-horizontal .p-treenode .p-treenode-content {
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		background-color: $black;
		color: $colorforeground_Default;
		padding: (0.429rem *($paddingControlFactor) );
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		&.p-highlight {
			background-color: $color_highlight;
			color: $black;
			.p-treenode-icon {
				color: $black;
			}
		}
		.p-tree-toggler {
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-treenode-icon {
			color: $color_active_1;
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-checkbox {
			margin-right: (0.5rem * ($marginControlFactor));
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $box_shadow;
		}
		.p-treenode-label:not(.p-highlight):hover {
			background-color: inherit;
			color: inherit;
		}
		&.p-treenode-selectable:not(.p-highlight):hover {
			background: $color_background_Default;
			color: $colorforeground_Default;
		}
	}
}
.p-treetable {
	.p-paginator-top {
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
	}
	.p-paginator-bottom {
		border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
	}
	.p-treetable-header {
		background: $data_background;
		color: $colorforeground_Default;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		font-weight: 700;
	}
	.p-treetable-footer {
		background: $black;
		color: $colorforeground_Default;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		font-weight: normal;
	}
	.p-sortable-column {
		outline-color: $box_shadow;
		.p-sortable-column-icon {
			color: $color_active_1;
			margin-left: (0.5rem * ($marginControlFactor));
		}
		.p-sortable-column-badge {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			height: (1.143rem * ($heightFactor));
			min-width: (1.143rem * ($widthFactor));
			line-height: (1.143rem * ($heightFactor));
			color: $black;
			background: $color_highlight;
			margin-left: (0.5rem * ($marginControlFactor));
		}
		&.p-highlight {
			background: $color_highlight;
			color: $black;
			.p-sortable-column-icon {
				color: $black;
			}
		}
		&:not(.p-highlight):hover {
			background: $color_background_Default;
			color: $colorforeground_Default;
			.p-sortable-column-icon {
				color: $colorforeground_Default;
			}
		}
	}
	.p-column-resizer-helper {
		background: $color_highlight;
	}
	.p-treetable-scrollable-header {
		background: $data_background;
	}
	.p-treetable-scrollable-footer {
		background: $data_background;
	}
	.p-treetable-loading-icon {
		font-size: 2rem;
	}
	&.p-treetable-gridlines {
		.p-datatable-header {
			border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		}
		.p-treetable-footer {
			border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		}
		.p-treetable-top {
			border-width: 0 (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		}
		.p-treetable-bottom {
			border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		}
		.p-treetable-thead > tr > th {
			border-width: (0.063rem * ($borderFactor));
		}
		.p-treetable-tbody > tr > td {
			border-width: (0.063rem * ($borderFactor));
		}
		.p-treetable-tfoot > tr > td {
			border-width: (0.063rem * ($borderFactor));
		}
	}
	&.p-treetable-sm {
		.p-treetable-header {
			padding: (0.749875rem *($paddingControlFactor) ) (0.875rem *($paddingControlFactor) );
		}
		.p-treetable-footer {
			padding: (0.48535rem *($paddingControlFactor) ) (0.85rem *($paddingControlFactor) );
		}
		.p-treetable-thead > tr > th {
			padding: (0.48535rem *($paddingControlFactor) ) (0.72845rem *($paddingControlFactor) );
		}
		.p-treetable-tbody > tr > td {
			padding: (0.48535rem *($paddingControlFactor) ) (0.72845rem *($paddingControlFactor) );
		}
		.p-treetable-tfoot > tr > td {
			padding: (0.48535rem *($paddingControlFactor) ) (0.85rem *($paddingControlFactor) );
		}
	}
	&.p-treetable-lg {
		.p-treetable-header {
			padding: (1.07125rem *($paddingControlFactor) ) (1.25rem *($paddingControlFactor) );
		}
		.p-treetable-footer {
			padding: (0.71375rem *($paddingControlFactor) ) (1.25rem *($paddingControlFactor) );
		}
		.p-treetable-thead > tr > th {
			padding: (0.71375rem *($paddingControlFactor) ) (1.07125rem *($paddingControlFactor) );
		}
		.p-treetable-tbody > tr > td {
			padding: (0.71375rem *($paddingControlFactor) ) (1.07125rem *($paddingControlFactor) );
		}
		.p-treetable-tfoot > tr > td {
			padding: (0.71375rem *($paddingControlFactor) ) (1.25rem *($paddingControlFactor) );
		}
	}
	.p-treetable-thead > tr > th {
		text-align: left;
		padding: (0.571rem *($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_border;
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		font-weight: 700;
		color: $colorforeground_Default;
		background: $data_background;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
	}
	.p-treetable-tfoot > tr > td {
		text-align: left;
		padding: (0.571rem *($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_border;
		border-width: (0.063rem * ($borderFactor));
		font-weight: 700;
		color: $colorforeground_Default;
		background: $data_background;
	}
	.p-treetable-tbody > tr {
		background: $black;
		color: $colorforeground_Default;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		outline-color: $box_shadow;
		> td {
			text-align: left;
			border: (0.063rem * ($borderFactor)) solid $color_border;
			border-width: (0.063rem * ($borderFactor));
			padding: (0.571rem *($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
			.p-treetable-toggler {
				width: (2rem * ($borderFactor));
				height: (2rem * ($borderFactor));
				color: $color_gallery;
				border: 0 none;
				background: transparent;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 50%;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				margin-right: (0.5rem * ($marginControlFactor));
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $box_shadow;
				}
				&:enabled:hover {
					color: $color_highlight;
					border-color: transparent;
					background: transparent;
				}
			}
			p-treetablecheckbox .p-checkbox {
				margin-right: (0.5rem * ($marginControlFactor));
				.p-indeterminate .p-checkbox-icon {
					color: $colorforeground_Default;
				}
			}
		}
		&.p-highlight {
			background: $color_highlight;
			color: $black;
			.p-treetable-toggler {
				color: $black;
				&:hover {
					color: $black;
				}
			}
		}
	}
	&.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
		background: $color_background_Default;
		color: $colorforeground_Default;
		.p-treetable-toggler {
			color: $colorforeground_Default;
		}
	}
}
.p-virtualscroller {
	.p-virtualscroller-header {
		background: $data_background;
		color: $colorforeground_Default;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		font-weight: 700;
	}
	.p-virtualscroller-content {
		background: $black;
		color: $colorforeground_Default;
		border: 0 none;
		padding: 0;
	}
	.p-virtualscroller-footer {
		background: $black;
		color: $colorforeground_Default;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		font-weight: normal;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
	}
}
.p-accordion {
	.p-accordion-header {
		.p-accordion-header-link {
			padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
			border: (0.063rem * ($borderFactor)) solid $color_border;
			color: $colorforeground_Default;
			background: $data_background;
			font-weight: 700;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			.p-accordion-toggle-icon {
				margin-right: (0.5rem * ($marginControlFactor));
			}
		}
		&:not(&.p-disabled) {
				&.p-highlight {
					.p-accordion-header-link {
						background: $color_highlight;
						border-color: $color_highlight;
						color: $black;
						//Instead of the line below you could use @include border-bottom-right-radius($radius)
						border-bottom-right-radius: 0;
						//Instead of the line below you could use @include border-bottom-left-radius($radius)
						border-bottom-left-radius: 0;
					}
					&:hover .p-accordion-header-link {
						border-color: $color_hover_enabled;
						background: $color_hover_enabled;
						color: $black;
					}
				}
				.p-accordion-header-link:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $box_shadow;
				}
			}
			.p-highlight:not(.p-disabled):hover .p-accordion-header-link {
				background: $colorback_highlight_hover;
				border-color: $colorback_highlight_hover;
				color: $colorforeground_Default;
			}

	}
	.p-accordion-content {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_border;
		background: $black;
		color: $colorforeground_Default;
		border-top: 0;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
	}
	p-accordiontab .p-accordion-tab {
		margin-bottom: 2px;
	}
}
.p-card {
	background: $black;
	color: $colorforeground_Default;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 (0.063rem * ($borderFactor)) 0.188rem $black_12, 0 (0.063rem * ($borderFactor)) 2px $black_24;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-card-body {
		padding: (1rem *($paddingControlFactor) );
	}
	.p-card-title {
		font-size: 1.5rem;
		font-weight: 700;
		margin-bottom: (0.5rem *($marginControlFactor) );
	}
	.p-card-subtitle {
		font-weight: 700;
		margin-bottom: (0.5rem *($marginControlFactor) );
		color: $color_active_1;
	}
	.p-card-content {
		padding: (1rem *($paddingControlFactor) ) 0;
	}
	.p-card-footer {
		padding: 1rem 0 0;
	}
}
.p-divider {
	.p-divider-content {
		background-color: $black;
	}
	&.p-divider-horizontal {
		margin: (1rem * $marginControlFactor) 0;
		padding: 0 (1rem * $paddingControlFactor);
		&:before {
			border-top: (0.063rem * ($borderFactor)) $color_border;
		}
		.p-divider-content {
			padding: 0 (0.5rem *($paddingControlFactor) );
		}
	}
	&.p-divider-vertical {
		margin: 0 (1rem * $marginControlFactor);
		padding: (1rem *($paddingControlFactor) ) 0;
		&:before {
			border-left: (0.063rem * ($borderFactor)) $color_border;
		}
		.p-divider-content {
			padding: (0.5rem *($paddingControlFactor) ) 0;
		}
	}
}
.p-fieldset {
	border: (0.063rem * ($borderFactor)) solid $color_border;
	background: $black;
	color: $colorforeground_Default;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-fieldset-legend {
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_border;
		color: $colorforeground_Default;
		background: $data_background;
		font-weight: 700;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
	}
	.p-fieldset-content {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	}
	&.p-fieldset-toggleable .p-fieldset-legend {
		padding: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		a {
			padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
			color: $colorforeground_Default;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			.p-fieldset-toggler {
				margin-right: (0.5rem * ($marginControlFactor));
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
		}
		&:hover {
			background: $colorback_highlight_hover;
			border-color: $colorback_highlight_hover;
			color: $colorforeground_Default;
		}
	}
}
.p-panel {
	.p-panel-header {
		border: (0.063rem * ($borderFactor)) solid $color_border;
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		background: $data_background;
		color: $colorforeground_Default;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-panel-title {
			font-weight: 700;
		}
		.p-panel-header-icon {
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			border: 0 none;
			background: transparent;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			color: $color_active_1;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
			&:enabled:hover {
				border-color: transparent;
				background: transparent;
				color: $colorforeground_Default;
			}
		}
	}
	.p-panel-content {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_border;
		background: $black;
		color: $colorforeground_Default;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		border-top: 0 none;
	}
	.p-panel-footer {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_border;
		background: $black;
		color: $colorforeground_Default;
		border-top: 0 none;
	}
	&.p-panel-toggleable .p-panel-header {
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	}
}
.p-splitter {
	border: (0.063rem * ($borderFactor)) solid $color_border;
	background: $black;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	color: $colorforeground_Default;
	.p-splitter-gutter {
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		background: $color_background_Default;
		.p-splitter-gutter-handle {
			background: $color_paddingtop_1;
		}
	}
	.p-splitter-gutter-resizing {
		background: $color_paddingtop_1;
	}
}
.p-tabview {
	.p-tabview-nav {
		background: transparent;
		border: 0 none;
		border-width: (0.063rem * ($borderFactor));
		li {
			margin-right: 2px;
			.p-tabview-nav-link {
				border: (0.063rem * ($borderFactor)) solid $color_border;
				border-width: (0.063rem * ($borderFactor));
				border-color: $color_border;
				background: $data_background;
				color: $colorforeground_Default;
				padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
				font-weight: 700;
				//Instead of the line below you could use @include border-top-right-radius($radius)
				border-top-right-radius: 0.188rem;
				//Instead of the line below you could use @include border-top-left-radius($radius)
				border-top-left-radius: 0.188rem;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, box-shadow 0.2s;
				margin: 0 0 (-(0.063rem * ($borderFactor))) 0;
				&:not(.p-disabled):focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $box_shadow;
				}
			}
			&:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
				background: $colorback_highlight_hover;
				border-color: $colorback_highlight_hover;
				color: $colorforeground_Default;
			}
			&.p-highlight .p-tabview-nav-link {
				background: $color_highlight;
				border-color: $color_highlight;
				color: $black;
			}
		}
	}
	.p-tabview-left-icon {
		margin-right: (0.5rem * ($marginControlFactor));
	}
	.p-tabview-right-icon {
		margin-left: (0.5rem * ($marginControlFactor));
	}
	.p-tabview-close {
		margin-left: (0.5rem * ($marginControlFactor));
	}
	.p-tabview-panels {
		background: $black;
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_border;
		color: $colorforeground_Default;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
	}
}
.p-toolbar {
	background: $data_background;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-toolbar-separator {
		margin: 0 (0.5rem *($marginControlFactor) );
	}
}
.p-confirm-popup {
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0 6px 0 $box_shadow_2;
	.p-confirm-popup-content {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	}
	.p-confirm-popup-footer {
		text-align: right;
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		button {
			margin: 0 0.5rem 0 0;
			width: auto;
			&:last-child {
				margin: 0;
			}
		}
	}
	&:after {
		border: solid transparent;
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: $black;
	}
	&:before {
		border: solid transparent;
		border-color: rgba(200, 200, 200, 0);
		border-bottom-color: $color_border;
	}
	&.p-confirm-popup-flipped {
		&:after {
			border-top-color: $black;
		}
		&:before {
			border-top-color: $color_border;
		}
	}
	.p-confirm-popup-icon {
		font-size: 1.5rem;
	}
	.p-confirm-popup-message {
		margin-left: (1rem *($marginControlFactor) );
	}
}
.p-dialog {
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0 6px 0 $box_shadow_2;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	.p-dialog-header {
		border-bottom: (0.063rem * ($borderFactor)) solid $color_border;
		background: $data_background;
		color: $colorforeground_Default;
		padding: (1rem *($paddingControlFactor) );
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-dialog-title {
			font-weight: 700;
			font-size: $fontSizeDefault;
		}
		.p-dialog-header-icon {
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			border: 0 none;
			background: transparent;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			margin-right: (0.5rem * ($marginControlFactor));
			color: $color_active_1;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
			&:last-child {
				margin-right: 0;
			}
			&:enabled:hover {
				border-color: transparent;
				background: transparent;
				color: $colorforeground_Default;
			}
		}
	}
	.p-dialog-content {
		background: $black;
		color: $colorforeground_Default;
		padding: (1rem *($paddingControlFactor) );
	}
	.p-dialog-footer {
		border-top: (0.063rem * ($borderFactor)) solid $color_border;
		background: $black;
		color: $colorforeground_Default;
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		text-align: right;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		button {
			margin: 0 0.5rem 0 0;
			width: auto;
		}
	}
	&.p-confirm-dialog {
		.p-confirm-dialog-icon {
			font-size: 2rem;
		}
		.p-confirm-dialog-message {
			margin-left: (1rem *($marginControlFactor) );
		}
	}
}
.p-overlaypanel {
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0 6px 0 $box_shadow_2;
	.p-overlaypanel-content {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	}
	.p-overlaypanel-close {
		background: $color_highlight;
		color: $black;
		width: (2rem * ($borderFactor));
		height: (2rem * ($borderFactor));
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50%;
		position: absolute;
		top: (-1rem *($marginControlFactor) );
		right: (-1rem *($marginControlFactor) );
		&:enabled:hover {
			background: $color_hover_enabled;
			color: $black;
		}
	}
	&:after {
		border: solid transparent;
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: $black;
	}
	&:before {
		border: solid transparent;
		border-color: rgba(200, 200, 200, 0);
		border-bottom-color: $color_border;
	}
	&.p-overlaypanel-flipped {
		&:after {
			border-top-color: $black;
		}
		&:before {
			border-top-color: $color_border;
		}
	}
}
.p-sidebar {
	background: $black;
	color: $colorforeground_Default;
	padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	border: (0.063rem * ($borderFactor)) solid $color_border;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0 6px 0 $box_shadow_2;
	.p-sidebar-close {
		width: (2rem * ($borderFactor));
		height: (2rem * ($borderFactor));
		color: $color_border_3;
		border: 0 none;
		background: transparent;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50%;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $box_shadow;
		}
		&:enabled:hover {
			color: $color_highlight;
			border-color: transparent;
			background: transparent;
		}
	}
}
.p-tooltip {
	.p-tooltip-text {
		background: $colorforeground_Default;
		color: $black;
		padding: (0.429rem *($paddingControlFactor)) (0.429rem *($paddingControlFactor));
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0.188rem 6px 0 $box_shadow_2;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
	}
	&.p-tooltip-right .p-tooltip-arrow {
		border-right-color: $colorforeground_Default;
	}
	&.p-tooltip-left .p-tooltip-arrow {
		border-left-color: $colorforeground_Default;
	}
	&.p-tooltip-top .p-tooltip-arrow {
		border-top-color: $colorforeground_Default;
	}
	&.p-tooltip-bottom .p-tooltip-arrow {
		border-bottom-color: $colorforeground_Default;
	}
}
.p-fileupload {
	.p-fileupload-buttonbar {
		background: $data_background;
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_border;
		color: $colorforeground_Default;
		border-bottom: 0 none;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-button {
			margin-right: (0.5rem * ($marginControlFactor));
			&.p-fileupload-choose.p-focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
		}
	}
	.p-fileupload-content {
		background: $black;
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_border;
		color: $colorforeground_Default;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
	}
	.p-progressbar {
		height: (0.25rem * $heightFactor);
	}
	.p-fileupload-row > div {
		padding: (0.571rem *($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
	}
	&.p-fileupload-advanced .p-message {
		margin-top: 0;
	}
}
.p-breadcrumb {
	background: $black;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	ul li {
		.p-menuitem-link {
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
			.p-menuitem-text {
				color: $colorforeground_Default;
			}
			.p-menuitem-icon {
				color: $colorforeground_Default;
			}
		}
		&.p-breadcrumb-chevron {
			margin: 0 0.5rem 0 0.5rem;
			color: $colorforeground_Default;
		}
		&:last-child {
			.p-menuitem-text {
				color: $colorforeground_Default;
			}
			.p-menuitem-icon {
				color: $colorforeground_Default;
			}
		}
	}
}
.p-contextmenu {
	padding: 0;
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0 6px 0 $box_shadow_2;
	width: (12.5rem *($widthFactor) );
	.p-menuitem-link {
		padding: (0.857rem * ($paddingControlFactor));
		color: $colorforeground_Default;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include user-select($select)
		user-select: none;
		.p-menuitem-text {
			color: $colorforeground_Default;
		}
		.p-menuitem-icon {
			color: $colorforeground_Default;
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-submenu-icon {
			color: $colorforeground_Default;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 0 0 0.2rem $box_shadow;
		}
		&:not(.p-disabled):hover {
			background: $color_background_Default;
			.p-menuitem-text {
				color: $colorforeground_Default;
			}
			.p-menuitem-icon {
				color: $colorforeground_Default;
			}
			.p-submenu-icon {
				color: $colorforeground_Default;
			}
		}
	}
	.p-submenu-list {
		padding: 0;
		background: $black;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 6px 0 $box_shadow_2;
	}
	.p-menuitem {
		margin: 0;
		&:last-child {
			margin: 0;
		}
		&.p-menuitem-active > .p-menuitem-link {
			background: $color_background_Default;
			.p-menuitem-text {
				color: $colorforeground_Default;
			}
			.p-menuitem-icon {
				color: $colorforeground_Default;
			}
			.p-submenu-icon {
				color: $colorforeground_Default;
			}
		}
	}
	.p-menu-separator {
		border-top: (0.063rem * ($borderFactor)) solid $color_paddingtop_1;
		margin: 0;
	}
	.p-submenu-icon {
		font-size: ($fontSizeSmaller);
	}
}
.p-megamenu {
	padding: (0.5rem * ($paddingControlFactor));
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-menuitem-link {
		padding: (0.857rem * ($paddingControlFactor));
		color: $colorforeground_Default;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include user-select($select)
		user-select: none;
		.p-menuitem-text {
			color: $colorforeground_Default;
		}
		.p-menuitem-icon {
			color: $colorforeground_Default;
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-submenu-icon {
			color: $colorforeground_Default;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 0 0 0.2rem $box_shadow;
		}
		&:not(.p-disabled):hover {
			background: $color_background_Default;
			.p-menuitem-text {
				color: $colorforeground_Default;
			}
			.p-menuitem-icon {
				color: $colorforeground_Default;
			}
			.p-submenu-icon {
				color: $colorforeground_Default;
			}
		}
	}
	.p-megamenu-panel {
		background: $black;
		color: $colorforeground_Default;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 6px 0 $box_shadow_2;
	}
	.p-megamenu-submenu-header {
		margin: 0;
		padding: (0.857rem * ($paddingControlFactor));
		color: $colorforeground_Default;
		background: $data_background;
		font-weight: 700;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
	}
	.p-megamenu-submenu {
		padding: 0;
		width: (12.5rem *($widthFactor) );
		.p-menu-separator {
			border-top: (0.063rem * ($borderFactor)) solid $color_paddingtop_1;
			margin: 0;
		}
		.p-menuitem {
			margin: 0;
			&:last-child {
				margin: 0;
			}
		}
	}
	&.p-megamenu-vertical {
		width: (12.5rem *($widthFactor) );
		padding: 0;
		.p-menuitem {
			margin: 0;
			&:last-child {
				margin: 0;
			}
		}
	}
	.p-megamenu-root-list > .p-menuitem {
		> .p-menuitem-link {
			padding: (0.857rem * ($paddingControlFactor));
			color: $colorforeground_Default;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include user-select($select)
			user-select: none;
			.p-menuitem-text {
				color: $colorforeground_Default;
			}
			.p-menuitem-icon {
				color: $colorforeground_Default;
				margin-right: (0.5rem * ($marginControlFactor));
			}
			.p-submenu-icon {
				color: $colorforeground_Default;
				margin-left: (0.5rem * ($marginControlFactor));
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 0 0 0.2rem $box_shadow;
			}
			&:not(.p-disabled):hover {
				background: $color_background_Default;
				.p-menuitem-text {
					color: $colorforeground_Default;
				}
				.p-menuitem-icon {
					color: $colorforeground_Default;
				}
				.p-submenu-icon {
					color: $colorforeground_Default;
				}
			}
		}
		&.p-menuitem-active > .p-menuitem-link {
			background: $color_background_Default;
			.p-menuitem-text {
				color: $colorforeground_Default;
			}
			.p-menuitem-icon {
				color: $colorforeground_Default;
			}
			.p-submenu-icon {
				color: $colorforeground_Default;
			}
			&:not(.p-disabled):hover {
				background: $color_background_Default;
				.p-menuitem-text {
					color: $colorforeground_Default;
				}
				.p-menuitem-icon {
					color: $colorforeground_Default;
				}
				.p-submenu-icon {
					color: $colorforeground_Default;
				}
			}
		}
	}
	.p-menuitem.p-menuitem-active > .p-menuitem-link {
		background: $color_background_Default;
		.p-menuitem-text {
			color: $colorforeground_Default;
		}
		.p-menuitem-icon {
			color: $colorforeground_Default;
		}
		.p-submenu-icon {
			color: $colorforeground_Default;
		}
	}
}
.p-menu {
	padding: 0;
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	width: (12.5rem *($widthFactor) );
	.p-menuitem-link {
		padding: (0.857rem * ($paddingControlFactor));
		color: $colorforeground_Default;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include user-select($select)
		user-select: none;
		.p-menuitem-text {
			color: $colorforeground_Default;
		}
		.p-menuitem-icon {
			color: $colorforeground_Default;
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-submenu-icon {
			color: $colorforeground_Default;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 0 0 0.2rem $box_shadow;
		}
		&:not(.p-disabled):hover {
			background: $color_background_Default;
			.p-menuitem-text {
				color: $colorforeground_Default;
			}
			.p-menuitem-icon {
				color: $colorforeground_Default;
			}
			.p-submenu-icon {
				color: $colorforeground_Default;
			}
		}
	}
	&.p-menu-overlay {
		background: $black;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 6px 0 $box_shadow_2;
	}
	.p-submenu-header {
		margin: 0;
		padding: (0.857rem * ($paddingControlFactor));
		color: $colorforeground_Default;
		background: $data_background;
		font-weight: 700;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
	}
	.p-menu-separator {
		border-top: (0.063rem * ($borderFactor)) solid $color_paddingtop_1;
		margin: 0;
	}
	.p-menuitem {
		margin: 0;
		&:last-child {
			margin: 0;
		}
	}
}
.p-menubar {
	padding: (0.5rem * ($paddingControlFactor));
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-menuitem-link {
		padding: (0.857rem * ($paddingControlFactor));
		color: $colorforeground_Default;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include user-select($select)
		user-select: none;
		.p-menuitem-text {
			color: $colorforeground_Default;
		}
		.p-menuitem-icon {
			color: $colorforeground_Default;
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-submenu-icon {
			color: $colorforeground_Default;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 0 0 0.2rem $box_shadow;
		}
		&:not(.p-disabled):hover {
			background: $color_background_Default;
			.p-menuitem-text {
				color: $colorforeground_Default;
			}
			.p-menuitem-icon {
				color: $colorforeground_Default;
			}
			.p-submenu-icon {
				color: $colorforeground_Default;
			}
		}
	}
	.p-submenu-list {
		padding: 0;
		background: $black;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 6px 0 $box_shadow_2;
		width: (12.5rem *($widthFactor) );
		.p-menu-separator {
			border-top: (0.063rem * ($borderFactor)) solid $color_paddingtop_1;
			margin: 0;
		}
		.p-submenu-icon {
			font-size: ($fontSizeSmaller);
		}
		.p-menuitem {
			margin: 0;
			&:last-child {
				margin: 0;
			}
		}
	}
	.p-menubar-root-list > .p-menuitem {
		> .p-menuitem-link {
			padding: (0.857rem * ($paddingControlFactor));
			color: $colorforeground_Default;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include user-select($select)
			user-select: none;
			.p-menuitem-text {
				color: $colorforeground_Default;
			}
			.p-menuitem-icon {
				color: $colorforeground_Default;
				margin-right: (0.5rem * ($marginControlFactor));
			}
			.p-submenu-icon {
				color: $colorforeground_Default;
				margin-left: (0.5rem * ($marginControlFactor));
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 0 0 0.2rem $box_shadow;
			}
			&:not(.p-disabled):hover {
				background: $color_background_Default;
				.p-menuitem-text {
					color: $colorforeground_Default;
				}
				.p-menuitem-icon {
					color: $colorforeground_Default;
				}
				.p-submenu-icon {
					color: $colorforeground_Default;
				}
			}
		}
		&.p-menuitem-active > .p-menuitem-link {
			background: $color_background_Default;
			.p-menuitem-text {
				color: $colorforeground_Default;
			}
			.p-menuitem-icon {
				color: $colorforeground_Default;
			}
			.p-submenu-icon {
				color: $colorforeground_Default;
			}
			&:not(.p-disabled):hover {
				background: $color_background_Default;
				.p-menuitem-text {
					color: $colorforeground_Default;
				}
				.p-menuitem-icon {
					color: $colorforeground_Default;
				}
				.p-submenu-icon {
					color: $colorforeground_Default;
				}
			}
		}
	}
	.p-menuitem.p-menuitem-active > .p-menuitem-link {
		background: $color_background_Default;
		.p-menuitem-text {
			color: $colorforeground_Default;
		}
		.p-menuitem-icon {
			color: $colorforeground_Default;
		}
		.p-submenu-icon {
			color: $colorforeground_Default;
		}
	}
}
.p-panelmenu {
	.p-panelmenu-header {
		> a {
			padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
			border: (0.063rem * ($borderFactor)) solid $color_border;
			color: $colorforeground_Default;
			background: $data_background;
			font-weight: 700;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			.p-panelmenu-icon {
				margin-right: (0.5rem * ($marginControlFactor));
			}
			.p-menuitem-icon {
				margin-right: (0.5rem * ($marginControlFactor));
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
		}
		&.p-highlight {
			margin-bottom: 0;
			> a {
				background: $color_highlight;
				border-color: $color_highlight;
				color: $black;
				//Instead of the line below you could use @include border-bottom-right-radius($radius)
				border-bottom-right-radius: 0;
				//Instead of the line below you could use @include border-bottom-left-radius($radius)
				border-bottom-left-radius: 0;
			}
			&:not(.p-disabled) > a:hover {
				border-color: $color_hover_enabled;
				background: $color_hover_enabled;
				color: $black;
			}
		}
		&:not(.p-highlight):not(.p-disabled) > a:hover {
			background: $colorback_highlight_hover;
			border-color: $colorback_highlight_hover;
			color: $colorforeground_Default;
		}
	}
	.p-panelmenu-content {
		padding: 0;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		background: $black;
		color: $colorforeground_Default;
		margin-bottom: 2px;
		border-top: 0;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		.p-menuitem .p-menuitem-link {
			padding: (0.857rem * ($paddingControlFactor));
			color: $colorforeground_Default;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include user-select($select)
			user-select: none;
			.p-menuitem-text {
				color: $colorforeground_Default;
			}
			.p-menuitem-icon {
				color: $colorforeground_Default;
				margin-right: (0.5rem * ($marginControlFactor));
			}
			.p-submenu-icon {
				color: $colorforeground_Default;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 0 0 0.2rem $box_shadow;
			}
			.p-panelmenu-icon {
				margin-right: (0.5rem * ($marginControlFactor));
			}
			&:not(.p-disabled):hover {
				background: $color_background_Default;
				.p-menuitem-text {
					color: $colorforeground_Default;
				}
				.p-menuitem-icon {
					color: $colorforeground_Default;
				}
				.p-submenu-icon {
					color: $colorforeground_Default;
				}
			}
		}
		.p-submenu-list:not(.p-panelmenu-root-submenu) {
			padding: 0 0 0 (1rem *($paddingControlFactor) );
		}
	}
	.p-panelmenu-panel {
		margin-bottom: 2px;
	}
}
.p-slidemenu {
	padding: 0;
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	width: (12.5rem *($widthFactor) );
	.p-menuitem-link {
		padding: (0.857rem * ($paddingControlFactor));
		color: $colorforeground_Default;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include user-select($select)
		user-select: none;
		.p-menuitem-text {
			color: $colorforeground_Default;
		}
		.p-menuitem-icon {
			color: $colorforeground_Default;
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-submenu-icon {
			color: $colorforeground_Default;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 0 0 0.2rem $box_shadow;
		}
		&:not(.p-disabled):hover {
			background: $color_background_Default;
			.p-menuitem-text {
				color: $colorforeground_Default;
			}
			.p-menuitem-icon {
				color: $colorforeground_Default;
			}
			.p-submenu-icon {
				color: $colorforeground_Default;
			}
		}
	}
	&.p-slidemenu-overlay {
		background: $black;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 6px 0 $box_shadow_2;
	}
	.p-slidemenu-list {
		padding: 0;
		background: $black;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 6px 0 $box_shadow_2;
	}
	.p-slidemenu-separator {
		border-top: (0.063rem * ($borderFactor)) solid $color_paddingtop_1;
		margin: 0;
	}
	.p-slidemenu-icon {
		font-size: ($fontSizeSmaller);
	}
	.p-slidemenu-backward {
		padding: (0.857rem * ($paddingControlFactor));
		color: $colorforeground_Default;
	}
	.p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
		background: $color_background_Default;
		.p-slidemenu-text {
			color: $colorforeground_Default;
		}
		.p-slidemenu-icon {
			color: $colorforeground_Default;
			color: $colorforeground_Default;
		}
	}
}
.p-tieredmenu {
	padding: 0;
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	width: (12.5rem *($widthFactor) );
	.p-menuitem-link {
		padding: (0.857rem * ($paddingControlFactor));
		color: $colorforeground_Default;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include user-select($select)
		user-select: none;
		.p-menuitem-text {
			color: $colorforeground_Default;
		}
		.p-menuitem-icon {
			color: $colorforeground_Default;
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-submenu-icon {
			color: $colorforeground_Default;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 0 0 0.2rem $box_shadow;
		}
		&:not(.p-disabled):hover {
			background: $color_background_Default;
			.p-menuitem-text {
				color: $colorforeground_Default;
			}
			.p-menuitem-icon {
				color: $colorforeground_Default;
			}
			.p-submenu-icon {
				color: $colorforeground_Default;
			}
		}
	}
	&.p-tieredmenu-overlay {
		background: $black;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 6px 0 $box_shadow_2;
	}
	.p-submenu-list {
		padding: 0;
		background: $black;
		border: (0.063rem * ($borderFactor)) solid $color_border;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 6px 0 $box_shadow_2;
	}
	.p-menuitem {
		margin: 0;
		&:last-child {
			margin: 0;
		}
		&.p-menuitem-active > .p-menuitem-link {
			background: $color_background_Default;
			.p-menuitem-text {
				color: $colorforeground_Default;
			}
			.p-menuitem-icon {
				color: $colorforeground_Default;
			}
			.p-submenu-icon {
				color: $colorforeground_Default;
			}
		}
	}
	.p-menu-separator {
		border-top: (0.063rem * ($borderFactor)) solid $color_paddingtop_1;
		margin: 0;
	}
	.p-submenu-icon {
		font-size: ($fontSizeSmaller);
	}
}
.p-inline-message {
	padding: (0.5rem * ($paddingControlFactor));
	margin: 0;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&.p-inline-message-info {
		background: $color_jordy_blue_approx;
		border: 0 none;
		border-width: 0;
		color: $colorforeground_Default;
		.p-inline-message-icon {
			color: $colorforeground_Default;
		}
	}
	&.p-inline-message-success {
		background: $color_surf_approx;
		border: 0 none;
		border-width: 0;
		color: $colorforeground_Default;
		.p-inline-message-icon {
			color: $colorforeground_Default;
		}
	}
	&.p-inline-message-warn {
		background: $colorback_warn_message;
		border: 0 none;
		border-width: 0;
		color: $colorforeground_Default;
		.p-inline-message-icon {
			color: $colorforeground_Default;
		}
	}
	&.p-inline-message-error {
		background: $color_sundown_approx;
		border: 0 none;
		border-width: 0;
		color: $colorforeground_Default;
		.p-inline-message-icon {
			color: $colorforeground_Default;
		}
	}
	.p-inline-message-icon {
		font-size: $fontSizeDefault;
		margin-right: (0.5rem * ($marginControlFactor));
	}
	.p-inline-message-text {
		font-size: $fontSizeDefault;
	}
	&.p-inline-message-icon-only .p-inline-message-icon {
		margin-right: 0;
	}
}
.p-message {
	margin: 1rem 0;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-message-wrapper {
		padding: (1rem *($paddingControlFactor) );
	}
	.p-message-close {
		width: (2rem * ($borderFactor));
		height: (2rem * ($borderFactor));
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50%;
		background: transparent;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		&:hover {
			background: $color_message_hover;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $box_shadow;
		}
	}
	&.p-message-info {
		background: $color_jordy_blue_approx;
		border: 0 none;
		border-width: 0;
		color: $colorforeground_Default;
		.p-message-icon {
			color: $colorforeground_Default;
		}
		.p-message-close {
			color: $colorforeground_Default;
		}
	}
	&.p-message-success {
		background: $color_surf_approx;
		border: 0 none;
		border-width: 0;
		color: $colorforeground_Default;
		.p-message-icon {
			color: $colorforeground_Default;
		}
		.p-message-close {
			color: $colorforeground_Default;
		}
	}
	&.p-message-warn {
		background: $colorback_warn_message;
		border: 0 none;
		border-width: 0;
		color: $colorforeground_Default;
		.p-message-icon {
			color: $colorforeground_Default;
		}
		.p-message-close {
			color: $colorforeground_Default;
		}
	}
	&.p-message-error {
		background: $color_sundown_approx;
		border: 0 none;
		border-width: 0;
		color: $colorforeground_Default;
		.p-message-icon {
			color: $colorforeground_Default;
		}
		.p-message-close {
			color: $colorforeground_Default;
		}
	}
	.p-message-text {
		font-size: $fontSizeDefault;
		font-weight: normal;
	}
	.p-message-icon {
		font-size: 1.5rem;
		margin-right: (0.5rem * ($marginControlFactor));
	}
	.p-message-summary {
		font-weight: 700;
	}
	.p-message-detail {
		margin-left: (0.5rem * ($marginControlFactor));
	}
}
.p-toast {
	opacity: 0.9;
	.p-toast-message {
		margin: 0 0 1rem 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0.188rem 6px 0 $box_shadow_2;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
		.p-toast-message-content {
			padding: (1rem *($paddingControlFactor) );
			border-width: 0;
			.p-toast-message-text {
				margin: 0 0 0 0.5rem;
			}
			.p-toast-message-icon {
				font-size: 2rem;
			}
			.p-toast-summary {
				font-weight: 700;
			}
			.p-toast-detail {
				margin: 0.5rem 0 0 0;
			}
		}
		.p-toast-icon-close {
			width: (2rem * ($paddingControlFactor));
			height: (2rem * ($paddingControlFactor));
      padding: (1rem *($paddingControlFactor) );
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			background: transparent;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:hover {
				background: $color_message_hover;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
		}
		&.p-toast-message-info {
			background: $color_jordy_blue_approx;
			border: 0 none;
			border-width: 0;
			color: $colorforeground_Default;
			.p-toast-message-icon {
				color: $colorforeground_Default;
			}
			.p-toast-icon-close {
				color: $colorforeground_Default;
			}
		}
		&.p-toast-message-success {
			background: $color_surf_approx;
			border: 0 none;
			border-width: 0;
			color: $colorforeground_Default;
			.p-toast-message-icon {
				color: $colorforeground_Default;
			}
			.p-toast-icon-close {
				color: $colorforeground_Default;
			}
		}
		&.p-toast-message-warn {
			background: $colorback_warn_message;
			border: 0 none;
			border-width: 0;
			color: $colorforeground_Default;
			.p-toast-message-icon {
				color: $colorforeground_Default;
			}
			.p-toast-icon-close {
				color: $colorforeground_Default;
			}
		}
		&.p-toast-message-error {
			background: $color_sundown_approx;
			border: 0 none;
			border-width: 0;
			color: $colorforeground_Default;
			.p-toast-message-icon {
				color: $colorforeground_Default;
			}
			.p-toast-icon-close {
				color: $colorforeground_Default;
			}
		}
	}
}
.p-galleria {
	.p-galleria-close {
		margin: (0.5rem * ($marginControlFactor));
		background: transparent;
		color: $color_athens_gray_approx;
		width: (4rem * $borderFactor);
		height: (4rem * $borderFactor);
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50%;
		.p-galleria-close-icon {
			font-size: 2rem;
		}
		&:hover {
			background: $colorback_hover_4;
			color: $color_athens_gray_approx;
		}
	}
	.p-galleria-item-nav {
		background: $black_20;
		color: $color_gallery;
		width: (4rem * $widthFactor);
		height: (4rem * $heightFactor);
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
		margin: 0 (0.5rem *($marginControlFactor) );
		.p-galleria-item-prev-icon {
			font-size: 2rem;
		}
		.p-galleria-item-next-icon {
			font-size: 2rem;
		}
		&:not(.p-disabled):hover {
			background: $black_30;
			color: $color_athens_gray_approx;
		}
	}
	.p-galleria-caption {
		background: $colorback_galleryonitem;
		color: $color_athens_gray_approx;
		padding: (1rem *($paddingControlFactor) );
	}
	.p-galleria-indicators {
		padding: (1rem *($paddingControlFactor) );
		.p-galleria-indicator {
			button {
				background-color: $data_background;
				width: (1rem * $widthFactor);
				height: (1rem * $heightFactor);
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 50%;
				&:hover {
					background: $color_background_Default;
				}
			}
			&.p-highlight button {
				background: $color_highlight;
				color: $black;
			}
		}
	}
	.p-galleria-thumbnail-container {
		background: $colorbck_gallery_container;
		padding: 1rem 0.25rem;
		.p-galleria-thumbnail-prev {
			margin: (0.5rem * ($marginControlFactor));
			background-color: transparent;
			color: $color_gallery;
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			&:hover {
				background: $colorback_hover_4;
				color: $color_gallery;
			}
		}
		.p-galleria-thumbnail-next {
			margin: (0.5rem * ($marginControlFactor));
			background-color: transparent;
			color: $color_gallery;
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			&:hover {
				background: $colorback_hover_4;
				color: $color_gallery;
			}
		}
		.p-galleria-thumbnail-item-content {
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
		}
	}
	&.p-galleria-indicators-bottom .p-galleria-indicator {
		margin-right: (0.5rem * ($marginControlFactor));
	}
	&.p-galleria-indicators-top .p-galleria-indicator {
		margin-right: (0.5rem * ($marginControlFactor));
	}
	&.p-galleria-indicators-left .p-galleria-indicator {
		margin-bottom: (0.5rem *($marginControlFactor) );
	}
	&.p-galleria-indicators-right .p-galleria-indicator {
		margin-bottom: (0.5rem *($marginControlFactor) );
	}
	&.p-galleria-indicator-onitem .p-galleria-indicators {
		background: $colorback_galleryonitem;
		.p-galleria-indicator {
			button {
				background: $color_background_Default;
				&:hover {
					background: $colorbckgr_gallery_hover;
				}
			}
			&.p-highlight button {
				background: $color_highlight;
				color: $black;
			}
		}
	}
}
.p-avatar {
	background-color: $color_border;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&.p-avatar-lg {
		width: 3rem;
		height: 3rem;
		font-size: 1.5rem;
		.p-avatar-icon {
			font-size: 1.5rem;
		}
	}
	&.p-avatar-xl {
		width: 4rem;
		height: 4rem;
		font-size: 2rem;
		.p-avatar-icon {
			font-size: 2rem;
		}
	}
}
.p-badge {
	background: $color_highlight;
	color: $black;
	font-size: ($fontSizeSmaller);
	font-weight: 700;
	min-width: (1.5rem * ($widthFactor));
	height: (1.5rem * ($heightFactor));
	line-height: (1.5rem * ($heightFactor));
	&.p-badge-secondary {
		background-color: $color_active_secondary;
		color: $black;
	}
	&.p-badge-success {
		background-color: $color_button_success;
		color: $black;
	}
	&.p-badge-info {
		background-color: $color_highlight;
		color: $black;
	}
	&.p-badge-warning {
		background-color: $color_back_warn;
		color: $colorforeground_Default;
	}
	&.p-badge-danger {
		background-color: $color_crimson_approx;
		color: $black;
	}
	&.p-badge-lg {
		font-size: 1.125rem;
		min-width: 2.25rem;
		height: 2.25rem;
		line-height: (2.25rem * ($heightFactor));
	}
	&.p-badge-xl {
		font-size: 1.5rem;
		min-width: 3rem;
		height: (3rem * ($heightFactor));
		line-height: (3rem * ($heightFactor));
	}
}
.p-chip {
	background-color: $color_border;
	color: $colorforeground_Default;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 16px;
	padding: 0 (0.429rem *($paddingControlFactor) );
	.p-chip-text {
		line-height: (1.5rem * ($heightFactor));
		margin-top: 0.2145rem;
		margin-bottom: 0.2145rem;
	}
	.p-chip-icon {
		margin-right: (0.5rem * ($marginControlFactor));
	}
	.pi-chip-remove-icon {
		margin-left: (0.5rem * ($marginControlFactor));
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $box_shadow;
		}
	}
	img {
		width: (1.929rem *$widthFactor);
		height: (1.929rem * $heightFactor);
		margin-left: (-0.429rem * $marginControlFactor);
		margin-right: (0.5rem * ($marginControlFactor));
	}
}
.p-progressbar {
	border: 0 none;
	height: 24px;
	background: $color_background_Default;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-progressbar-value {
		border: 0 none;
		margin: 0;
		background: $color_highlight;
	}
	.p-progressbar-label {
		color: $colorforeground_Default;
		line-height: (24px * $heightFactor) ;
	}
}
.p-scrolltop {
	width: 3rem;
	height: 3rem;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 50%;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0.188rem 6px 0 $box_shadow_2;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	&.p-link {
		background: $color_link;
		&:hover {
			background: $color_hover_link;
		}
	}
	.p-scrolltop-icon {
		font-size: 1.5rem;
		color: $data_background;
	}
}
.p-skeleton {
	background-color: $color_background_Default;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&:after {
		background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0));
	}
}
.p-tag {
	background: $color_highlight;
	color: $black;
	font-size: 0.75rem;
	font-weight: 700;
	padding: (0.25rem * $paddingControlFactor) (0.4rem * $paddingControlFactor);
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&.p-tag-success {
		background-color: $color_button_success;
		color: $black;
	}
	&.p-tag-info {
		background-color: $color_highlight;
		color: $black;
	}
	&.p-tag-warning {
		background-color: $color_back_warn;
		color: $colorforeground_Default;
	}
	&.p-tag-danger {
		background-color: $color_crimson_approx;
		color: $black;
	}
	.p-tag-icon {
		margin-right: 0.25rem;
		font-size: 0.75rem;
	}
}
.p-terminal {
	background: $black;
	color: $colorforeground_Default;
	border: (0.063rem * ($borderFactor)) solid $color_border;
	padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	.p-terminal-input {
		font-size: $fontSizeDefault;
		font-family: $font_0;
	}
}
p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
	border-color: $color_border_warn;
}
p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
	border-color: $color_border_warn;
}
p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
	border-color: $color_border_warn;
}
p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
	border-color: $color_border_warn;
}
.p-chips .p-chips-multiple-container {
	padding: (0.2145rem * ($paddingControlFactor)) (0.429rem *($paddingControlFactor));
	.p-chips-token {
		padding: (0.2145rem * ($paddingControlFactor)) (0.429rem *($paddingControlFactor));
		margin-right: (0.5rem * ($marginControlFactor));
		background: $color_highlight;
		color: $black;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
		.p-chips-token-icon {
			margin-left: (0.5rem * ($marginControlFactor));
		}
	}
	.p-chips-input-token {
		padding: (0.2145rem * ($paddingControlFactor)) 0;
		input {
			font-family: $font_0;
			font-size: $fontSizeDefault;
			color: $colorforeground_Default;
			padding: 0;
			margin: 0;
		}
	}
	&:not(.p-disabled) {
		&:hover {
			border-color: $colorforeground_Default;
		}
		&.p-focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $box_shadow;
			border-color: $color_highlight;
		}
	}
}
p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
	border-color: $color_border_warn;
}
p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
	border-color: $color_border_warn;
}
p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
	border-color: $color_border_warn;
}
p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
	border-color: $color_border_warn;
}
p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
	border-color: $color_border_warn;
}
.p-inputtext-sm .p-inputtext {
	font-size: ($fontSizeSmaller);
	padding: (0.375375rem * $paddingControlFactor) ( 0.375375rem * $paddingControlFactor);
}
.p-inputtext-lg .p-inputtext {
	font-size: $fontSizeBigger;
	padding: (0.53625rem * $paddingControlFactor) (0.53625rem * $paddingControlFactor);
}
p-listbox.ng-dirty.ng-invalid > .p-listbox {
	border-color: $color_border_warn;
}
.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
	padding: (0.2145rem * ($paddingControlFactor)) (0.429rem *($paddingControlFactor));
}
p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
	border-color: $color_border_warn;
}
p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
	border-color: $color_border_warn;
}
.p-selectbutton .p-button {
	background: $button_back_3;
	border: (0.063rem * ($borderFactor)) solid $button_back_3;
	color: $colorforeground_Default;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	.p-button-icon-left {
		color: $color_button_icon_1;
	}
	.p-button-icon-right {
		color: $color_button_icon_1;
	}
	&.p-highlight {
		background: $color_highlight;
		border-color: $color_highlight;
		color: $black;
		.p-button-icon-left {
			color: $black;
		}
		.p-button-icon-right {
			color: $black;
		}
		&:hover {
			background: $color_hover_enabled;
			border-color: $color_hover_enabled;
			color: $black;
			.p-button-icon-left {
				color: $black;
			}
			.p-button-icon-right {
				color: $black;
			}
		}
	}
	&:not(.p-disabled):not(.p-highlight):hover {
		background: $color_border;
		border-color: $color_border;
		color: $colorforeground_Default;
		.p-button-icon-left {
			color: $colorforeground_Default;
		}
		.p-button-icon-right {
			color: $colorforeground_Default;
		}
	}
}
p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
	border-color: $color_border_warn;
}
.p-togglebutton.p-button {
	background: $button_back_3;
	border: (0.063rem * ($borderFactor)) solid $button_back_3;
	color: $colorforeground_Default;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	.p-button-icon-left {
		color: $color_button_icon_1;
	}
	.p-button-icon-right {
		color: $color_button_icon_1;
	}
	&.p-highlight {
		background: $color_highlight;
		border-color: $color_highlight;
		color: $black;
		.p-button-icon-left {
			color: $black;
		}
		.p-button-icon-right {
			color: $black;
		}
		&:hover {
			background: $color_hover_enabled;
			border-color: $color_hover_enabled;
			color: $black;
			.p-button-icon-left {
				color: $black;
			}
			.p-button-icon-right {
				color: $black;
			}
		}
	}
	&:not(.p-disabled):not(.p-highlight):hover {
		background: $color_border;
		border-color: $color_border;
		color: $colorforeground_Default;
		.p-button-icon-left {
			color: $colorforeground_Default;
		}
		.p-button-icon-right {
			color: $colorforeground_Default;
		}
	}
}
p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
	border-color: $color_border_warn;
}
.p-scrollpanel .p-scrollpanel-bar {
	background: $color_alabaster_approx;
	border: 0 none;
}
.p-dialog-mask.p-component-overlay {
	background-color: $black_40;
}
.p-sidebar-mask.p-component-overlay {
	background: $black_40;
}
.p-fileupload-choose:not(.p-disabled) {
	&:hover {
		background: $color_hover_enabled;
		color: $black;
		border-color: $color_hover_enabled;
	}
	&:active {
		background: $color_hover_enabled;
		color: $black;
		border-color: $color_hover_enabled;
	}
}
.p-steps .p-steps-item {
	.p-menuitem-link {
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
		background: $black;
		.p-steps-number {
			color: $colorforeground_Default;
			border: (0.063rem * ($borderFactor)) solid $color_border_stepsnumber;
			background: $black;
			min-width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			line-height: (2rem * ($heightFactor));
			font-size: 1.143rem;
			z-index: 1;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
		}
		.p-steps-title {
			margin-top: (0.5rem *($marginControlFactor) );
			color: $color_active_1;
		}
		&:not(.p-disabled):focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $box_shadow;
		}
	}
	&.p-highlight {
		.p-steps-number {
			background: $color_highlight;
			color: $black;
		}
		.p-steps-title {
			font-weight: 700;
			color: $colorforeground_Default;
		}
	}
	&:before {
		content: " ";
		border-top: (0.063rem * ($borderFactor)) solid $color_paddingtop_1;
		width: 100%;
		top: 50%;
		left: 0;
		display: block;
		position: absolute;
		margin-top: (-1rem *($marginControlFactor) );
	}
}
.p-tabmenu .p-tabmenu-nav {
	background: transparent;
	border: 0 none;
	border-width: (0.063rem * ($borderFactor));
	.p-tabmenuitem {
		margin-right: 2px;
		.p-menuitem-link {
			border: (0.063rem * ($borderFactor)) solid $color_border;
			border-width: (0.063rem * ($borderFactor));
			border-color: $color_border;
			background: $data_background;
			color: $colorforeground_Default;
			padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
			font-weight: 700;
			//Instead of the line below you could use @include border-top-right-radius($radius)
			border-top-right-radius: 0.188rem;
			//Instead of the line below you could use @include border-top-left-radius($radius)
			border-top-left-radius: 0.188rem;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			margin: 0 0 (-(0.063rem * ($borderFactor))) 0;
			.p-menuitem-icon {
				margin-right: (0.5rem * ($marginControlFactor));
			}
			&:not(.p-disabled):focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $box_shadow;
			}
		}
		&:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
			background: $colorback_highlight_hover;
			border-color: $colorback_highlight_hover;
			color: $colorforeground_Default;
		}
		&.p-highlight .p-menuitem-link {
			background: $color_highlight;
			border-color: $color_highlight;
			color: $black;
		}
	}
}
.p-galleria-mask.p-component-overlay {
	background-color: $colorbck_gallery_container;
}
.p-avatar-group .p-avatar {
	border: 2px solid $black;
}
.p-blockui.p-component-overlay {
	background: $black_40;
}
.p-inplace .p-inplace-display {
	padding: (0.429rem *($paddingControlFactor)) (0.429rem *($paddingControlFactor));
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0 0.2rem $box_shadow;
	}
	&:not(.p-disabled):hover {
		background: $color_background_Default;
		color: $colorforeground_Default;
	}
}


.p-treeselect {
  color: $colorforeground_Default;
  border: 1px solid $color_border;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0.188rem;
}
.p-treeselect:not(.p-disabled):hover {
  border-color: $black_40;
}
.p-treeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $box_shadow;
  border-color: $box_shadow;
}
.p-treeselect .p-treeselect-label {
  padding: (0.429rem *($paddingControlFactor) ) (0.429rem *($paddingControlFactor) );
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-treeselect .p-treeselect-label.p-placeholder {
  color: $color_athens_gray_approx;
}
.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: (0.2145rem *($paddingControlFactor) ) (0.429rem*($paddingControlFactor) );
  margin-right: (0.5rem * ($marginControlFactor)) ;
  background: $black_40;
  color: $colorforeground_Default;
  border-radius: 16px;
}
.p-treeselect .p-treeselect-trigger {
  background: transparent;
  color: $colorforeground_Default;
  width: (2.357rem * ($widthFactor));
  border-top-right-radius: 0.188rem;
  border-bottom-right-radius: 0.188rem;
}


p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
  border-color: $color_border_warn;
}

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: (0.2145rem *($paddingControlFactor) ) (0.429rem *($paddingControlFactor) );
}

.p-treeselect-panel {

	background: $black_40;
	color: $colorforeground_Default;
  border: 1px solid $color_border;
  border-radius: 0.188rem;
  box-shadow: 0 0.188rem 6px 0 rgba(0, 0, 0, 0.16);
}
.p-treeselect-panel .p-treeselect-header {
  padding: (0.429rem*($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
  border-bottom: 1px solid $color_border;
  background: $black;
	color: $colorforeground_Default;
  margin: 0;
  border-top-right-radius: 0.188rem;
  border-top-left-radius: 0.188rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
  margin-right: ( 0.5rem * ($marginControlFactor));
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
  padding-right: (1.429rem *($paddingControlFactor) );
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
  right: (0.429rem *($paddingRightFactor) );
  color: $colorforeground_Default;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
  padding-right: (2.858rem *($paddingControlFactor) );
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
  right: ( 1.858rem *($paddingRightFactor) );
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
  width: (2rem * ($borderFactor));;
  height: (2rem * ($borderFactor));;
  color: $colorforeground_Default;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
  color: $colorforeground_Default;
  border-color: transparent;
  background: transparent;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $box_shadow;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0 none;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
  padding: (0.429rem *($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
  color: $black_40;
  background: transparent;
}

.p-input-filled .p-treeselect {
  background: $colorforeground_Default;
}
.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background: $color_hover_enabled;
}
.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  background-color: $colorforeground_Default;
}

p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
  padding-right: (1.429rem *($paddingRightFactor));
}
p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
  color: $colorforeground_Default;
  right: (2.357rem *($paddingRightFactor));
}
