@charset "UTF-8";


//primeng variablen
:root {
  --surface-a: #ffffff;
  --surface-b: #f4f4f4;
  --surface-c: #eaeaea;
  --surface-d: #c8c8c8;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #333333;
  --text-color-secondary: #848484;
  --primary-color: #007ad9;
  --primary-color-text: #ffffff;
  --font-family: 'Open Sans', sans-serif;
}

//Variablen die auch global verwendet werden
:root
{
  /*Background allgemein*/
  --backgroundCommonContent: white;
  /*Background disabled allgemein*/
  --backgroundCommonContentDisabled: #eaeaea;
  /*Background disabled Button*/
  --backgroundButtonDisabled: #8dcdff;
  /*disabled color algemein*/
  --colorCommonContentDisabled: black;
  /*Unter Überschrift in Tabelle */
  --colorTableHeader: darkgray;
  /*Menufarben Navigation*/
  --colorMenu: black;
  --backgroundMenu: white;
  --colorMenuIcon: rgb(48, 46, 46);
  /*Menu Ende*/

  /*Aktives Item */
  --colorItemActive: #05273b;
  /*Backround aktiv*/
  --backgroundActive: #eeebcf;
  /*Trenner Liste */
  --colorDivider: gray;
  /*Aktion wie Add im Header einer Seite z.B. medientipps*/
  --colorActionButtonIcon: darkgray;

}


$paddingControlFactor:  1 !default ;
$marginControlFactor: 1 !default ;
$paddingRightFactor: 1 !default ;
$widthFactor: 1 !default ;
$heightFactor: 1 !default ;
$borderFactor: 1 !default ;


//lipo sass zuordnung für diese MAIN-CSS

/*Background allgemein*/
$backgroundCommonContent: var(--backgroundCommonContent);
/*Background disabled allgemein*/
$backgroundCommonContentDisabled: var(--backgroundCommonContentDisabled);
/*Background disabled Button*/
$backgroundButtonDisabled: var(--backgroundCommonContent);
/*disabled color algemein*/
$colorCommonContentDisabled: var(--colorCommonContentDisabled);
/*Unter Überschrift in Tabelle */
$colorTableHeader: var(--colorTableHeader);
/*Menufarben Navigation*/

$backgroundMenu: var(--backgroundMenu);
$colorMenuIcon: var(--colorMenuIcon);
/*Menu Ende*/

/*Aktives Item */
$colorItemActive: var(--colorItemActive);
/*Backround aktiv*/
$backgroundActive:  var(--backgroundActive);
/*Trenner Liste */
$colorDivider: var(--colorDivider);
/*Aktion wie Add im Header einer Seite z.B. medientipps*/
$colorActionButtonIcon: var(--colorActionButtonIcon);



//https://blog.logrocket.com/how-to-create-better-themes-with-css-variables-5a3744105c74/
//https://sass-lang.com/documentation/variables
/*Fontsizes*/
$fontSizeDefault: var(--fontSizeDefault) !default;
$font-family: 'Open Sans', sans-serif;
$fontSizeBigger: 1.286rem !default ;
$fontSizeSmaller: 0.875rem !default ;
/**/

//colors
$color_mine_shaft_approx: #333333;
$color_mercury_approx: #eaeaea;
$color_malibu_approx1: #8dcdff;
$white: #ffffff;
$color_silver_approx: #c8c8c8;
$black_16: rgba(0, 0, 0, 0.16);
$color_mischka_approx: #d8dae2;
$black_40: rgba(0, 0, 0, 0.4);
$color_bright_red_approx: #a80000;
$color_gray_approx: #848484;
$color_lochmara_approx: #007ad9;
$wild_sand: #f4f4f4;
$color_silver_chalice_approx: #a6a6a6;
$color_alto_approx: #d0d0d0;
$color_dove_gray_approx: #666666;
$color_endeavour_approx: #005b9f;
$mine_shaft: #323232;
$color_cod_gray_approx: #191919;
$color_nobel_approx: #b7b7b7;
$color_denim_approx: #116fbf;
$color_hollywood_cerise_approx: #e4018d;
$color_violet_eggplant_approx: #b5019f;
$color_lochmara_4_approx: rgba(0, 122, 217, 0.04);
$color_lochmara_16_approx: rgba(0, 122, 217, 0.16);
$black_20: rgba(0, 0, 0, 0.2);
$black_14: rgba(0, 0, 0, 0.14);
$black_12: rgba(0, 0, 0, 0.12);
$color_smalt_blue_approx: #607d8b;
$color_blue_bayoux_approx: #546e7a;
$color_loblolly_approx: #b0bec5;
$color_fiord_approx: #455a64;
$color_smalt_blue_4_approx: rgba(96, 125, 139, 0.04);
$color_smalt_blue_16_approx: rgba(96, 125, 139, 0.16);
$color_apple_approx: #34a835;
$color_japanese_laurel_approx: #107d11;
$color_celadon_approx: #aae5aa;
$color_san_felix_approx: #0c6b0d;
$color_apple_4_approx: rgba(52, 168, 53, 0.04);
$color_apple_16_approx: rgba(52, 168, 53, 0.16);
$color_selective_yellow_approx: #ffba01;
$color_gamboge_approx: #ed990b;
$color_peach_approx: #ffeab4;
$color_meteor_approx: #d38b10;
$color_selective_yellow_4_approx: rgba(255, 186, 1, 0.04);
$color_selective_yellow_16_approx: rgba(255, 186, 1, 0.16);
$color_seance_approx: #8e24aa;
$color_light_wisteria_approx: #ce93d8;
$color_violet_eggplant_4_approx: rgba(156, 39, 176, 0.04);
$color_violet_eggplant_16_approx: rgba(156, 39, 176, 0.16);
$color_crimson_approx: #e91224;
$color_monza_approx: #c01120;
$color_sundown_approx: #f9b4ba;
$color_crimson_4_approx: rgba(233, 18, 36, 0.04);
$color_crimson_16_approx: rgba(233, 18, 36, 0.16);
$color_alabaster_approx: #f9f9f9;
$color_prussian_blue_approx: #00325a;
$alto: #dbdbdb;
$black_24: rgba(0, 0, 0, 0.24);
$color_iron_approx: #dee2e6;
$color_jordy_blue_approx: #7fbcec;
$color_surf_approx: #b7d8b7;
$color_golden_glow_approx: #ffe399;
$white_30: rgba(255, 255, 255, 0.3);
$color_athens_gray_approx: #ebedef;
$white_10: rgba(255, 255, 255, 0.1);
$color_cadet_blue_approx: #aeb6bf;
$black_30: rgba(0, 0, 0, 0.3);
$black_50: rgba(0, 0, 0, 0.5);
$white_40: rgba(255, 255, 255, 0.4);
$white_60: rgba(255, 255, 255, 0.6);
$black_90: rgba(0, 0, 0, 0.9);
$black_70: rgba(0, 0, 0, 0.7);
$black_80: rgba(0, 0, 0, 0.8);


//fonts
$font_0: $font-family;
$font_icons: "PrimeIcons" !important;


@debug $paddingControlFactor;

//@extend-elements
//original selectors
//.p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next
%extend_1 {
	width: (2rem * ($borderFactor));
	height: (2rem * ($borderFactor));
	color: $color_silver_chalice_approx;
	border: 0 none;
	background: transparent;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 50%;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

//original selectors
//.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover
%extend_2 {
	color: $color_lochmara_approx;
	border-color: transparent;
	background: transparent;
}

//original selectors
//.p-datepicker .p-datepicker-header .p-datepicker-prev:focus, .p-datepicker .p-datepicker-header .p-datepicker-next:focus
%extend_3 {
	outline: 0 none;
	outline-offset: 0;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
}



* {
	//Instead of the line below you could use @include box-sizing($bs)
	box-sizing: border-box;
}
.p-component {
	font-family: $font_0;
	font-size: $fontSizeDefault;
	font-weight: normal;
	&:disabled {
		opacity: 0.5;
	}
}
.p-component-overlay {
	background-color: $black_40;
	//Instead of the line below you could use @include transition-duration($duration-1, $duration-2, $duration-3, $duration-4, $duration-5, $duration-6, $duration-7, $duration-8, $duration-9, $duration-10)
	transition-duration: 0.2s;
}
.p-disabled {
	opacity: 0.5;
}
.p-error {
	color: $color_bright_red_approx;
}
.p-text-secondary {
	color: $color_gray_approx;
}
.pi {
	font-size: $fontSizeDefault;
}

.p-link {
	font-size: $fontSizeDefault;
	font-family: $font_0;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
	}
}
.p-autocomplete-clear-icon {
  position: absolute;
  top: 50%;
  margin-top: -.5rem;
  cursor: pointer;
  right: (0.429rem *($paddingRightFactor));
}

.p-autocomplete-clearable {
  position: relative;
}


.p-autocomplete {
  .p-autocomplete-input
  {
    padding-right: (1.858rem  *($paddingRightFactor));
  }
	.p-autocomplete-loader {
		right: (0.429rem *($paddingRightFactor));
	}
	.p-autocomplete-multiple-container {
		padding: (0.2145rem * ($paddingControlFactor)) (0.429rem *($paddingControlFactor));
		.p-autocomplete-input-token {
			padding: (0.2145rem * ($paddingControlFactor)) 0;
			input {
				font-family: $font_0;
				font-size: $fontSizeDefault;
				color: $color_mine_shaft_approx;
				padding: 0;
				margin: 0;
			}
		}
		.p-autocomplete-token {
			padding: (0.2145rem * ($paddingControlFactor)) (0.429rem *($paddingControlFactor));
			margin-right: (0.5rem * ($marginControlFactor));
			background: $color_lochmara_approx;
			color: $white;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			.p-autocomplete-token-icon {
				margin-left: (0.5rem * ($marginControlFactor));
			}
		}
		&:not(.p-disabled) {
			&:hover {
				border-color: $color_mine_shaft_approx;
			}
			&.p-focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
				border-color: $color_lochmara_approx;
			}
		}
	}
	&.p-autocomplete-dd .p-autocomplete-loader {
		right: (2.786rem *($paddingRightFactor));
	}
}
.p-autocomplete-panel {
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0.188rem 0.375rem 0 $black_16;
	.p-autocomplete-items {
		padding: 0;
		.p-autocomplete-item {
			margin: 0;
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			border: 0 none;
			color: $color_mine_shaft_approx;
			background: transparent;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			&:hover {
				color: $color_mine_shaft_approx;
				background: $color_mercury_approx;
			}
			&.p-highlight {
				color: $white;
				background: $color_lochmara_approx;
			}
		}
		.p-autocomplete-item-group {
			margin: 0;
			padding: (0.857rem * ($paddingControlFactor));
			color: $color_mine_shaft_approx;
			background: $wild_sand;
			font-weight: 700;
		}
	}
}
.p-datepicker {
	padding: (0.857rem * ($paddingControlFactor));
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_chalice_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&:not(&.p-datepicker-inline) {
			background: $white;
			border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0.188rem 0.375rem 0 $black_16;
			.p-datepicker-header {
				background: $white;
			}
		}
		&:not(&.p-disabled) {
			table td span:not(.p-highlight):not(.p-disabled) {
				&:hover {
					background: $color_mercury_approx;
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
				}
			}
			.p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled) {
				&:hover {
					background: $color_mercury_approx;
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
				}
			}
		}

	.p-datepicker-header  {
		padding: (0.5rem * ($paddingControlFactor));
		color: $color_mine_shaft_approx;
		background: $white;
		font-weight: 700;
		margin: 0;
		border-bottom: 0 none;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-datepicker-prev {
			@extend %extend_1;
			&:focus {
				@extend %extend_3;
			}
			&:enabled:hover {
				@extend %extend_2;
			}
		}
		.p-datepicker-next {
			@extend %extend_1;
			&:focus {
				@extend %extend_3;
			}
			&:enabled:hover {
				@extend %extend_2;
			}
		}
		.p-datepicker-title {
			line-height: (2rem * ($heightFactor));
			select {
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
					border-color: $color_lochmara_approx;
				}
			}
			.p-datepicker-month {
				margin-right: (0.5rem * ($marginControlFactor));
			}
		}
	}
	table {
		font-size: $fontSizeDefault;
		margin-right: (0.857rem * ($marginControlFactor)) 0;
		th {
			padding: (0.5rem * ($paddingControlFactor));
			> span {
				width: (2.5rem * ($widthFactor));
				height: (2.5rem * ($heightFactor));
			}
		}
		td {
			padding: (0.5rem * ($paddingControlFactor));
			> span {
				width: (2.5rem * ($widthFactor));
				height: (2.5rem * ($heightFactor));
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 0.188rem;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, box-shadow 0.2s;
				border: (0.063rem * ($borderFactor)) solid transparent;
				&.p-highlight {
					color: $white;
					background: $color_lochmara_approx;
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
				}
			}
			&.p-datepicker-today > span {
				background: $color_alto_approx;
				color: $color_mine_shaft_approx;
				border-color: transparent;
				&.p-highlight {
					color: $white;
					background: $color_lochmara_approx;
				}
			}
		}
	}
	.p-datepicker-buttonbar {
		padding: (0.857rem * ($paddingControlFactor)) 0 (0.429rem * ($paddingControlFactor)) 0;
		border-top: (0.063rem * ($borderFactor)) solid $color_mischka_approx;
		.p-button {
			width: auto;
		}
	}
	.p-timepicker {
		border-top: (0.063rem * ($borderFactor)) solid $color_mischka_approx;
		padding: (0.857rem * ($paddingControlFactor)) 0 (0.429rem * ($paddingControlFactor)) 0;
		button {
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			color: $color_silver_chalice_approx;
			border: 0 none;
			background: transparent;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
			&:last-child {
				margin-top: (0.2rem * ($marginControlFactor));
			}
			&:enabled:hover {
				color: $color_lochmara_approx;
				border-color: transparent;
				background: transparent;
			}
		}
		span {
			font-size: ($fontSizeBigger);
		}
		> div {
			padding: 0 (0.429rem *($paddingControlFactor) );
		}
	}
	.p-monthpicker {
		margin-right: (0.857rem * ($marginControlFactor)) 0;
		.p-monthpicker-month {
			padding: (0.5rem * ($paddingControlFactor));
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			&.p-highlight {
				color: $white;
				background: $color_lochmara_approx;
			}
		}
	}
	&.p-datepicker-timeonly .p-timepicker {
		border-top: 0 none;
	}
	&.p-datepicker-multiple-month .p-datepicker-group {
		border-right: (0.063rem * ($borderFactor)) solid $color_mischka_approx;
		padding-right: (0.857rem *($paddingControlFactor) );
		padding-left: (0.857rem *($paddingControlFactor) );
		padding-top: 0;
		padding-bottom: 0;
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
			border-right: 0 none;
		}
	}
}


//primeng 13

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: $color_mine_shaft_approx;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  font-weight: 700;
  padding: (0.5rem * ($paddingControlFactor));
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: $color_lochmara_approx;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right:(0.5rem * ($marginControlFactor));
}

.p-datepicker .p-monthpicker {
  margin: (0.857rem * ($marginControlFactor)) 0;;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: (0.5rem * ($paddingControlFactor));
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: 0.188rem;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: $white;
  background: $color_lochmara_approx;
}

.p-datepicker .p-yearpicker {
  margin-right: (0.857rem * ($marginControlFactor)) 0;
}
.p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: (0.5rem * ($paddingControlFactor));
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: 0.188rem;
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: $white;
  background: $color_lochmara_approx;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: $color_mercury_approx;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
}


@media screen and (max-width: 769px) {
	.p-datepicker table {
		th {
			padding: (0.25rem *($paddingControlFactor) );
		}
		td {
			padding: (0.25rem *($paddingControlFactor) );
		}
	}
}
.p-cascadeselect {
	background: $white;
	border: (0.063rem * ($borderFactor)) solid $color_silver_chalice_approx;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-cascadeselect-label {
		background: transparent;
		border: 0 none;
		padding: (0.429rem *($paddingControlFactor)) (0.429rem*($paddingControlFactor));
		&.p-placeholder {
			color: $color_dove_gray_approx;
		}
		&:enabled:focus {
			outline: 0 none;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: none;
		}
	}
	.p-cascadeselect-trigger {
		background: transparent;
		color: $color_gray_approx;
		width: (2.357rem *($widthFactor));
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
	}
	&:not(.p-disabled) {
		&:hover {
			border-color: $color_mine_shaft_approx;
		}
		&.p-focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			border-color: $color_lochmara_approx;
		}
	}
}
.p-cascadeselect-panel {
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0.188rem 0.375rem 0 $black_16;
	min-width: (12.5rem *($widthFactor) );
	.p-cascadeselect-items {
		padding: 0;
		.p-cascadeselect-item {
			margin: 0;
			border: 0 none;
			color: $color_mine_shaft_approx;
			background: transparent;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			.p-cascadeselect-item-content {
				padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
				}
			}
			&.p-highlight {
				color: $white;
				background: $color_lochmara_approx;
			}
			.p-cascadeselect-group-icon {
				font-size: ($fontSizeSmaller);
			}
			&:not(.p-highlight):not(.p-disabled):hover {
				color: $color_mine_shaft_approx;
				background: $color_mercury_approx;
			}
		}
	}
}
.p-input-filled {
	.p-cascadeselect {
		background: $wild_sand;
		&:not(.p-disabled) {
			&:hover {
				background-color: $wild_sand;
			}
			&.p-focus {
				background-color: $wild_sand;
			}
		}
	}
	.p-checkbox {
		.p-checkbox-box {
			background-color: $wild_sand;
			&.p-highlight {
				background: $color_lochmara_approx;
			}
		}
		&:not(.p-checkbox-disabled) .p-checkbox-box {
			&:hover {
				background-color: $wild_sand;
			}
			&.p-highlight:hover {
				background: $color_endeavour_approx;
			}
		}
	}
	.p-dropdown {
		background: $wild_sand;
		&:not(.p-disabled) {
			&:hover {
				background-color: $wild_sand;
			}
			&.p-focus {
				background-color: $wild_sand;
			}
		}
	}
	.p-inputtext {
		background-color: $wild_sand;
		&:enabled {
			&:hover {
				background-color: $wild_sand;
			}
			&:focus {
				background-color: $wild_sand;
			}
		}
	}
	.p-multiselect {
		background: $wild_sand;
		&:not(.p-disabled) {
			&:hover {
				background-color: $wild_sand;
			}
			&.p-focus {
				background-color: $wild_sand;
			}
		}
	}
	.p-radiobutton .p-radiobutton-box {
		background-color: $wild_sand;
		&.p-highlight {
			background: $color_lochmara_approx;
			&:not(.p-disabled):hover {
				background: $color_endeavour_approx;
			}
		}
		&:not(.p-disabled):hover {
			background-color: $wild_sand;
		}
	}
}
.p-checkbox {
	width: (1.25rem * $widthFactor);
	height: (1.25rem * $heightFactor);
	.p-checkbox-box {
		border: (0.063rem * ($borderFactor)) solid $color_silver_chalice_approx;
		background: $white;
		width: (1.25rem * $widthFactor);
		height: (1.25rem * $heightFactor);
		color: $color_mine_shaft_approx;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		.p-checkbox-icon {
			//Instead of the line below you could use @include transition-duration($duration-1, $duration-2, $duration-3, $duration-4, $duration-5, $duration-6, $duration-7, $duration-8, $duration-9, $duration-10)
			transition-duration: 0.2s;
			color: $white;
			font-size: (0.875rem * $paddingControlFactor);
		}
		&.p-highlight {
			border-color: $color_lochmara_approx;
			background: $color_lochmara_approx;
		}
	}
	&:not(.p-checkbox-disabled) .p-checkbox-box {
		&:hover {
			border-color: $color_mine_shaft_approx;
		}
		&.p-focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			border-color: $color_lochmara_approx;
		}
		&.p-highlight:hover {
			border-color: $color_endeavour_approx;
			background: $color_endeavour_approx;
			color: $white;
		}
	}
}
.p-checkbox-label {
	margin-left: (0.5rem * ($marginControlFactor));
}
.p-highlight {
	.p-checkbox .p-checkbox-box {
		border-color: $white;
	}
	.p-radiobutton .p-radiobutton-box {
		border-color: $white;
	}
	.p-rating .p-rating-icon {
		color: $white;
	}
}
.p-colorpicker-preview {
	width: (2rem * ($widthFactor));
	height: (2rem * ($heightFactor));
}
.p-fluid {
	.p-button {
		width: 100%;
	}
	.p-button-icon-only {
		width: (2.357rem *($widthFactor));
	}
	.p-buttonset {
		display: flex;
		.p-button {
			flex: 1;
		}
	}
	.p-colorpicker-preview.p-inputtext {
		width: (2rem * ($widthFactor));
		height: (2rem * ($heightFactor));
	}
	.p-inputgroup .p-button {
		width: auto;
		&.p-button-icon-only {
			width: (2.357rem *($widthFactor) );
		}
	}
}
.p-colorpicker-panel {
	background: $mine_shaft;
	border-color: $color_cod_gray_approx;
	.p-colorpicker-color-handle {
		border-color: $white;
	}
	.p-colorpicker-hue-handle {
		border-color: $white;
	}
}
.p-colorpicker-overlay-panel {
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0.188rem 0.375rem 0 $black_16;
}
.p-dropdown {
	background: $white;
	border: (0.063rem * ($borderFactor)) solid $color_silver_chalice_approx;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-dropdown-label {

		background: transparent;
		border: 0 none;
		&.p-placeholder {
			color: $color_dove_gray_approx;
		}
		&:enabled:focus {
			outline: 0 none;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: none;
		}
	}
	.p-dropdown-trigger {
		background: transparent;
		color: $color_gray_approx;
		width: (2.357rem *($widthFactor) );
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
	}
	.p-dropdown-clear-icon {
		color: $color_mine_shaft_approx;
		right: (2.357rem *($paddingRightFactor));
	}
	&:not(.p-disabled) {
		&:hover {
			border-color: $color_mine_shaft_approx;
		}
		&.p-focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			border-color: $color_lochmara_approx;
		}
	}
	&.p-dropdown-clearable .p-dropdown-label {
		padding-right: (1.429rem * ($paddingControlFactor));
	}
}
.p-dropdown-panel {
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0.188rem 0.375rem 0 $black_16;
	.p-dropdown-header {
		padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
		border-bottom: (0.063rem * ($borderFactor)) solid $color_mercury_approx;
		color: $color_mine_shaft_approx;
		background: $white;
		margin: 0;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-dropdown-filter {
			padding-right: (1.429rem * ($paddingControlFactor));
		}
		.p-dropdown-filter-icon {
			right: (0.429rem *($paddingRightFactor));
			color: $color_gray_approx;
		}
	}
	.p-dropdown-items {
		padding: 0;
		.p-dropdown-item {
			margin: 0;
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			border: 0 none;
			color: $color_mine_shaft_approx;
			background: transparent;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			&.p-highlight {
				color: $white;
				background: $color_lochmara_approx;
			}
			&:not(.p-highlight):not(.p-disabled):hover {
				color: $color_mine_shaft_approx;
				background: $color_mercury_approx;
			}
		}
		.p-dropdown-empty-message {
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			color: $color_mine_shaft_approx;
			background: transparent;
		}
		.p-dropdown-item-group {
			margin: 0;
			padding: (0.857rem * ($paddingControlFactor));
			color: $color_mine_shaft_approx;
			background: $wild_sand;
			font-weight: 700;
		}
	}
}
.p-editor-container {
	.p-editor-toolbar {
		background: $wild_sand;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		&.ql-snow {
			border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
			.ql-stroke {
				stroke: $color_gray_approx;
			}
			.ql-fill {
				fill: $color_gray_approx;
			}
			.ql-picker {
				.ql-picker-label {
					border: 0 none;
					color: $color_gray_approx;
					&:hover {
						color: $color_mine_shaft_approx;
						.ql-stroke {
							stroke: $color_mine_shaft_approx;
						}
						.ql-fill {
							fill: $color_mine_shaft_approx;
						}
					}
				}
				&.ql-expanded {
					.ql-picker-label {
						color: $color_mine_shaft_approx;
						.ql-stroke {
							stroke: $color_mine_shaft_approx;
						}
						.ql-fill {
							fill: $color_mine_shaft_approx;
						}
					}
					.ql-picker-options {
						background: $white;
						border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
						//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
						box-shadow: 0 0.188rem 0.375rem 0 $black_16;
						//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
						border-radius: 0.188rem;
						padding: 0;
						.ql-picker-item {
							color: $color_mine_shaft_approx;
							&:hover {
								color: $color_mine_shaft_approx;
								background: $color_mercury_approx;
							}
						}
					}
					&:not(.ql-icon-picker) .ql-picker-item {
						padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
					}
				}
			}
		}
	}
	.p-editor-content {
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		&.ql-snow {
			border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		}
		.ql-editor {
			background: $white;
			color: $color_mine_shaft_approx;
			//Instead of the line below you could use @include border-bottom-right-radius($radius)
			border-bottom-right-radius: 0.188rem;
			//Instead of the line below you could use @include border-bottom-left-radius($radius)
			border-bottom-left-radius: 0.188rem;
		}
	}
	.ql-snow.ql-toolbar {
		button {
			&:hover {
				color: $color_mine_shaft_approx;
				.ql-stroke {
					stroke: $color_mine_shaft_approx;
				}
				.ql-fill {
					fill: $color_mine_shaft_approx;
				}
			}
			&:focus {
				color: $color_mine_shaft_approx;
				.ql-stroke {
					stroke: $color_mine_shaft_approx;
				}
				.ql-fill {
					fill: $color_mine_shaft_approx;
				}
			}
			&.ql-active {
				color: $color_lochmara_approx;
				.ql-stroke {
					stroke: $color_lochmara_approx;
				}
				.ql-fill {
					fill: $color_lochmara_approx;
				}
				.ql-picker-label {
					color: $color_lochmara_approx;
				}
			}
		}
		.ql-picker-label.ql-active {
			color: $color_lochmara_approx;
			.ql-stroke {
				stroke: $color_lochmara_approx;
			}
			.ql-fill {
				fill: $color_lochmara_approx;
			}
			.ql-picker-label {
				color: $color_lochmara_approx;
			}
		}
		.ql-picker-item.ql-selected {
			color: $color_lochmara_approx;
			.ql-stroke {
				stroke: $color_lochmara_approx;
			}
			.ql-fill {
				fill: $color_lochmara_approx;
			}
			.ql-picker-label {
				color: $color_lochmara_approx;
			}
		}
	}
}
.p-inputgroup-addon {
	background: $color_mercury_approx;
	color: $color_gray_approx;
	border-top: (0.063rem * ($borderFactor)) solid $color_silver_chalice_approx;
	border-left: (0.063rem * ($borderFactor)) solid $color_silver_chalice_approx;
	border-bottom: (0.063rem * ($borderFactor)) solid $color_silver_chalice_approx;
	padding: (0.429rem *($paddingControlFactor)) (0.429rem *($paddingControlFactor));
	min-width: (2.357rem *($widthFactor));
	&:last-child {
		border-right: (0.063rem * ($borderFactor)) solid $color_silver_chalice_approx;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
	}
	&:first-child {
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
	}
}
.p-inputgroup {
	> {
		.p-component {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			margin: 0;
			&:focus {
				z-index: 1;
				~ label {
					z-index: 1;
				}
			}
			+ .p-inputgroup-addon {
				border-left: 0 none;
			}
		}
		.p-float-label > .p-component {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			margin: 0;
			&:focus {
				z-index: 1;
				~ label {
					z-index: 1;
				}
			}
			+ .p-inputgroup-addon {
				border-left: 0 none;
			}
		}
	}
	button {
		&:first-child {
			//Instead of the line below you could use @include border-top-left-radius($radius)
			border-top-left-radius: 0.188rem;
			//Instead of the line below you could use @include border-bottom-left-radius($radius)
			border-bottom-left-radius: 0.188rem;
		}
		&:last-child {
			//Instead of the line below you could use @include border-top-right-radius($radius)
			border-top-right-radius: 0.188rem;
			//Instead of the line below you could use @include border-bottom-right-radius($radius)
			border-bottom-right-radius: 0.188rem;
		}
	}
	input {
		&:first-child {
			//Instead of the line below you could use @include border-top-left-radius($radius)
			border-top-left-radius: 0.188rem;
			//Instead of the line below you could use @include border-bottom-left-radius($radius)
			border-bottom-left-radius: 0.188rem;
		}
		&:last-child {
			//Instead of the line below you could use @include border-top-right-radius($radius)
			border-top-right-radius: 0.188rem;
			//Instead of the line below you could use @include border-bottom-right-radius($radius)
			border-bottom-right-radius: 0.188rem;
		}
	}
	.p-float-label {
		&:first-child input {
			//Instead of the line below you could use @include border-top-left-radius($radius)
			border-top-left-radius: 0.188rem;
			//Instead of the line below you could use @include border-bottom-left-radius($radius)
			border-bottom-left-radius: 0.188rem;
		}
		&:last-child input {
			//Instead of the line below you could use @include border-top-right-radius($radius)
			border-top-right-radius: 0.188rem;
			//Instead of the line below you could use @include border-bottom-right-radius($radius)
			border-bottom-right-radius: 0.188rem;
		}
	}
}
.p-inputswitch {
	width: (3rem * $marginControlFactor);
	height: (1.75rem *$marginControlFactor);
	.p-inputswitch-slider {
		background: $color_silver_approx;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: (1.875rem* $marginControlFactor);;
		&:before {
			background: $white;
			width: (1.25rem * $marginControlFactor);
			height: (1.25rem * $marginControlFactor);
			left: (0.25rem * $marginControlFactor);

			margin-top: (-0.625rem * $marginControlFactor);;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include transition-duration($duration-1, $duration-2, $duration-3, $duration-4, $duration-5, $duration-6, $duration-7, $duration-8, $duration-9, $duration-10)
			transition-duration: 0.2s;
		}
	}
	&.p-inputswitch-checked {
		.p-inputswitch-slider {
			background: $color_lochmara_approx;
			&:before {
				//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
				transform: translateX(( 1.25rem * $marginControlFactor));
				background: $color_silver_approx;

			}
		}
		&:not(.p-disabled):hover .p-inputswitch-slider {
			background: $color_denim_approx;
		}
	}
	&.p-focus .p-inputswitch-slider {
		outline: 0 none;
		outline-offset: 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
	}
	&:not(.p-disabled):hover .p-inputswitch-slider {
		background: $color_nobel_approx;
	}
}
.p-inputtext {
	font-family: $font_0;
	font-size: $fontSizeDefault;
	color: $color_mine_shaft_approx;
	background: $white;
	padding: (0.429rem *($paddingControlFactor)) (0.429rem *($paddingControlFactor));
	border: (0.063rem * ($borderFactor)) solid $color_silver_chalice_approx;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	appearance: none;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&:enabled {
		&:hover {
			border-color: $color_mine_shaft_approx;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			border-color: $color_lochmara_approx;
		}
	}
	&.p-inputtext-sm {
		font-size: ($fontSizeSmaller);
		padding: (0.375375rem * $paddingControlFactor) (0.375375rem * $paddingControlFactor);
	}
	&.p-inputtext-lg {
		font-size: $fontSizeBigger;
		padding: (0.53625rem * $paddingControlFactor)  ( 0.53625rem * $paddingControlFactor);
	}
	&.ng-dirty.ng-invalid {
		border-color: $color_bright_red_approx;
	}
}
.p-float-label {
	> label {
		left: (0.429rem * $marginControlFactor);
		color: $color_dove_gray_approx;
		//Instead of the line below you could use @include transition-duration($duration-1, $duration-2, $duration-3, $duration-4, $duration-5, $duration-6, $duration-7, $duration-8, $duration-9, $duration-10)
		transition-duration: 0.2s;
	}
	> .ng-invalid.ng-dirty + label {
		color: $color_bright_red_approx;
	}
}
.p-input-icon-left {
	> i:first-of-type {
		left: (0.429rem * $marginControlFactor);
		color: $color_gray_approx;
	}
	> .p-inputtext {
		padding-left: (1.858rem * $paddingControlFactor);
	}
	&.p-float-label > label {
		left: (1.858rem * $paddingControlFactor);
	}
}
.p-input-icon-right {
	> i:last-of-type {
		right: (0.429rem *($paddingRightFactor));
		color: $color_gray_approx;
	}
	> .p-inputtext {
		padding-right: (1.858rem * $paddingControlFactor);
	}
}
::-webkit-input-placeholder {
	color: $color_dove_gray_approx;
}
:-moz-placeholder {
	color: $color_dove_gray_approx;
}
::-moz-placeholder {
	color: $color_dove_gray_approx;
}
:-ms-input-placeholder {
	color: $color_dove_gray_approx;
}
.p-listbox {
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_chalice_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-listbox-header {
		padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
		border-bottom: (0.063rem * ($borderFactor)) solid $color_mercury_approx;
		color: $color_mine_shaft_approx;
		background: $white;
		margin: 0;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-listbox-filter {
			padding-right: (1.429rem * ($paddingControlFactor));
		}
		.p-listbox-filter-icon {
			right: (0.429rem *($paddingRightFactor));
			color: $color_gray_approx;
		}
		.p-checkbox {
			margin-right: (0.5rem * ($marginControlFactor));
		}
	}
	.p-listbox-list {
		padding: 0;
		.p-listbox-item {
			margin: 0;
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			border: 0 none;
			color: $color_mine_shaft_approx;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			&.p-highlight {
				color: $white;
				background: $color_lochmara_approx;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
			}
			.p-checkbox {
				margin-right: (0.5rem * ($marginControlFactor));
			}
		}
		.p-listbox-item-group {
			margin: 0;
			padding: (0.857rem * ($paddingControlFactor));
			color: $color_mine_shaft_approx;
			background: $wild_sand;
			font-weight: 700;
		}
	}
	&:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
		color: $color_mine_shaft_approx;
		background: $color_mercury_approx;
	}
}
.p-multiselect {
	background: $white;
	border: (0.063rem * ($borderFactor)) solid $color_silver_chalice_approx;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-multiselect-label {
		padding: (0.429rem *($paddingControlFactor)) (0.429rem *($paddingControlFactor));
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		&.p-placeholder {
			color: $color_dove_gray_approx;
		}
	}
	.p-multiselect-trigger {
		background: transparent;
		color: $color_gray_approx;
		width: (2.357rem *($widthFactor) );
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
	}
	&:not(.p-disabled) {
		&:hover {
			border-color: $color_mine_shaft_approx;
		}
		&.p-focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			border-color: $color_lochmara_approx;
		}
	}
	&.p-multiselect-chip .p-multiselect-token {
		padding: (0.2145rem * ($paddingControlFactor)) (0.429rem *($paddingControlFactor));
		margin-right: (0.5rem * ($marginControlFactor));
		background: $color_lochmara_approx;
		color: $white;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
		.p-multiselect-token-icon {
			margin-left: (0.5rem * ($marginControlFactor));
		}
	}
}
.p-multiselect-panel {
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0.188rem 0.375rem 0 $black_16;
	.p-multiselect-header {
		padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
		border-bottom: (0.063rem * ($borderFactor)) solid $color_mercury_approx;
		color: $color_mine_shaft_approx;
		background: $white;
		margin: 0;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-multiselect-filter-container {
			.p-inputtext {
				padding-right: (1.429rem * ($paddingControlFactor));
			}
			.p-multiselect-filter-icon {
				right: (0.429rem *($paddingRightFactor));
				color: $color_gray_approx;
			}
		}
		.p-checkbox {
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-multiselect-close {
			margin-left: (0.5rem * ($marginControlFactor));
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			color: $color_silver_chalice_approx;
			border: 0 none;
			background: transparent;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
			&:enabled:hover {
				color: $color_lochmara_approx;
				border-color: transparent;
				background: transparent;
			}
		}
	}
	.p-multiselect-items {
		padding: 0;
		.p-multiselect-item {
			margin: 0;
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			border: 0 none;
			color: $color_mine_shaft_approx;
			background: transparent;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			&.p-highlight {
				color: $white;
				background: $color_lochmara_approx;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
			}
			.p-checkbox {
				margin-right: (0.5rem * ($marginControlFactor));
			}
			&:not(.p-highlight):not(.p-disabled):hover {
				color: $color_mine_shaft_approx;
				background: $color_mercury_approx;
			}
		}
		.p-multiselect-item-group {
			margin: 0;
			padding: (0.857rem * ($paddingControlFactor));
			color: $color_mine_shaft_approx;
			background: $wild_sand;
			font-weight: 700;
		}
		.p-multiselect-empty-message {
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			color: $color_mine_shaft_approx;
			background: transparent;
		}
	}
}

p-multiselect.p-multiselect-clearable .p-multiselect-label-container {
  padding-right: (1.429rem * ($paddingControlFactor));
}

p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon {
  color: $color_mine_shaft_approx;
  right: (2.357rem *($paddingRightFactor));
}



.p-password-panel {
	padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0.188rem 0.375rem 0 $black_16;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-password-meter {
		margin-bottom: (0.5rem *($marginControlFactor) );
	}
}
.p-radiobutton {
	width: (1.25rem * $widthFactor);
	height: (1.25rem * $heightFactor);
	.p-radiobutton-box {
		border: (0.063rem * ($borderFactor)) solid $color_silver_chalice_approx;
		background: $white;
		width: (1.25rem * $borderFactor);
		height: (1.25rem *$borderFactor);
		color: $color_mine_shaft_approx;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50%;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		.p-radiobutton-icon {
			width: (0.75 * $widthFactor);
			height: (0.75 * $heightFactor);
			//Instead of the line below you could use @include transition-duration($duration-1, $duration-2, $duration-3, $duration-4, $duration-5, $duration-6, $duration-7, $duration-8, $duration-9, $duration-10)
			transition-duration: 0.2s;
			background-color: $white;
		}
		&.p-highlight {
			border-color: $color_lochmara_approx;
			background: $color_lochmara_approx;
			&:not(.p-disabled):hover {
				border-color: $color_endeavour_approx;
				background: $color_endeavour_approx;
				color: $white;
			}
		}
		&:not(.p-disabled) {
			&.p-focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
				border-color: $color_lochmara_approx;
			}
			&:not(.p-highlight):hover {
				border-color: $color_mine_shaft_approx;
			}
		}
	}
}
.p-radiobutton-label {
	margin-left: (0.5rem * ($marginControlFactor));
}
.p-rating {
	.p-rating-icon {
		color: $color_mine_shaft_approx;
		margin-left: (0.5rem * ($marginControlFactor));
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		font-size: 1.143rem;
		&.p-rating-cancel {
			color: $color_hollywood_cerise_approx;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
		}
		&:first-child {
			margin-left: 0;
		}
		&.pi-star {
			color: $color_lochmara_approx;
		}
	}
	&:not(.p-disabled):not(.p-readonly) .p-rating-icon {
		&:hover {
			color: $color_lochmara_approx;
		}
		&.p-rating-cancel:hover {
			color: $color_violet_eggplant_approx;
		}
	}
}
.p-slider {
	background: $color_silver_approx;
	border: 0 none;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&.p-slider-horizontal {
		height: (0.286rem * $heightFactor);
		.p-slider-handle {
			margin-top: -0.5715rem;
			margin-left: -0.5715rem;
		}
	}
	&.p-slider-vertical {
		width: (0.286rem * $widthFactor);
		.p-slider-handle {
			margin-left: -0.5715rem;
			margin-bottom: -0.5715rem;
		}
	}
	.p-slider-handle {
		height: (1.143rem * $heightFactor);
		width: (1rem * $widthFactor);
		background: $white;
		border: 00.125rem solid $color_dove_gray_approx;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 100%;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
		}
	}
	.p-slider-range {
		background: $color_lochmara_approx;
	}
	&.p-slider-animate {
		&.p-slider-horizontal {
			.p-slider-handle {
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
			}
			.p-slider-range {
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: width 0.2s;
			}
		}
		&.p-slider-vertical {
			.p-slider-handle {
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
			}
			.p-slider-range {
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: height 0.2s;
			}
		}
	}
	&:not(.p-disabled) .p-slider-handle:hover {
		background: 00.125rem solid $color_dove_gray_approx;
		border-color: $color_lochmara_approx;
	}
}
.p-button {
	color: $white;
	background: $color_lochmara_approx;
	border: (0.063rem * ($borderFactor)) solid $color_lochmara_approx;
	padding: (0.429rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	font-size: $fontSizeDefault;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&:enabled {
		&:hover {
			background: $color_denim_approx;
			color: $white;
			border-color: $color_denim_approx;
		}
		&:active {
			background: $color_endeavour_approx;
			color: $white;
			border-color: $color_endeavour_approx;
		}
	}
	&.p-button-outlined {
		background-color: transparent;
		color: $color_lochmara_approx;
		border: (0.063rem * ($borderFactor)) solid;
		&:enabled {
			&:hover {
				background: $color_lochmara_4_approx;
				color: $color_lochmara_approx;
				border: (0.063rem * ($borderFactor)) solid;
			}
			&:active {
				background: $color_lochmara_16_approx;
				color: $color_lochmara_approx;
				border: (0.063rem * ($borderFactor)) solid;
			}
		}
		&.p-button-plain {
			color: $color_gray_approx;
			border-color: $color_gray_approx;
			&:enabled {
				&:hover {
					background: $color_mercury_approx;
					color: $color_gray_approx;
				}
				&:active {
					background: $color_alto_approx;
					color: $color_gray_approx;
				}
			}
		}
	}
	&.p-button-text {
		background-color: transparent;
		color: $color_lochmara_approx;
		border-color: transparent;
		&:enabled {
			&:hover {
				background: $color_lochmara_4_approx;
				color: $color_lochmara_approx;
				border-color: transparent;
			}
			&:active {
				background: $color_lochmara_16_approx;
				color: $color_lochmara_approx;
				border-color: transparent;
			}
		}
		&.p-button-plain {
			color: $color_gray_approx;
			&:enabled {
				&:hover {
					background: $color_mercury_approx;
					color: $color_gray_approx;
				}
				&:active {
					background: $color_alto_approx;
					color: $color_gray_approx;
				}
			}
		}
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
	}
	.p-button-icon-left {
		margin-right: (0.5rem * ($marginControlFactor));
	}
	.p-button-icon-right {
		margin-left: (0.5rem * ($marginControlFactor));
	}
	.p-button-icon-bottom {
		margin-top: (0.5rem *($marginControlFactor) );
	}
	.p-button-icon-top {
		margin-bottom: (0.5rem *($marginControlFactor) );
	}
	.p-badge {
		margin-left: (0.5rem * ($marginControlFactor));
		min-width: (2rem * ($widthFactor));
		height: (2rem * ($heightFactor));
		line-height: (1rem * ($heightFactor));
		color: $color_lochmara_approx;
		background-color: $white;
	}
	&.p-button-raised {
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0.188rem (0.063rem * ($borderFactor)) -00.125rem $black_20, 0 00.125rem 00.125rem 0 $black_14, 0 (0.063rem * ($borderFactor)) 0.313rem 0 $black_12;
	}
	&.p-button-rounded {
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 2rem;
	}
	&.p-button-icon-only {
		width: (2.357rem *($widthFactor) );
		padding: (0.429rem * $paddingControlFactor) 0;
		.p-button-icon-left {
			margin: 0;
		}
		.p-button-icon-right {
			margin: 0;
		}
		&.p-button-rounded {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			height: (2.357rem * $heightFactor);
		}
	}
	&.p-button-sm {
		font-size: ($fontSizeSmaller);
		padding: (0.375375rem *$paddingControlFactor) (0.875rem *$paddingControlFactor);
		.p-button-icon {
			font-size: ($fontSizeSmaller);
		}
	}
	&.p-button-lg {
		font-size: ($fontSizeBigger);
		padding: (0.53625rem * $paddingControlFactor) (1.25rem * $paddingControlFactor);
		.p-button-icon {
			font-size: ($fontSizeBigger);
		}
	}
	&.p-button-secondary {
		color: $white;
		background: $color_smalt_blue_approx;
		border: (0.063rem * ($borderFactor)) solid $color_smalt_blue_approx;
		&:enabled {
			&:hover {
				background: $color_blue_bayoux_approx;
				color: $white;
				border-color: $color_blue_bayoux_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_loblolly_approx;
			}
			&:active {
				background: $color_fiord_approx;
				color: $white;
				border-color: $color_fiord_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_smalt_blue_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_smalt_blue_4_approx;
					color: $color_smalt_blue_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_smalt_blue_16_approx;
					color: $color_smalt_blue_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_smalt_blue_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_smalt_blue_4_approx;
					border-color: transparent;
					color: $color_smalt_blue_approx;
				}
				&:active {
					background: $color_smalt_blue_16_approx;
					border-color: transparent;
					color: $color_smalt_blue_approx;
				}
			}
		}
	}
	&.p-button-info {
		color: $white;
		background: $color_lochmara_approx;
		border: (0.063rem * ($borderFactor)) solid $color_lochmara_approx;
		&:enabled {
			&:hover {
				background: $color_denim_approx;
				color: $white;
				border-color: $color_denim_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
			&:active {
				background: $color_endeavour_approx;
				color: $white;
				border-color: $color_endeavour_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_lochmara_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_lochmara_4_approx;
					color: $color_lochmara_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_lochmara_16_approx;
					color: $color_lochmara_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_lochmara_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_lochmara_4_approx;
					border-color: transparent;
					color: $color_lochmara_approx;
				}
				&:active {
					background: $color_lochmara_16_approx;
					border-color: transparent;
					color: $color_lochmara_approx;
				}
			}
		}
	}
	&.p-button-success {
		color: $white;
		background: $color_apple_approx;
		border: (0.063rem * ($borderFactor)) solid $color_apple_approx;
		&:enabled {
			&:hover {
				background: $color_japanese_laurel_approx;
				color: $white;
				border-color: $color_japanese_laurel_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_celadon_approx;
			}
			&:active {
				background: $color_san_felix_approx;
				color: $white;
				border-color: $color_san_felix_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_apple_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_apple_4_approx;
					color: $color_apple_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_apple_16_approx;
					color: $color_apple_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_apple_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_apple_4_approx;
					border-color: transparent;
					color: $color_apple_approx;
				}
				&:active {
					background: $color_apple_16_approx;
					border-color: transparent;
					color: $color_apple_approx;
				}
			}
		}
	}
	&.p-button-warning {
		color: $color_mine_shaft_approx;
		background: $color_selective_yellow_approx;
		border: (0.063rem * ($borderFactor)) solid $color_selective_yellow_approx;
		&:enabled {
			&:hover {
				background: $color_gamboge_approx;
				color: $color_mine_shaft_approx;
				border-color: $color_gamboge_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_peach_approx;
			}
			&:active {
				background: $color_meteor_approx;
				color: $color_mine_shaft_approx;
				border-color: $color_meteor_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_selective_yellow_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_selective_yellow_4_approx;
					color: $color_selective_yellow_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_selective_yellow_16_approx;
					color: $color_selective_yellow_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_selective_yellow_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_selective_yellow_4_approx;
					border-color: transparent;
					color: $color_selective_yellow_approx;
				}
				&:active {
					background: $color_selective_yellow_16_approx;
					border-color: transparent;
					color: $color_selective_yellow_approx;
				}
			}
		}
	}
	&.p-button-help {
		color: $white;
		background: $color_violet_eggplant_approx;
		border: (0.063rem * ($borderFactor)) solid $color_violet_eggplant_approx;
		&:enabled {
			&:hover {
				background: $color_seance_approx;
				color: $white;
				border-color: $color_seance_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_light_wisteria_approx;
			}
			&:active {
				background: $color_seance_approx;
				color: $white;
				border-color: $color_seance_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_violet_eggplant_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_violet_eggplant_4_approx;
					color: $color_violet_eggplant_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_violet_eggplant_16_approx;
					color: $color_violet_eggplant_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_violet_eggplant_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_violet_eggplant_4_approx;
					border-color: transparent;
					color: $color_violet_eggplant_approx;
				}
				&:active {
					background: $color_violet_eggplant_16_approx;
					border-color: transparent;
					color: $color_violet_eggplant_approx;
				}
			}
		}
	}
	&.p-button-danger {
		color: $white;
		background: $color_crimson_approx;
		border: (0.063rem * ($borderFactor)) solid $color_crimson_approx;
		&:enabled {
			&:hover {
				background: $color_monza_approx;
				color: $white;
				border-color: $color_monza_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_sundown_approx;
			}
			&:active {
				background: $color_bright_red_approx;
				color: $white;
				border-color: $color_bright_red_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_crimson_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_crimson_4_approx;
					color: $color_crimson_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_crimson_16_approx;
					color: $color_crimson_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_crimson_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_crimson_4_approx;
					border-color: transparent;
					color: $color_crimson_approx;
				}
				&:active {
					background: $color_crimson_16_approx;
					border-color: transparent;
					color: $color_crimson_approx;
				}
			}
		}
	}
	&.p-button-link {
		color: $color_endeavour_approx;
		background: transparent;
		border: transparent;
		&:enabled {
			&:hover {
				background: transparent;
				color: $color_endeavour_approx;
				border-color: transparent;
				.p-button-label {
					text-decoration: underline;
				}
			}
			&:focus {
				background: transparent;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
				border-color: transparent;
			}
			&:active {
				background: transparent;
				color: $color_endeavour_approx;
				border-color: transparent;
			}
		}
	}
}
.p-buttonset {
	&.p-button-secondary > .p-button {
		color: $white;
		background: $color_smalt_blue_approx;
		border: (0.063rem * ($borderFactor)) solid $color_smalt_blue_approx;
		&:enabled {
			&:hover {
				background: $color_blue_bayoux_approx;
				color: $white;
				border-color: $color_blue_bayoux_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_loblolly_approx;
			}
			&:active {
				background: $color_fiord_approx;
				color: $white;
				border-color: $color_fiord_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_smalt_blue_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_smalt_blue_4_approx;
					color: $color_smalt_blue_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_smalt_blue_16_approx;
					color: $color_smalt_blue_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_smalt_blue_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_smalt_blue_4_approx;
					border-color: transparent;
					color: $color_smalt_blue_approx;
				}
				&:active {
					background: $color_smalt_blue_16_approx;
					border-color: transparent;
					color: $color_smalt_blue_approx;
				}
			}
		}
	}
	&.p-button-info > .p-button {
		color: $white;
		background: $color_lochmara_approx;
		border: (0.063rem * ($borderFactor)) solid $color_lochmara_approx;
		&:enabled {
			&:hover {
				background: $color_denim_approx;
				color: $white;
				border-color: $color_denim_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
			&:active {
				background: $color_endeavour_approx;
				color: $white;
				border-color: $color_endeavour_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_lochmara_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_lochmara_4_approx;
					color: $color_lochmara_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_lochmara_16_approx;
					color: $color_lochmara_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_lochmara_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_lochmara_4_approx;
					border-color: transparent;
					color: $color_lochmara_approx;
				}
				&:active {
					background: $color_lochmara_16_approx;
					border-color: transparent;
					color: $color_lochmara_approx;
				}
			}
		}
	}
	&.p-button-success > .p-button {
		color: $white;
		background: $color_apple_approx;
		border: (0.063rem * ($borderFactor)) solid $color_apple_approx;
		&:enabled {
			&:hover {
				background: $color_japanese_laurel_approx;
				color: $white;
				border-color: $color_japanese_laurel_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_celadon_approx;
			}
			&:active {
				background: $color_san_felix_approx;
				color: $white;
				border-color: $color_san_felix_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_apple_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_apple_4_approx;
					color: $color_apple_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_apple_16_approx;
					color: $color_apple_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_apple_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_apple_4_approx;
					border-color: transparent;
					color: $color_apple_approx;
				}
				&:active {
					background: $color_apple_16_approx;
					border-color: transparent;
					color: $color_apple_approx;
				}
			}
		}
	}
	&.p-button-warning > .p-button {
		color: $color_mine_shaft_approx;
		background: $color_selective_yellow_approx;
		border: (0.063rem * ($borderFactor)) solid $color_selective_yellow_approx;
		&:enabled {
			&:hover {
				background: $color_gamboge_approx;
				color: $color_mine_shaft_approx;
				border-color: $color_gamboge_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_peach_approx;
			}
			&:active {
				background: $color_meteor_approx;
				color: $color_mine_shaft_approx;
				border-color: $color_meteor_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_selective_yellow_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_selective_yellow_4_approx;
					color: $color_selective_yellow_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_selective_yellow_16_approx;
					color: $color_selective_yellow_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_selective_yellow_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_selective_yellow_4_approx;
					border-color: transparent;
					color: $color_selective_yellow_approx;
				}
				&:active {
					background: $color_selective_yellow_16_approx;
					border-color: transparent;
					color: $color_selective_yellow_approx;
				}
			}
		}
	}
	&.p-button-help > .p-button {
		color: $white;
		background: $color_violet_eggplant_approx;
		border: (0.063rem * ($borderFactor)) solid $color_violet_eggplant_approx;
		&:enabled {
			&:hover {
				background: $color_seance_approx;
				color: $white;
				border-color: $color_seance_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_light_wisteria_approx;
			}
			&:active {
				background: $color_seance_approx;
				color: $white;
				border-color: $color_seance_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_violet_eggplant_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_violet_eggplant_4_approx;
					color: $color_violet_eggplant_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_violet_eggplant_16_approx;
					color: $color_violet_eggplant_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_violet_eggplant_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_violet_eggplant_4_approx;
					border-color: transparent;
					color: $color_violet_eggplant_approx;
				}
				&:active {
					background: $color_violet_eggplant_16_approx;
					border-color: transparent;
					color: $color_violet_eggplant_approx;
				}
			}
		}
	}
	&.p-button-danger > .p-button {
		color: $white;
		background: $color_crimson_approx;
		border: (0.063rem * ($borderFactor)) solid $color_crimson_approx;
		&:enabled {
			&:hover {
				background: $color_monza_approx;
				color: $white;
				border-color: $color_monza_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_sundown_approx;
			}
			&:active {
				background: $color_bright_red_approx;
				color: $white;
				border-color: $color_bright_red_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_crimson_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_crimson_4_approx;
					color: $color_crimson_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_crimson_16_approx;
					color: $color_crimson_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_crimson_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_crimson_4_approx;
					border-color: transparent;
					color: $color_crimson_approx;
				}
				&:active {
					background: $color_crimson_16_approx;
					border-color: transparent;
					color: $color_crimson_approx;
				}
			}
		}
	}
}
.p-splitbutton {
	&.p-button-secondary > .p-button {
		color: $white;
		background: $color_smalt_blue_approx;
		border: (0.063rem * ($borderFactor)) solid $color_smalt_blue_approx;
		&:enabled {
			&:hover {
				background: $color_blue_bayoux_approx;
				color: $white;
				border-color: $color_blue_bayoux_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_loblolly_approx;
			}
			&:active {
				background: $color_fiord_approx;
				color: $white;
				border-color: $color_fiord_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_smalt_blue_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_smalt_blue_4_approx;
					color: $color_smalt_blue_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_smalt_blue_16_approx;
					color: $color_smalt_blue_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_smalt_blue_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_smalt_blue_4_approx;
					border-color: transparent;
					color: $color_smalt_blue_approx;
				}
				&:active {
					background: $color_smalt_blue_16_approx;
					border-color: transparent;
					color: $color_smalt_blue_approx;
				}
			}
		}
	}
	&.p-button-info > .p-button {
		color: $white;
		background: $color_lochmara_approx;
		border: (0.063rem * ($borderFactor)) solid $color_lochmara_approx;
		&:enabled {
			&:hover {
				background: $color_denim_approx;
				color: $white;
				border-color: $color_denim_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
			&:active {
				background: $color_endeavour_approx;
				color: $white;
				border-color: $color_endeavour_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_lochmara_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_lochmara_4_approx;
					color: $color_lochmara_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_lochmara_16_approx;
					color: $color_lochmara_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_lochmara_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_lochmara_4_approx;
					border-color: transparent;
					color: $color_lochmara_approx;
				}
				&:active {
					background: $color_lochmara_16_approx;
					border-color: transparent;
					color: $color_lochmara_approx;
				}
			}
		}
	}
	&.p-button-success > .p-button {
		color: $white;
		background: $color_apple_approx;
		border: (0.063rem * ($borderFactor)) solid $color_apple_approx;
		&:enabled {
			&:hover {
				background: $color_japanese_laurel_approx;
				color: $white;
				border-color: $color_japanese_laurel_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_celadon_approx;
			}
			&:active {
				background: $color_san_felix_approx;
				color: $white;
				border-color: $color_san_felix_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_apple_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_apple_4_approx;
					color: $color_apple_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_apple_16_approx;
					color: $color_apple_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_apple_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_apple_4_approx;
					border-color: transparent;
					color: $color_apple_approx;
				}
				&:active {
					background: $color_apple_16_approx;
					border-color: transparent;
					color: $color_apple_approx;
				}
			}
		}
	}
	&.p-button-warning > .p-button {
		color: $color_mine_shaft_approx;
		background: $color_selective_yellow_approx;
		border: (0.063rem * ($borderFactor)) solid $color_selective_yellow_approx;
		&:enabled {
			&:hover {
				background: $color_gamboge_approx;
				color: $color_mine_shaft_approx;
				border-color: $color_gamboge_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_peach_approx;
			}
			&:active {
				background: $color_meteor_approx;
				color: $color_mine_shaft_approx;
				border-color: $color_meteor_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_selective_yellow_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_selective_yellow_4_approx;
					color: $color_selective_yellow_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_selective_yellow_16_approx;
					color: $color_selective_yellow_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_selective_yellow_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_selective_yellow_4_approx;
					border-color: transparent;
					color: $color_selective_yellow_approx;
				}
				&:active {
					background: $color_selective_yellow_16_approx;
					border-color: transparent;
					color: $color_selective_yellow_approx;
				}
			}
		}
	}
	&.p-button-help > .p-button {
		color: $white;
		background: $color_violet_eggplant_approx;
		border: (0.063rem * ($borderFactor)) solid $color_violet_eggplant_approx;
		&:enabled {
			&:hover {
				background: $color_seance_approx;
				color: $white;
				border-color: $color_seance_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_light_wisteria_approx;
			}
			&:active {
				background: $color_seance_approx;
				color: $white;
				border-color: $color_seance_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_violet_eggplant_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_violet_eggplant_4_approx;
					color: $color_violet_eggplant_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_violet_eggplant_16_approx;
					color: $color_violet_eggplant_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_violet_eggplant_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_violet_eggplant_4_approx;
					border-color: transparent;
					color: $color_violet_eggplant_approx;
				}
				&:active {
					background: $color_violet_eggplant_16_approx;
					border-color: transparent;
					color: $color_violet_eggplant_approx;
				}
			}
		}
	}
	&.p-button-danger > .p-button {
		color: $white;
		background: $color_crimson_approx;
		border: (0.063rem * ($borderFactor)) solid $color_crimson_approx;
		&:enabled {
			&:hover {
				background: $color_monza_approx;
				color: $white;
				border-color: $color_monza_approx;
			}
			&:focus {
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_sundown_approx;
			}
			&:active {
				background: $color_bright_red_approx;
				color: $white;
				border-color: $color_bright_red_approx;
			}
		}
		&.p-button-outlined {
			background-color: transparent;
			color: $color_crimson_approx;
			border: (0.063rem * ($borderFactor)) solid;
			&:enabled {
				&:hover {
					background: $color_crimson_4_approx;
					color: $color_crimson_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
				&:active {
					background: $color_crimson_16_approx;
					color: $color_crimson_approx;
					border: (0.063rem * ($borderFactor)) solid;
				}
			}
		}
		&.p-button-text {
			background-color: transparent;
			color: $color_crimson_approx;
			border-color: transparent;
			&:enabled {
				&:hover {
					background: $color_crimson_4_approx;
					border-color: transparent;
					color: $color_crimson_approx;
				}
				&:active {
					background: $color_crimson_16_approx;
					border-color: transparent;
					color: $color_crimson_approx;
				}
			}
		}
	}
}
.p-carousel {
	.p-carousel-content {
		.p-carousel-prev {
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			color: $color_silver_chalice_approx;
			border: 0 none;
			background: transparent;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			margin: (0.5rem * ($marginControlFactor));
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
			&:enabled:hover {
				color: $color_lochmara_approx;
				border-color: transparent;
				background: transparent;
			}
		}
		.p-carousel-next {
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			color: $color_silver_chalice_approx;
			border: 0 none;
			background: transparent;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			margin: (0.5rem * ($marginControlFactor));
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
			&:enabled:hover {
				color: $color_lochmara_approx;
				border-color: transparent;
				background: transparent;
			}
		}
	}
	.p-carousel-indicators {
		padding: (1rem *($paddingControlFactor) );
		.p-carousel-indicator {
			margin-right: (0.5rem * ($marginControlFactor));
			margin-bottom: (0.5rem *($marginControlFactor) );
			button {
				background-color: $wild_sand;
				width: (2rem * ($widthFactor));
				height: (0.5rem * $heightFactor);
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 0;
				&:hover {
					background: $color_mercury_approx;
				}
			}
			&.p-highlight button {
				background: $color_lochmara_approx;
				color: $white;
			}
		}
	}
}
.p-datatable {
	.p-paginator-top {
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
	}
	.p-paginator-bottom {
		border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
	}
	.p-datatable-header {
		background: $wild_sand;
		color: $color_mine_shaft_approx;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		font-weight: 700;
	}
	.p-datatable-footer {
		background: $white;
		color: $color_mine_shaft_approx;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		font-weight: normal;
	}
	.p-sortable-column {
		.p-sortable-column-icon {
			color: $color_gray_approx;
			margin-left: (0.5rem * ($marginControlFactor));
		}
		.p-sortable-column-badge {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			height: (1.143rem * ($borderFactor));
			min-width: (1.143rem * ($borderFactor));
			line-height: (1.143rem * ($heightFactor));
			color: $white;
			background: $color_lochmara_approx;
			margin-left: (0.5rem * ($marginControlFactor));
		}
		&.p-highlight {
			background: $color_lochmara_approx;
			color: $white;
			.p-sortable-column-icon {
				color: $white;
			}
			&:hover {
				background: $color_lochmara_approx;
				color: $white;
				.p-sortable-column-icon {
					color: $white;
				}
			}
		}
		&:focus {
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
			outline: 0 none;
		}
		&:not(.p-highlight):hover {
			background: $color_mercury_approx;
			color: $color_mine_shaft_approx;
			.p-sortable-column-icon {
				color: $color_mine_shaft_approx;
			}
		}
	}
	.p-column-resizer-helper {
		background: $color_lochmara_approx;
	}
	.p-datatable-scrollable-header {
		background: $wild_sand;
	}
	.p-datatable-scrollable-footer {
		background: $wild_sand;
	}
	.p-datatable-loading-icon {
		font-size: 2rem;
	}
	&.p-datatable-gridlines {
		.p-datatable-header {
			border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		}
		.p-datatable-footer {
			border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		}
		.p-paginator-top {
			border-width: 0 (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		}
		.p-paginator-bottom {
			border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		}
		.p-datatable-thead > tr > th {
			border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		}
		.p-datatable-tbody > tr > td {
			border-width: (0.063rem * ($borderFactor));
		}
		.p-datatable-tfoot > tr > td {
			border-width: (0.063rem * ($borderFactor));
		}
	}
	&.p-datatable-sm {
		.p-datatable-header {
			padding: (0.72845rem *($paddingControlFactor) ) (0.85rem *($paddingControlFactor) );
		}
		.p-datatable-footer {
			padding: (0.48535rem *($paddingControlFactor) ) (0.85rem *($paddingControlFactor) );
		}
		.p-datatable-thead > tr > th {
			padding: (0.48535rem *($paddingControlFactor) ) (0.72845rem *($paddingControlFactor) );
		}
		.p-datatable-tbody > tr > td {
			padding: (0.48535rem *($paddingControlFactor) ) (0.72845rem *($paddingControlFactor) );
		}
		.p-datatable-tfoot > tr > td {
			padding: (0.48535rem *($paddingControlFactor) ) (0.85rem *($paddingControlFactor) );
		}
	}
  &.p-datatable-xs {
    .p-datatable-header {
      padding: (0.35rem *($paddingControlFactor) ) (0.4rem *($paddingControlFactor) );
    }
    .p-datatable-footer {
      padding: (0.24rem *($paddingControlFactor) ) (0.4rem *($paddingControlFactor) );
    }
    .p-datatable-thead > tr > th {
      padding: (0.24rem *($paddingControlFactor) ) (0.35rem *($paddingControlFactor) );
    }
    .p-datatable-tbody > tr > td {
      padding: (0.24rem *($paddingControlFactor) ) (0.35rem *($paddingControlFactor) );
    }
    .p-datatable-tfoot > tr > td {
      padding: (0.24rem *($paddingControlFactor) ) (0.4rem *($paddingControlFactor) );
    }
  }
	&.p-datatable-lg {
		.p-datatable-header {
			padding: (1.07125rem *($paddingControlFactor) ) (1.25rem *($paddingControlFactor) );
		}
		.p-datatable-footer {
			padding: (0.71375rem *($paddingControlFactor) ) (1.25rem *($paddingControlFactor) );
		}
		.p-datatable-thead > tr > th {
			padding: (0.71375rem *($paddingControlFactor) ) (1.07125rem *($paddingControlFactor) );
		}
		.p-datatable-tbody > tr > td {
			padding: (0.71375rem *($paddingControlFactor) ) (1.07125rem *($paddingControlFactor) );
		}
		.p-datatable-tfoot > tr > td {
			padding: (0.71375rem *($paddingControlFactor) ) (1.25rem *($paddingControlFactor) );
		}
	}
	.p-datatable-thead > tr > th {
		text-align: left;
		padding: (0.571rem *($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		font-weight: 700;
		color: $color_mine_shaft_approx;
		background: $wild_sand;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
	}
	.p-datatable-tfoot > tr > td {
		text-align: left;
		padding: (0.571rem *($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		border-width: (0.063rem * ($borderFactor));
		font-weight: 700;
		color: $color_mine_shaft_approx;
		background: $wild_sand;
	}
	.p-datatable-tbody > tr {
		background: $white;
		color: $color_mine_shaft_approx;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		outline-color: $color_malibu_approx1;
		> td {
			text-align: left;
			border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
			border-width: (0.063rem * ($borderFactor));
			padding: (0.571rem *($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
			.p-row-toggler {
				width: (2rem * ($borderFactor));
				height: (2rem * ($borderFactor));
				color: $color_silver_chalice_approx;
				border: 0 none;
				background: transparent;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 50%;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
				}
				&:enabled:hover {
					color: $color_lochmara_approx;
					border-color: transparent;
					background: transparent;
				}
			}
			.p-row-editor-init {
				width: (2rem * ($borderFactor));
				height: (2rem * ($borderFactor));
				color: $color_silver_chalice_approx;
				border: 0 none;
				background: transparent;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 50%;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
				}
				&:enabled:hover {
					color: $color_lochmara_approx;
					border-color: transparent;
					background: transparent;
				}
			}
			.p-row-editor-save {
				width: (2rem * ($borderFactor));
				height: (2rem * ($borderFactor));
				color: $color_silver_chalice_approx;
				border: 0 none;
				background: transparent;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 50%;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				margin-right: (0.5rem * ($marginControlFactor));
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
				}
				&:enabled:hover {
					color: $color_lochmara_approx;
					border-color: transparent;
					background: transparent;
				}
			}
			.p-row-editor-cancel {
				width: (2rem * ($borderFactor));
				height: (2rem * ($borderFactor));
				color: $color_silver_chalice_approx;
				border: 0 none;
				background: transparent;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 50%;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
				}
				&:enabled:hover {
					color: $color_lochmara_approx;
					border-color: transparent;
					background: transparent;
				}
			}
		}
		&.p-highlight {
			background: $color_lochmara_approx;
			color: $white;
		}
		&.p-datatable-dragpoint-top > td {
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 00.125rem 0 0 $color_lochmara_approx;
		}
		&.p-datatable-dragpoint-bottom > td {
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 -00.125rem 0 0 $color_lochmara_approx;
		}
	}
	&.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
		background: $color_mercury_approx;
		color: $color_mine_shaft_approx;
	}
	&.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
		background: $color_alabaster_approx;
		&.p-highlight {
			background: $color_lochmara_approx;
			color: $white;
			.p-row-toggler {
				color: $white;
				&:hover {
					color: $white;
				}
			}
		}
	}
}
.p-dataview {
	.p-paginator-top {
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
	}
	.p-paginator-bottom {
		border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
	}
	.p-dataview-header {
		background: $wild_sand;
		color: $color_mine_shaft_approx;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		font-weight: 700;
	}
	.p-dataview-content {
		background: $white;
		color: $color_mine_shaft_approx;
		border: 0 none;
		padding: 0;
	}
	.p-dataview-footer {
		background: $white;
		color: $color_mine_shaft_approx;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		font-weight: normal;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
	}
	.p-dataview-loading-icon {
		font-size: 2rem;
	}
	.p-dataview-emptymessage {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	}
	&.p-dataview-list .p-dataview-content > .grid > div {
		border: (0.063rem * ($borderFactor)) solid $color_mischka_approx;
		border-width: (0.063rem * ($borderFactor));
	}
}
.p-column-filter-row {
	.p-column-filter-menu-button {
		margin-left: (0.5rem * ($marginControlFactor));
	}
	.p-column-filter-clear-button {
		margin-left: (0.5rem * ($marginControlFactor));
	}
}
.p-column-filter-menu-button {
	width: (2rem * ($borderFactor));
	height: (2rem * ($borderFactor));
	color: $color_silver_chalice_approx;
	border: 0 none;
	background: transparent;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 50%;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	&:hover {
		color: $color_lochmara_approx;
		border-color: transparent;
		background: transparent;
	}
	&.p-column-filter-menu-button-open {
		background: transparent;
		color: $color_lochmara_approx;
		&:hover {
			background: transparent;
			color: $color_lochmara_approx;
		}
	}
	&.p-column-filter-menu-button-active {
		background: $color_lochmara_approx;
		color: $white;
		&:hover {
			background: $color_lochmara_approx;
			color: $white;
		}
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
	}
}
.p-column-filter-clear-button {
	width: (2rem * ($borderFactor));
	height: (2rem * ($borderFactor));
	color: $color_silver_chalice_approx;
	border: 0 none;
	background: transparent;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 50%;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	&:hover {
		color: $color_lochmara_approx;
		border-color: transparent;
		background: transparent;
	}
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
	}
}
.p-column-filter-overlay {
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0.188rem 0.375rem 0 $black_16;
	min-width: (12.5rem *($widthFactor) );
	.p-column-filter-row-items {
		padding: 0;
		.p-column-filter-row-item {
			margin: 0;
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			border: 0 none;
			color: $color_mine_shaft_approx;
			background: transparent;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			&.p-highlight {
				color: $white;
				background: $color_lochmara_approx;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
			}
			&:not(.p-highlight):not(.p-disabled):hover {
				color: $color_mine_shaft_approx;
				background: $color_mercury_approx;
			}
		}
		.p-column-filter-separator {
			border-top: (0.063rem * ($borderFactor)) solid $color_mischka_approx;
			margin: 0;
		}
	}
}
.p-column-filter-overlay-menu {
	.p-column-filter-operator {
		padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
		border-bottom: (0.063rem * ($borderFactor)) solid $color_mercury_approx;
		color: $color_mine_shaft_approx;
		background: $white;
		margin: 0;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
	}
	.p-column-filter-constraint {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border-bottom: (0.063rem * ($borderFactor)) solid $color_mischka_approx;
		.p-column-filter-matchmode-dropdown {
			margin-bottom: (0.5rem *($marginControlFactor) );
		}
		.p-column-filter-remove-button {
			margin-top: (0.5rem *($marginControlFactor) );
		}
		&:last-child {
			border-bottom: 0 none;
		}
	}
	.p-column-filter-add-rule {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	}
	.p-column-filter-buttonbar {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	}
}
.fc {
	.fc-view-container {
		th {
			background: $wild_sand;
			border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
			color: $color_mine_shaft_approx;
		}
		td {
			&.fc-widget-content {
				background: $white;
				border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
				color: $color_mine_shaft_approx;
			}
			&.fc-head-container {
				border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
			}
		}
		.fc-row {
			border-right: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		}
		.fc-event {
			background: $color_denim_approx;
			border: (0.063rem * ($borderFactor)) solid $color_denim_approx;
			color: $white;
		}
		.fc-divider {
			background: $wild_sand;
			border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		}
	}
	.fc-toolbar {
		.fc-button {
			color: $white;
			background: $color_lochmara_approx;
			border: (0.063rem * ($borderFactor)) solid $color_lochmara_approx;
			font-size: $fontSizeDefault;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			display: flex;
			align-items: center;
			&:enabled {
				&:hover {
					background: $color_denim_approx;
					color: $white;
					border-color: $color_denim_approx;
				}
				&:active {
					background: $color_endeavour_approx;
					color: $white;
					border-color: $color_endeavour_approx;
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
						box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
					}
				}
			}
			.fc-icon-chevron-left {
				font-family: $font_icons;
				text-indent: 0;
				font-size: $fontSizeDefault;
				&:before {
					content: "";
				}
			}
			.fc-icon-chevron-right {
				font-family: $font_icons;
				text-indent: 0;
				font-size: $fontSizeDefault;
				&:before {
					content: "";
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
			&.fc-dayGridMonth-button {
				background: $color_alto_approx;
				border: (0.063rem * ($borderFactor)) solid $color_alto_approx;
				color: $color_mine_shaft_approx;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
				&:hover {
					background: $color_silver_approx;
					border-color: $color_silver_approx;
					color: $color_mine_shaft_approx;
				}
				&.fc-button-active {
					background: $color_lochmara_approx;
					border-color: $color_lochmara_approx;
					color: $white;
					&:hover {
						background: $color_denim_approx;
						border-color: $color_denim_approx;
						color: $white;
					}
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
					z-index: 1;
				}
			}
			&.fc-timeGridWeek-button {
				background: $color_alto_approx;
				border: (0.063rem * ($borderFactor)) solid $color_alto_approx;
				color: $color_mine_shaft_approx;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
				&:hover {
					background: $color_silver_approx;
					border-color: $color_silver_approx;
					color: $color_mine_shaft_approx;
				}
				&.fc-button-active {
					background: $color_lochmara_approx;
					border-color: $color_lochmara_approx;
					color: $white;
					&:hover {
						background: $color_denim_approx;
						border-color: $color_denim_approx;
						color: $white;
					}
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
					z-index: 1;
				}
			}
			&.fc-timeGridDay-button {
				background: $color_alto_approx;
				border: (0.063rem * ($borderFactor)) solid $color_alto_approx;
				color: $color_mine_shaft_approx;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
				&:hover {
					background: $color_silver_approx;
					border-color: $color_silver_approx;
					color: $color_mine_shaft_approx;
				}
				&.fc-button-active {
					background: $color_lochmara_approx;
					border-color: $color_lochmara_approx;
					color: $white;
					&:hover {
						background: $color_denim_approx;
						border-color: $color_denim_approx;
						color: $white;
					}
				}
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
					z-index: 1;
				}
			}
		}
		.fc-button-group .fc-button {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			&:first-child {
				//Instead of the line below you could use @include border-top-left-radius($radius)
				border-top-left-radius: 0.188rem;
				//Instead of the line below you could use @include border-bottom-left-radius($radius)
				border-bottom-left-radius: 0.188rem;
			}
			&:last-child {
				//Instead of the line below you could use @include border-top-right-radius($radius)
				border-top-right-radius: 0.188rem;
				//Instead of the line below you could use @include border-bottom-right-radius($radius)
				border-bottom-right-radius: 0.188rem;
			}
		}
	}
}
.p-orderlist {
	.p-orderlist-controls {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		.p-button {
			margin-bottom: (0.5rem *($marginControlFactor) );
		}
	}
	.p-orderlist-header {
		background: $wild_sand;
		color: $color_mine_shaft_approx;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border-bottom: 0 none;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-orderlist-title {
			font-weight: 700;
		}
	}
	.p-orderlist-filter-container {
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		background: $white;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		border-bottom: 0 none;
		.p-orderlist-filter-input {
			padding-right: (1.429rem * ($paddingControlFactor));
		}
		.p-orderlist-filter-icon {
			right: (0.429rem *($paddingRightFactor));
			color: $color_gray_approx;
		}
	}
	.p-orderlist-list {
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		background: $white;
		color: $color_mine_shaft_approx;
		padding: 0;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		.p-orderlist-item {
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			margin: 0;
			border: 0 none;
			color: $color_mine_shaft_approx;
			background: transparent;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: transform 0.2s, background-color 0.2s, box-shadow 0.2s;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
			}
			&.p-highlight {
				color: $white;
				background: $color_lochmara_approx;
			}
			&:not(.p-highlight):hover {
				background: $color_mercury_approx;
				color: $color_mine_shaft_approx;
			}
		}
		.p-orderlist-droppoint.p-orderlist-droppoint-highlight {
			background-color: $color_endeavour_approx;
		}
	}
}
@media screen and (max-width: 960px) {
	.p-orderlist {
		flex-direction: column;
		.p-orderlist-controls {
			padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
			flex-direction: row;
			.p-button {
				margin-right: (0.5rem * ($marginControlFactor));
				margin-bottom: 0;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	.p-picklist {
		flex-direction: column;
		.p-picklist-buttons {
			padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
			flex-direction: row;
			.p-button {
				margin-right: (0.5rem * ($marginControlFactor));
				margin-bottom: 0;
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.p-picklist-transfer-buttons {
			.pi-angle-right:before {
				content: "";
			}
			.pi-angle-double-right:before {
				content: "";
			}
			.pi-angle-left:before {
				content: "";
			}
			.pi-angle-double-left:before {
				content: "";
			}
		}
	}
	.p-menubar {
		position: relative;
		.p-menubar-button {
			display: flex;
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			color: $color_mine_shaft_approx;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:hover {
				color: $color_mine_shaft_approx;
				background: $color_mercury_approx;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
		}
		.p-menubar-root-list {
			position: absolute;
			display: none;
			padding: 0;
			background: $white;
			border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0.375rem 0 $black_16;
			width: 100%;
			.p-menu-separator {
				border-top: (0.063rem * ($borderFactor)) solid $color_mischka_approx;
				margin: 0;
			}
			.p-submenu-icon {
				font-size: ($fontSizeSmaller);
			}
			.p-submenu-list {
				width: 100%;
				position: static;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: none;
				border: 0 none;
				.p-submenu-icon {
					//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
					transition: transform 0.2s;
					//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
					transform: rotate(90deg);
				}
				.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
					//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
					transform: rotate(-90deg);
				}
			}
			.p-menuitem {
				width: 100%;
				position: static;
			}
			> .p-menuitem {
				width: 100%;
				position: static;
				> .p-menuitem-link {
					padding: (0.857rem * ($paddingControlFactor));
					color: $color_mine_shaft_approx;
					//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
					border-radius: 0;
					//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
					transition: background-color 0.2s, box-shadow 0.2s;
					//Instead of the line below you could use @include user-select($select)
					user-select: none;
					.p-menuitem-text {
						color: $color_mine_shaft_approx;
					}
					.p-menuitem-icon {
						color: $color_mine_shaft_approx;
						margin-right: (0.5rem * ($marginControlFactor));
					}
					.p-submenu-icon {
						color: $color_mine_shaft_approx;
					}
					&:focus {
						outline: 0 none;
						outline-offset: 0;
						//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
						box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
					}
					&:not(.p-disabled):hover {
						background: $color_mercury_approx;
						.p-menuitem-text {
							color: $color_mine_shaft_approx;
						}
						.p-menuitem-icon {
							color: $color_mine_shaft_approx;
						}
						.p-submenu-icon {
							color: $color_mine_shaft_approx;
						}
					}
					> .p-submenu-icon {
						margin-left: auto;
						//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
						transition: transform 0.2s;
					}
				}
				&.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
					//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
					transform: rotate(-180deg);
				}
			}
			ul li {
				a {
					padding-left: 2.571rem;
				}
				ul li {
					a {
						padding-left: 4.285rem;
					}
					ul li {
						a {
							padding-left: 5.999rem;
						}
						ul li {
							a {
								padding-left: 7.713rem;
							}
							ul li a {
								padding-left: 9.427rem;
							}
						}
					}
				}
			}
		}
		&.p-menubar-mobile-active .p-menubar-root-list {
			display: flex;
			flex-direction: column;
			top: 100%;
			left: 0;
			z-index: 1;
		}
	}
}
.p-organizationchart {
	.p-organizationchart-node-content {
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		background: $white;
		color: $color_mine_shaft_approx;
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		&.p-highlight {
			background: $color_lochmara_approx;
			color: $white;
			.p-node-toggler i {
				color: $color_prussian_blue_approx;
			}
		}
		.p-node-toggler {
			background: inherit;
			color: inherit;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
		}
		&.p-organizationchart-selectable-node:not(.p-highlight):hover {
			background: $color_mercury_approx;
			color: $color_mine_shaft_approx;
		}
	}
	.p-organizationchart-line-down {
		background: $color_silver_approx;
	}
	.p-organizationchart-line-left {
		border-right: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		border-color: $color_silver_approx;
	}
	.p-organizationchart-line-top {
		border-top: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		border-color: $color_silver_approx;
	}
}
.p-paginator {
	background: $wild_sand;
	color: $color_mine_shaft_approx;
	border: solid $color_silver_approx;
	border-width: (0.063rem * ($borderFactor));
	padding: 0;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-paginator-first {
		background-color: transparent;
		border: 0 none;
		color: $color_gray_approx;
		min-width: (2.357rem *($widthFactor));
		height: (2.357rem * $heightFactor);
		margin: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		&:not(.p-disabled):not(.p-highlight):hover {
			background: $color_mercury_approx;
			border-color: transparent;
			color: $color_mine_shaft_approx;
		}
	}
	.p-paginator-prev {
		background-color: transparent;
		border: 0 none;
		color: $color_gray_approx;
		min-width: (2.357rem *($widthFactor));
		height: (2.357rem *$heightFactor);
		margin: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		&:not(.p-disabled):not(.p-highlight):hover {
			background: $color_mercury_approx;
			border-color: transparent;
			color: $color_mine_shaft_approx;
		}
	}
	.p-paginator-next {
		background-color: transparent;
		border: 0 none;
		color: $color_gray_approx;
		min-width: (2.357rem *($widthFactor));
		height: (2.357rem * $heightFactor);
		margin: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		&:not(.p-disabled):not(.p-highlight):hover {
			background: $color_mercury_approx;
			border-color: transparent;
			color: $color_mine_shaft_approx;
		}
	}
	.p-paginator-last {
		background-color: transparent;
		border: 0 none;
		color: $color_gray_approx;
		min-width: (2.357rem *($widthFactor));
		height: (2.357rem *$heightFactor);
		margin: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		&:not(.p-disabled):not(.p-highlight):hover {
			background: $color_mercury_approx;
			border-color: transparent;
			color: $color_mine_shaft_approx;
		}
	}
	.p-dropdown {
		margin-left: (0.5rem * ($marginControlFactor));
		height: (2.357rem * $heightFactor);
		.p-dropdown-label {
      margin-top: auto;
      margin-bottom: auto;
			padding-right: 0;
		}
	}
	.p-paginator-current {
		background-color: transparent;
		border: 0 none;
		color: $color_gray_approx;
		min-width: (2.357rem *($widthFactor));
		height: (2.357rem * $heightFactor);
		margin: 0;
		padding: 0 (0.5rem *($paddingControlFactor) );
	}
	.p-paginator-pages .p-paginator-page {
		background-color: transparent;
		border: 0 none;
		color: $color_gray_approx;
		min-width: (2.357rem *($widthFactor));
		height: (2.357rem * $heightFactor);
		margin: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		&.p-highlight {
			background: $color_lochmara_approx;
			border-color: $color_lochmara_approx;
			color: $white;
		}
		&:not(.p-highlight):hover {
			background: $color_mercury_approx;
			border-color: transparent;
			color: $color_mine_shaft_approx;
		}
	}
}
.p-picklist {
	.p-picklist-buttons {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		.p-button {
			margin-bottom: (0.5rem *($marginControlFactor) );
		}
	}
	.p-picklist-header {
		background: $wild_sand;
		color: $color_mine_shaft_approx;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border-bottom: 0 none;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-picklist-title {
			font-weight: 700;
		}
	}
	.p-picklist-filter-container {
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		background: $white;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		border-bottom: 0 none;
		.p-picklist-filter-input {
			padding-right: (1.429rem * ($paddingControlFactor));
		}
		.p-picklist-filter-icon {
			right: (0.429rem *($paddingRightFactor));
			color: $color_gray_approx;
		}
	}
	.p-picklist-list {
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		background: $white;
		color: $color_mine_shaft_approx;
		padding: 0;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		.p-picklist-item {
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			margin: 0;
			border: 0 none;
			color: $color_mine_shaft_approx;
			background: transparent;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: transform 0.2s, background-color 0.2s, box-shadow 0.2s;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
			}
			&.p-highlight {
				color: $white;
				background: $color_lochmara_approx;
			}
			&:not(.p-highlight):hover {
				background: $color_mercury_approx;
				color: $color_mine_shaft_approx;
			}
		}
		.p-picklist-empty-message {
			padding: (0.429rem * ($paddingControlFactor)) (0.857rem * ($paddingControlFactor));
			color: $color_mine_shaft_approx;
		}
		.p-picklist-droppoint.p-picklist-droppoint-highlight {
			background-color: $color_endeavour_approx;
		}
	}
}
.p-timeline {
	.p-timeline-event-marker {
		border: 0 none;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50%;
		width: (1rem * $borderFactor);
		height: (1rem * $borderFactor);
		background-color: $color_lochmara_approx;
	}
	.p-timeline-event-connector {
		background-color: $color_silver_approx;
	}
	&.p-timeline-vertical {
		.p-timeline-event-opposite {
			padding: 0 1rem;
		}
		.p-timeline-event-content {
			padding: 0 1rem;
		}
		.p-timeline-event-connector {
			width: 00.125rem;
		}
	}
	&.p-timeline-horizontal {
		.p-timeline-event-opposite {
			padding: (1rem *($paddingControlFactor) ) 0;
		}
		.p-timeline-event-content {
			padding: (1rem *($paddingControlFactor) ) 0;
		}
		.p-timeline-event-connector {
			height: (00.125rem * $heightFactor);
		}
	}
}
.p-tree {
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	background: $white;
	color: $color_mine_shaft_approx;
	padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-tree-filter-container {
		margin-bottom: (0.5rem *($marginControlFactor) );
		.p-tree-filter {
			width: 100%;
			padding-right: (1.429rem * ($paddingControlFactor));
		}
		.p-tree-filter-icon {
			right: (0.429rem *($paddingRightFactor));
			color: $color_gray_approx;
		}
	}
	.p-treenode-children {
		padding: 0 0 0 (1rem * $paddingControlFactor);
	}
	.p-tree-loading-icon {
		font-size: 2rem;
	}
	.p-tree-container .p-treenode {
		padding: (0.143rem *($paddingControlFactor) );
		.p-treenode-content {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			padding: (0.429rem *($paddingControlFactor) );
			.p-tree-toggler {
				margin-right: (0.5rem * ($marginControlFactor));
				width: (2rem * ($borderFactor));
				height: (2rem * ($borderFactor));
				color: $color_silver_chalice_approx;
				border: 0 none;
				background: transparent;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 50%;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
				}
				&:enabled:hover {
					color: $color_lochmara_approx;
					border-color: transparent;
					background: transparent;
				}
			}
			.p-treenode-icon {
				margin-right: (0.5rem * ($marginControlFactor));
				color: $color_gray_approx;
			}
			.p-checkbox {
				margin-right: (0.5rem * ($marginControlFactor));
				.p-indeterminate .p-checkbox-icon {
					color: $color_mine_shaft_approx;
				}
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
			&.p-highlight {
				background: $color_lochmara_approx;
				color: $white;
				.p-tree-toggler {
					color: $white;
					&:hover {
						color: $white;
					}
				}
				.p-treenode-icon {
					color: $white;
					&:hover {
						color: $white;
					}
				}
			}
			&.p-treenode-dragover {
				background: $color_mercury_approx;
				color: $color_mine_shaft_approx;
			}
			&.p-treenode-selectable:not(.p-highlight):hover {
				background: $color_mercury_approx;
				color: $color_mine_shaft_approx;
			}
		}
	}
	.p-treenode-droppoint.p-treenode-droppoint-active {
		background-color: $color_endeavour_approx;
	}
	&.p-tree-horizontal .p-treenode .p-treenode-content {
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		background-color: $white;
		color: $color_mine_shaft_approx;
		padding: (0.429rem *($paddingControlFactor) );
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		&.p-highlight {
			background-color: $color_lochmara_approx;
			color: $white;
			.p-treenode-icon {
				color: $white;
			}
		}
		.p-tree-toggler {
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-treenode-icon {
			color: $color_gray_approx;
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-checkbox {
			margin-right: (0.5rem * ($marginControlFactor));
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
		}
		.p-treenode-label:not(.p-highlight):hover {
			background-color: inherit;
			color: inherit;
		}
		&.p-treenode-selectable:not(.p-highlight):hover {
			background: $color_mercury_approx;
			color: $color_mine_shaft_approx;
		}
	}
}
.p-treetable {
	.p-paginator-top {
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
	}
	.p-paginator-bottom {
		border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
	}
	.p-treetable-header {
		background: $wild_sand;
		color: $color_mine_shaft_approx;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		font-weight: 700;
	}
	.p-treetable-footer {
		background: $white;
		color: $color_mine_shaft_approx;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		font-weight: normal;
	}
	.p-sortable-column {
		outline-color: $color_malibu_approx1;
		.p-sortable-column-icon {
			color: $color_gray_approx;
			margin-left: (0.5rem * ($marginControlFactor));
		}
		.p-sortable-column-badge {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			height: (1.143rem * ($borderFactor));
			min-width: (1.143rem * ($borderFactor));
			line-height: (1.143rem * ($heightFactor));
			color: $white;
			background: $color_lochmara_approx;
			margin-left: (0.5rem * ($marginControlFactor));
		}
		&.p-highlight {
			background: $color_lochmara_approx;
			color: $white;
			.p-sortable-column-icon {
				color: $white;
			}
		}
		&:not(.p-highlight):hover {
			background: $color_mercury_approx;
			color: $color_mine_shaft_approx;
			.p-sortable-column-icon {
				color: $color_mine_shaft_approx;
			}
		}
	}
	.p-column-resizer-helper {
		background: $color_lochmara_approx;
	}
	.p-treetable-scrollable-header {
		background: $wild_sand;
	}
	.p-treetable-scrollable-footer {
		background: $wild_sand;
	}
	.p-treetable-loading-icon {
		font-size: 2rem;
	}
	&.p-treetable-gridlines {
		.p-datatable-header {
			border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		}
		.p-treetable-footer {
			border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		}
		.p-treetable-top {
			border-width: 0 (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		}
		.p-treetable-bottom {
			border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		}
		.p-treetable-thead > tr > th {
			border-width: (0.063rem * ($borderFactor));
		}
		.p-treetable-tbody > tr > td {
			border-width: (0.063rem * ($borderFactor));
		}
		.p-treetable-tfoot > tr > td {
			border-width: (0.063rem * ($borderFactor));
		}
	}
	&.p-treetable-sm {
		.p-treetable-header {
			padding: (0.749875rem *($paddingControlFactor) ) (0.875rem *($paddingControlFactor) );
		}
		.p-treetable-footer {
			padding: (0.48535rem *($paddingControlFactor) ) (0.85rem *($paddingControlFactor) );
		}
		.p-treetable-thead > tr > th {
			padding: (0.48535rem *($paddingControlFactor) ) (0.72845rem *($paddingControlFactor) );
		}
		.p-treetable-tbody > tr > td {
			padding: (0.48535rem *($paddingControlFactor) ) (0.72845rem *($paddingControlFactor) );
		}
		.p-treetable-tfoot > tr > td {
			padding: (0.48535rem *($paddingControlFactor) ) (0.85rem *($paddingControlFactor) );
		}
	}
	&.p-treetable-lg {
		.p-treetable-header {
			padding: (1.07125rem *($paddingControlFactor) ) (1.25rem *($paddingControlFactor) );
		}
		.p-treetable-footer {
			padding: (0.71375rem *($paddingControlFactor) ) (1.25rem *($paddingControlFactor) );
		}
		.p-treetable-thead > tr > th {
			padding: (0.71375rem *($paddingControlFactor) ) (1.07125rem *($paddingControlFactor) );
		}
		.p-treetable-tbody > tr > td {
			padding: (0.71375rem *($paddingControlFactor) ) (1.07125rem *($paddingControlFactor) );
		}
		.p-treetable-tfoot > tr > td {
			padding: (0.71375rem *($paddingControlFactor) ) (1.25rem *($paddingControlFactor) );
		}
	}
	.p-treetable-thead > tr > th {
		text-align: left;
		padding: (0.571rem *($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		font-weight: 700;
		color: $color_mine_shaft_approx;
		background: $wild_sand;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
	}
	.p-treetable-tfoot > tr > td {
		text-align: left;
		padding: (0.571rem *($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		border-width: (0.063rem * ($borderFactor));
		font-weight: 700;
		color: $color_mine_shaft_approx;
		background: $wild_sand;
	}
	.p-treetable-tbody > tr {
		background: $white;
		color: $color_mine_shaft_approx;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		outline-color: $color_malibu_approx1;
		> td {
			text-align: left;
			border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
			border-width: (0.063rem * ($borderFactor));
			padding: (0.571rem *($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
			.p-treetable-toggler {
				width: (2rem * ($borderFactor));
				height: (2rem * ($borderFactor));
				color: $color_silver_chalice_approx;
				border: 0 none;
				background: transparent;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 50%;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				margin-right: (0.5rem * ($marginControlFactor));
				&:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
				}
				&:enabled:hover {
					color: $color_lochmara_approx;
					border-color: transparent;
					background: transparent;
				}
			}
			p-treetablecheckbox .p-checkbox {
				margin-right: (0.5rem * ($marginControlFactor));
				.p-indeterminate .p-checkbox-icon {
					color: $color_mine_shaft_approx;
				}
			}
		}
		&.p-highlight {
			background: $color_lochmara_approx;
			color: $white;
			.p-treetable-toggler {
				color: $white;
				&:hover {
					color: $white;
				}
			}
		}
	}
	&.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
		background: $color_mercury_approx;
		color: $color_mine_shaft_approx;
		.p-treetable-toggler {
			color: $color_mine_shaft_approx;
		}
	}
}
.p-virtualscroller {
	.p-virtualscroller-header {
		background: $wild_sand;
		color: $color_mine_shaft_approx;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		border-width: (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) 0 (0.063rem * ($borderFactor));
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		font-weight: 700;
	}
	.p-virtualscroller-content {
		background: $white;
		color: $color_mine_shaft_approx;
		border: 0 none;
		padding: 0;
	}
	.p-virtualscroller-footer {
		background: $white;
		color: $color_mine_shaft_approx;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		border-width: 0 (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor)) (0.063rem * ($borderFactor));
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		font-weight: normal;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
	}
}
.p-accordion {
	.p-accordion-header {
		.p-accordion-header-link {
			padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
			border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
			color: $color_mine_shaft_approx;
			background: $wild_sand;
			font-weight: 700;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			.p-accordion-toggle-icon {
				margin-right: (0.5rem * ($marginControlFactor));
			}
		}
		&:not(&.p-disabled) {
				&.p-highlight {
					.p-accordion-header-link {
						background: $color_lochmara_approx;
						border-color: $color_lochmara_approx;
						color: $white;
						//Instead of the line below you could use @include border-bottom-right-radius($radius)
						border-bottom-right-radius: 0;
						//Instead of the line below you could use @include border-bottom-left-radius($radius)
						border-bottom-left-radius: 0;
					}
					&:hover .p-accordion-header-link {
						border-color: $color_endeavour_approx;
						background: $color_endeavour_approx;
						color: $white;
					}
				}
				.p-accordion-header-link:focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
				}
			}
			.p-highlight:not(.p-disabled):hover .p-accordion-header-link {
				background: $alto;
				border-color: $alto;
				color: $color_mine_shaft_approx;
			}

	}
	.p-accordion-content {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		background: $white;
		color: $color_mine_shaft_approx;
		border-top: 0;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
	}
	p-accordiontab .p-accordion-tab {
		margin-bottom: 00.125rem;
	}
}
.p-card {
	background: $white;
	color: $color_mine_shaft_approx;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 (0.063rem * ($borderFactor)) 0.188rem $black_12, 0 (0.063rem * ($borderFactor)) 00.125rem $black_24;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-card-body {
		padding: (1rem *($paddingControlFactor) );
	}
	.p-card-title {
		font-size: 1.5rem;
		font-weight: 700;
		margin-bottom: (0.5rem *($marginControlFactor) );
	}
	.p-card-subtitle {
		font-weight: 700;
		margin-bottom: (0.5rem *($marginControlFactor) );
		color: $color_gray_approx;
	}
	.p-card-content {
		padding: (1rem *($paddingControlFactor) ) 0;
	}
	.p-card-footer {
		padding: 1rem 0 0;
	}
}
.p-divider {
	.p-divider-content {
		background-color: $white;
	}
	&.p-divider-horizontal {
		margin: (1rem * $marginControlFactor) 0;
		padding: 0 (1rem * $paddingControlFactor);
		&:before {
			border-top: (0.063rem * ($borderFactor)) $color_silver_approx;
		}
		.p-divider-content {
			padding: 0 (0.5rem *($paddingControlFactor) );
		}
	}
	&.p-divider-vertical {
		margin: 0 (1rem * $marginControlFactor);
		padding: (1rem *($paddingControlFactor) ) 0;
		&:before {
			border-left: (0.063rem * ($borderFactor)) $color_silver_approx;
		}
		.p-divider-content {
			padding: (0.5rem *($paddingControlFactor) ) 0;
		}
	}
}
.p-fieldset {
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	background: $white;
	color: $color_mine_shaft_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-fieldset-legend {
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		color: $color_mine_shaft_approx;
		background: $wild_sand;
		font-weight: 700;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
	}
	.p-fieldset-content {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	}
	&.p-fieldset-toggleable .p-fieldset-legend {
		padding: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		a {
			padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
			color: $color_mine_shaft_approx;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			.p-fieldset-toggler {
				margin-right: (0.5rem * ($marginControlFactor));
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
		}
		&:hover {
			background: $alto;
			border-color: $alto;
			color: $color_mine_shaft_approx;
		}
	}
}
.p-panel {
	.p-panel-header {
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		background: $wild_sand;
		color: $color_mine_shaft_approx;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-panel-title {
			font-weight: 700;
		}
		.p-panel-header-icon {
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			border: 0 none;
			background: transparent;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			color: $color_gray_approx;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
			&:enabled:hover {
				border-color: transparent;
				background: transparent;
				color: $color_mine_shaft_approx;
			}
		}
	}
	.p-panel-content {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		background: $white;
		color: $color_mine_shaft_approx;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		border-top: 0 none;
	}
	.p-panel-footer {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		background: $white;
		color: $color_mine_shaft_approx;
		border-top: 0 none;
	}
	&.p-panel-toggleable .p-panel-header {
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	}
}
.p-splitter {
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	background: $white;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	color: $color_mine_shaft_approx;
	.p-splitter-gutter {
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		background: $color_mercury_approx;
		.p-splitter-gutter-handle {
			background: $color_mischka_approx;
		}
	}
	.p-splitter-gutter-resizing {
		background: $color_mischka_approx;
	}
}
.p-tabview {
	.p-tabview-nav {
		background: transparent;
		border: 0 none;
		border-width: (0.063rem * ($borderFactor));
		li {
			margin-right: 00.125rem;
			.p-tabview-nav-link {
				border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
				border-width: (0.063rem * ($borderFactor));
				border-color: $color_silver_approx;
				background: $wild_sand;
				color: $color_mine_shaft_approx;
				padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
				font-weight: 700;
				//Instead of the line below you could use @include border-top-right-radius($radius)
				border-top-right-radius: 0.188rem;
				//Instead of the line below you could use @include border-top-left-radius($radius)
				border-top-left-radius: 0.188rem;
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, box-shadow 0.2s;
				margin: 0 0 (-(0.063rem * ($borderFactor))) 0;
				&:not(.p-disabled):focus {
					outline: 0 none;
					outline-offset: 0;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
				}
			}
			&:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
				background: $alto;
				border-color: $alto;
				color: $color_mine_shaft_approx;
			}
			&.p-highlight .p-tabview-nav-link {
				background: $color_lochmara_approx;
				border-color: $color_lochmara_approx;
				color: $white;
			}
		}
	}
	.p-tabview-left-icon {
		margin-right: (0.5rem * ($marginControlFactor));
	}
	.p-tabview-right-icon {
		margin-left: (0.5rem * ($marginControlFactor));
	}
	.p-tabview-close {
		margin-left: (0.5rem * ($marginControlFactor));
	}
	.p-tabview-panels {
		background: $white;
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		color: $color_mine_shaft_approx;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
	}
}
.p-toolbar {
	background: $wild_sand;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-toolbar-separator {
		margin: 0 (0.5rem *($marginControlFactor) );
	}
}
.p-confirm-popup {
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0 0.375rem 0 $black_16;
	.p-confirm-popup-content {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	}
	.p-confirm-popup-footer {
		text-align: right;
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		button {
			margin: 0 0.5rem 0 0;
			width: auto;
			&:last-child {
				margin: 0;
			}
		}
	}
	&:after {
		border: solid transparent;
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: $white;
	}
	&:before {
		border: solid transparent;
		border-color: rgba(200, 200, 200, 0);
		border-bottom-color: $color_silver_approx;
	}
	&.p-confirm-popup-flipped {
		&:after {
			border-top-color: $white;
		}
		&:before {
			border-top-color: $color_silver_approx;
		}
	}
	.p-confirm-popup-icon {
		font-size: 1.5rem;
	}
	.p-confirm-popup-message {
		margin-left: (1rem *($marginControlFactor) );
	}
}
.p-dialog {
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0 0.375rem 0 $black_16;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	.p-dialog-header {
		border-bottom: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		background: $wild_sand;
		color: $color_mine_shaft_approx;
		padding: (1rem *($paddingControlFactor) );
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-dialog-title {
			font-weight: 700;
			font-size: $fontSizeDefault;
		}
		.p-dialog-header-icon {
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			border: 0 none;
			background: transparent;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			margin-right: (0.5rem * ($marginControlFactor));
			color: $color_gray_approx;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
			&:last-child {
				margin-right: 0;
			}
			&:enabled:hover {
				border-color: transparent;
				background: transparent;
				color: $color_mine_shaft_approx;
			}
		}
	}
	.p-dialog-content {
		background: $white;
		color: $color_mine_shaft_approx;
		padding: (1rem *($paddingControlFactor) );
	}
	.p-dialog-footer {
		border-top: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		background: $white;
		color: $color_mine_shaft_approx;
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		text-align: right;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		button {
			margin: 0 0.5rem 0 0;
			width: auto;
		}
	}
	&.p-confirm-dialog {
		.p-confirm-dialog-icon {
			font-size: 2rem;
		}
		.p-confirm-dialog-message {
			margin-left: (1rem *($marginControlFactor) );
		}
	}
}
.p-overlaypanel {
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0 0.375rem 0 $black_16;
	.p-overlaypanel-content {
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	}
	.p-overlaypanel-close {
		background: $color_lochmara_approx;
		color: $white;
		width: (2rem * ($borderFactor));
		height: (2rem * ($borderFactor));
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50%;
		position: absolute;
		top: (-1rem *($marginControlFactor) );
		right: (-1rem *($marginControlFactor) );
		&:enabled:hover {
			background: $color_denim_approx;
			color: $white;
		}
	}
	&:after {
		border: solid transparent;
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: $white;
	}
	&:before {
		border: solid transparent;
		border-color: rgba(200, 200, 200, 0);
		border-bottom-color: $color_silver_approx;
	}
	&.p-overlaypanel-flipped {
		&:after {
			border-top-color: $white;
		}
		&:before {
			border-top-color: $color_silver_approx;
		}
	}
}
.p-sidebar {
	background: $white;
	color: $color_mine_shaft_approx;
	padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0 0.375rem 0 $black_16;
	.p-sidebar-close {
		width: (2rem * ($borderFactor));
		height: (2rem * ($borderFactor));
		color: $color_silver_chalice_approx;
		border: 0 none;
		background: transparent;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50%;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
		}
		&:enabled:hover {
			color: $color_lochmara_approx;
			border-color: transparent;
			background: transparent;
		}
	}
}
.p-tooltip {
	.p-tooltip-text {
		background: $color_mine_shaft_approx;
		color: $white;
		padding: (0.429rem *($paddingControlFactor)) (0.429rem *($paddingControlFactor));
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0.188rem 0.375rem 0 $black_16;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
	}
	&.p-tooltip-right .p-tooltip-arrow {
		border-right-color: $color_mine_shaft_approx;
	}
	&.p-tooltip-left .p-tooltip-arrow {
		border-left-color: $color_mine_shaft_approx;
	}
	&.p-tooltip-top .p-tooltip-arrow {
		border-top-color: $color_mine_shaft_approx;
	}
	&.p-tooltip-bottom .p-tooltip-arrow {
		border-bottom-color: $color_mine_shaft_approx;
	}
}
.p-fileupload {
	.p-fileupload-buttonbar {
		background: $wild_sand;
		padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		color: $color_mine_shaft_approx;
		border-bottom: 0 none;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
		.p-button {
			margin-right: (0.5rem * ($marginControlFactor));
			&.p-fileupload-choose.p-focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
		}
	}
	.p-fileupload-content {
		background: $white;
		padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		color: $color_mine_shaft_approx;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
	}
	.p-progressbar {
		height: (0.25rem * $heightFactor);
	}
	.p-fileupload-row > div {
		padding: (0.571rem *($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
	}
	&.p-fileupload-advanced .p-message {
		margin-top: 0;
	}
}
.p-breadcrumb {
	background: $white;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	ul li {
		.p-menuitem-link {
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
			.p-menuitem-text {
				color: $color_mine_shaft_approx;
			}
			.p-menuitem-icon {
				color: $color_mine_shaft_approx;
			}
		}
		&.p-breadcrumb-chevron {
			margin: 0 0.5rem 0 0.5rem;
			color: $color_mine_shaft_approx;
		}
		&:last-child {
			.p-menuitem-text {
				color: $color_mine_shaft_approx;
			}
			.p-menuitem-icon {
				color: $color_mine_shaft_approx;
			}
		}
	}
}
.p-contextmenu {
	padding: 0;
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0 0.375rem 0 $black_16;
	width: (12.5rem *($widthFactor) );
	.p-menuitem-link {
		padding: (0.857rem * ($paddingControlFactor));
		color: $color_mine_shaft_approx;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include user-select($select)
		user-select: none;
		.p-menuitem-text {
			color: $color_mine_shaft_approx;
		}
		.p-menuitem-icon {
			color: $color_mine_shaft_approx;
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-submenu-icon {
			color: $color_mine_shaft_approx;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
		}
		&:not(.p-disabled):hover {
			background: $color_mercury_approx;
			.p-menuitem-text {
				color: $color_mine_shaft_approx;
			}
			.p-menuitem-icon {
				color: $color_mine_shaft_approx;
			}
			.p-submenu-icon {
				color: $color_mine_shaft_approx;
			}
		}
	}
	.p-submenu-list {
		padding: 0;
		background: $white;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0.375rem 0 $black_16;
	}
	.p-menuitem {
		margin: 0;
		&:last-child {
			margin: 0;
		}
		&.p-menuitem-active > .p-menuitem-link {
			background: $color_mercury_approx;
			.p-menuitem-text {
				color: $color_mine_shaft_approx;
			}
			.p-menuitem-icon {
				color: $color_mine_shaft_approx;
			}
			.p-submenu-icon {
				color: $color_mine_shaft_approx;
			}
		}
	}
	.p-menu-separator {
		border-top: (0.063rem * ($borderFactor)) solid $color_mischka_approx;
		margin: 0;
	}
	.p-submenu-icon {
		font-size: ($fontSizeSmaller);
	}
}
.p-megamenu {
	padding: (0.5rem * ($paddingControlFactor));
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-menuitem-link {
		padding: (0.857rem * ($paddingControlFactor));
		color: $color_mine_shaft_approx;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include user-select($select)
		user-select: none;
		.p-menuitem-text {
			color: $color_mine_shaft_approx;
		}
		.p-menuitem-icon {
			color: $color_mine_shaft_approx;
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-submenu-icon {
			color: $color_mine_shaft_approx;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
		}
		&:not(.p-disabled):hover {
			background: $color_mercury_approx;
			.p-menuitem-text {
				color: $color_mine_shaft_approx;
			}
			.p-menuitem-icon {
				color: $color_mine_shaft_approx;
			}
			.p-submenu-icon {
				color: $color_mine_shaft_approx;
			}
		}
	}
	.p-megamenu-panel {
		background: $white;
		color: $color_mine_shaft_approx;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0.375rem 0 $black_16;
	}
	.p-megamenu-submenu-header {
		margin: 0;
		padding: (0.857rem * ($paddingControlFactor));
		color: $color_mine_shaft_approx;
		background: $wild_sand;
		font-weight: 700;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
	}
	.p-megamenu-submenu {
		padding: 0;
		width: (12.5rem *($widthFactor) );
		.p-menu-separator {
			border-top: (0.063rem * ($borderFactor)) solid $color_mischka_approx;
			margin: 0;
		}
		.p-menuitem {
			margin: 0;
			&:last-child {
				margin: 0;
			}
		}
	}
	&.p-megamenu-vertical {
		width: (12.5rem *($widthFactor) );
		padding: 0;
		.p-menuitem {
			margin: 0;
			&:last-child {
				margin: 0;
			}
		}
	}
	.p-megamenu-root-list > .p-menuitem {
		> .p-menuitem-link {
			padding: (0.857rem * ($paddingControlFactor));
			color: $color_mine_shaft_approx;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include user-select($select)
			user-select: none;
			.p-menuitem-text {
				color: $color_mine_shaft_approx;
			}
			.p-menuitem-icon {
				color: $color_mine_shaft_approx;
				margin-right: (0.5rem * ($marginControlFactor));
			}
			.p-submenu-icon {
				color: $color_mine_shaft_approx;
				margin-left: (0.5rem * ($marginControlFactor));
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
			}
			&:not(.p-disabled):hover {
				background: $color_mercury_approx;
				.p-menuitem-text {
					color: $color_mine_shaft_approx;
				}
				.p-menuitem-icon {
					color: $color_mine_shaft_approx;
				}
				.p-submenu-icon {
					color: $color_mine_shaft_approx;
				}
			}
		}
		&.p-menuitem-active > .p-menuitem-link {
			background: $color_mercury_approx;
			.p-menuitem-text {
				color: $color_mine_shaft_approx;
			}
			.p-menuitem-icon {
				color: $color_mine_shaft_approx;
			}
			.p-submenu-icon {
				color: $color_mine_shaft_approx;
			}
			&:not(.p-disabled):hover {
				background: $color_mercury_approx;
				.p-menuitem-text {
					color: $color_mine_shaft_approx;
				}
				.p-menuitem-icon {
					color: $color_mine_shaft_approx;
				}
				.p-submenu-icon {
					color: $color_mine_shaft_approx;
				}
			}
		}
	}
	.p-menuitem.p-menuitem-active > .p-menuitem-link {
		background: $color_mercury_approx;
		.p-menuitem-text {
			color: $color_mine_shaft_approx;
		}
		.p-menuitem-icon {
			color: $color_mine_shaft_approx;
		}
		.p-submenu-icon {
			color: $color_mine_shaft_approx;
		}
	}
}
.p-menu {
	padding: 0;
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	width: (12.5rem *($widthFactor) );
	.p-menuitem-link {
		padding: (0.857rem * ($paddingControlFactor));
		color: $color_mine_shaft_approx;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include user-select($select)
		user-select: none;
		.p-menuitem-text {
			color: $color_mine_shaft_approx;
		}
		.p-menuitem-icon {
			color: $color_mine_shaft_approx;
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-submenu-icon {
			color: $color_mine_shaft_approx;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
		}
		&:not(.p-disabled):hover {
			background: $color_mercury_approx;
			.p-menuitem-text {
				color: $color_mine_shaft_approx;
			}
			.p-menuitem-icon {
				color: $color_mine_shaft_approx;
			}
			.p-submenu-icon {
				color: $color_mine_shaft_approx;
			}
		}
	}
	&.p-menu-overlay {
		background: $white;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0.375rem 0 $black_16;
	}
	.p-submenu-header {
		margin: 0;
		padding: (0.857rem * ($paddingControlFactor));
		color: $color_mine_shaft_approx;
		background: $wild_sand;
		font-weight: 700;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0.188rem;
	}
	.p-menu-separator {
		border-top: (0.063rem * ($borderFactor)) solid $color_mischka_approx;
		margin: 0;
	}
	.p-menuitem {
		margin: 0;
		&:last-child {
			margin: 0;
		}
	}
}
.p-menubar {
	padding: (0.5rem * ($paddingControlFactor));
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-menuitem-link {
		padding: (0.857rem * ($paddingControlFactor));
		color: $color_mine_shaft_approx;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include user-select($select)
		user-select: none;
		.p-menuitem-text {
			color: $color_mine_shaft_approx;
		}
		.p-menuitem-icon {
			color: $color_mine_shaft_approx;
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-submenu-icon {
			color: $color_mine_shaft_approx;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
		}
		&:not(.p-disabled):hover {
			background: $color_mercury_approx;
			.p-menuitem-text {
				color: $color_mine_shaft_approx;
			}
			.p-menuitem-icon {
				color: $color_mine_shaft_approx;
			}
			.p-submenu-icon {
				color: $color_mine_shaft_approx;
			}
		}
	}
	.p-submenu-list {
		padding: 0;
		background: $white;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0.375rem 0 $black_16;
		width: (12.5rem *($widthFactor) );
		.p-menu-separator {
			border-top: (0.063rem * ($borderFactor)) solid $color_mischka_approx;
			margin: 0;
		}
		.p-submenu-icon {
			font-size: ($fontSizeSmaller);
		}
		.p-menuitem {
			margin: 0;
			&:last-child {
				margin: 0;
			}
		}
	}
	.p-menubar-root-list > .p-menuitem {
		> .p-menuitem-link {
			padding: (0.857rem * ($paddingControlFactor));
			color: $color_mine_shaft_approx;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include user-select($select)
			user-select: none;
			.p-menuitem-text {
				color: $color_mine_shaft_approx;
			}
			.p-menuitem-icon {
				color: $color_mine_shaft_approx;
				margin-right: (0.5rem * ($marginControlFactor));
			}
			.p-submenu-icon {
				color: $color_mine_shaft_approx;
				margin-left: (0.5rem * ($marginControlFactor));
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
			}
			&:not(.p-disabled):hover {
				background: $color_mercury_approx;
				.p-menuitem-text {
					color: $color_mine_shaft_approx;
				}
				.p-menuitem-icon {
					color: $color_mine_shaft_approx;
				}
				.p-submenu-icon {
					color: $color_mine_shaft_approx;
				}
			}
		}
		&.p-menuitem-active > .p-menuitem-link {
			background: $color_mercury_approx;
			.p-menuitem-text {
				color: $color_mine_shaft_approx;
			}
			.p-menuitem-icon {
				color: $color_mine_shaft_approx;
			}
			.p-submenu-icon {
				color: $color_mine_shaft_approx;
			}
			&:not(.p-disabled):hover {
				background: $color_mercury_approx;
				.p-menuitem-text {
					color: $color_mine_shaft_approx;
				}
				.p-menuitem-icon {
					color: $color_mine_shaft_approx;
				}
				.p-submenu-icon {
					color: $color_mine_shaft_approx;
				}
			}
		}
	}
	.p-menuitem.p-menuitem-active > .p-menuitem-link {
		background: $color_mercury_approx;
		.p-menuitem-text {
			color: $color_mine_shaft_approx;
		}
		.p-menuitem-icon {
			color: $color_mine_shaft_approx;
		}
		.p-submenu-icon {
			color: $color_mine_shaft_approx;
		}
	}
}
.p-panelmenu {
	.p-panelmenu-header {
		> a {
			padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
			border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
			color: $color_mine_shaft_approx;
			background: $wild_sand;
			font-weight: 700;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0.188rem;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			.p-panelmenu-icon {
				margin-right: (0.5rem * ($marginControlFactor));
			}
			.p-menuitem-icon {
				margin-right: (0.5rem * ($marginControlFactor));
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
		}
		&.p-highlight {
			margin-bottom: 0;
			> a {
				background: $color_lochmara_approx;
				border-color: $color_lochmara_approx;
				color: $white;
				//Instead of the line below you could use @include border-bottom-right-radius($radius)
				border-bottom-right-radius: 0;
				//Instead of the line below you could use @include border-bottom-left-radius($radius)
				border-bottom-left-radius: 0;
			}
			&:not(.p-disabled) > a:hover {
				border-color: $color_endeavour_approx;
				background: $color_endeavour_approx;
				color: $white;
			}
		}
		&:not(.p-highlight):not(.p-disabled) > a:hover {
			background: $alto;
			border-color: $alto;
			color: $color_mine_shaft_approx;
		}
	}
	.p-panelmenu-content {
		padding: 0;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		background: $white;
		color: $color_mine_shaft_approx;
		margin-bottom: 00.125rem;
		border-top: 0;
		//Instead of the line below you could use @include border-top-right-radius($radius)
		border-top-right-radius: 0;
		//Instead of the line below you could use @include border-top-left-radius($radius)
		border-top-left-radius: 0;
		//Instead of the line below you could use @include border-bottom-right-radius($radius)
		border-bottom-right-radius: 0.188rem;
		//Instead of the line below you could use @include border-bottom-left-radius($radius)
		border-bottom-left-radius: 0.188rem;
		.p-menuitem .p-menuitem-link {
			padding: (0.857rem * ($paddingControlFactor));
			color: $color_mine_shaft_approx;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include user-select($select)
			user-select: none;
			.p-menuitem-text {
				color: $color_mine_shaft_approx;
			}
			.p-menuitem-icon {
				color: $color_mine_shaft_approx;
				margin-right: (0.5rem * ($marginControlFactor));
			}
			.p-submenu-icon {
				color: $color_mine_shaft_approx;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
			}
			.p-panelmenu-icon {
				margin-right: (0.5rem * ($marginControlFactor));
			}
			&:not(.p-disabled):hover {
				background: $color_mercury_approx;
				.p-menuitem-text {
					color: $color_mine_shaft_approx;
				}
				.p-menuitem-icon {
					color: $color_mine_shaft_approx;
				}
				.p-submenu-icon {
					color: $color_mine_shaft_approx;
				}
			}
		}
		.p-submenu-list:not(.p-panelmenu-root-submenu) {
			padding: 0 0 0 (1rem *($paddingControlFactor) );
		}
	}
	.p-panelmenu-panel {
		margin-bottom: 00.125rem;
	}
}
.p-slidemenu {
	padding: 0;
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	width: (12.5rem *($widthFactor) );
	.p-menuitem-link {
		padding: (0.857rem * ($paddingControlFactor));
		color: $color_mine_shaft_approx;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include user-select($select)
		user-select: none;
		.p-menuitem-text {
			color: $color_mine_shaft_approx;
		}
		.p-menuitem-icon {
			color: $color_mine_shaft_approx;
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-submenu-icon {
			color: $color_mine_shaft_approx;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
		}
		&:not(.p-disabled):hover {
			background: $color_mercury_approx;
			.p-menuitem-text {
				color: $color_mine_shaft_approx;
			}
			.p-menuitem-icon {
				color: $color_mine_shaft_approx;
			}
			.p-submenu-icon {
				color: $color_mine_shaft_approx;
			}
		}
	}
	&.p-slidemenu-overlay {
		background: $white;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0.375rem 0 $black_16;
	}
	.p-slidemenu-list {
		padding: 0;
		background: $white;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0.375rem 0 $black_16;
	}
	.p-slidemenu-separator {
		border-top: (0.063rem * ($borderFactor)) solid $color_mischka_approx;
		margin: 0;
	}
	.p-slidemenu-icon {
		font-size: ($fontSizeSmaller);
	}
	.p-slidemenu-backward {
		padding: (0.857rem * ($paddingControlFactor));
		color: $color_mine_shaft_approx;
	}
	.p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
		background: $color_mercury_approx;
		.p-slidemenu-text {
			color: $color_mine_shaft_approx;
		}
		.p-slidemenu-icon {
			color: $color_mine_shaft_approx;
			color: $color_mine_shaft_approx;
		}
	}
}
.p-tieredmenu {
	padding: 0;
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	width: (12.5rem *($widthFactor) );
	.p-menuitem-link {
		padding: (0.857rem * ($paddingControlFactor));
		color: $color_mine_shaft_approx;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include user-select($select)
		user-select: none;
		.p-menuitem-text {
			color: $color_mine_shaft_approx;
		}
		.p-menuitem-icon {
			color: $color_mine_shaft_approx;
			margin-right: (0.5rem * ($marginControlFactor));
		}
		.p-submenu-icon {
			color: $color_mine_shaft_approx;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 0 0 0.2rem $color_malibu_approx1;
		}
		&:not(.p-disabled):hover {
			background: $color_mercury_approx;
			.p-menuitem-text {
				color: $color_mine_shaft_approx;
			}
			.p-menuitem-icon {
				color: $color_mine_shaft_approx;
			}
			.p-submenu-icon {
				color: $color_mine_shaft_approx;
			}
		}
	}
	&.p-tieredmenu-overlay {
		background: $white;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0.375rem 0 $black_16;
	}
	.p-submenu-list {
		padding: 0;
		background: $white;
		border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0.375rem 0 $black_16;
	}
	.p-menuitem {
		margin: 0;
		&:last-child {
			margin: 0;
		}
		&.p-menuitem-active > .p-menuitem-link {
			background: $color_mercury_approx;
			.p-menuitem-text {
				color: $color_mine_shaft_approx;
			}
			.p-menuitem-icon {
				color: $color_mine_shaft_approx;
			}
			.p-submenu-icon {
				color: $color_mine_shaft_approx;
			}
		}
	}
	.p-menu-separator {
		border-top: (0.063rem * ($borderFactor)) solid $color_mischka_approx;
		margin: 0;
	}
	.p-submenu-icon {
		font-size: ($fontSizeSmaller);
	}
}
.p-inline-message {
	padding: (0.5rem * ($paddingControlFactor));
	margin: 0;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&.p-inline-message-info {
		background: $color_jordy_blue_approx;
		border: 0 none;
		border-width: 0;
		color: $color_mine_shaft_approx;
		.p-inline-message-icon {
			color: $color_mine_shaft_approx;
		}
	}
	&.p-inline-message-success {
		background: $color_surf_approx;
		border: 0 none;
		border-width: 0;
		color: $color_mine_shaft_approx;
		.p-inline-message-icon {
			color: $color_mine_shaft_approx;
		}
	}
	&.p-inline-message-warn {
		background: $color_golden_glow_approx;
		border: 0 none;
		border-width: 0;
		color: $color_mine_shaft_approx;
		.p-inline-message-icon {
			color: $color_mine_shaft_approx;
		}
	}
	&.p-inline-message-error {
		background: $color_sundown_approx;
		border: 0 none;
		border-width: 0;
		color: $color_mine_shaft_approx;
		.p-inline-message-icon {
			color: $color_mine_shaft_approx;
		}
	}
	.p-inline-message-icon {
		font-size: $fontSizeDefault;
		margin-right: (0.5rem * ($marginControlFactor));
	}
	.p-inline-message-text {
		font-size: $fontSizeDefault;
	}
	&.p-inline-message-icon-only .p-inline-message-icon {
		margin-right: 0;
	}
}
.p-message {
	margin: 1rem 0;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-message-wrapper {
		padding: (1rem *($paddingControlFactor) );
	}
	.p-message-close {
		width: (2rem * ($borderFactor));
		height: (2rem * ($borderFactor));
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50%;
		background: transparent;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		&:hover {
			background: $white_30;
		}
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
		}
	}
	&.p-message-info {
		background: $color_jordy_blue_approx;
		border: 0 none;
		border-width: 0;
		color: $color_mine_shaft_approx;
		.p-message-icon {
			color: $color_mine_shaft_approx;
		}
		.p-message-close {
			color: $color_mine_shaft_approx;
		}
	}
	&.p-message-success {
		background: $color_surf_approx;
		border: 0 none;
		border-width: 0;
		color: $color_mine_shaft_approx;
		.p-message-icon {
			color: $color_mine_shaft_approx;
		}
		.p-message-close {
			color: $color_mine_shaft_approx;
		}
	}
	&.p-message-warn {
		background: $color_golden_glow_approx;
		border: 0 none;
		border-width: 0;
		color: $color_mine_shaft_approx;
		.p-message-icon {
			color: $color_mine_shaft_approx;
		}
		.p-message-close {
			color: $color_mine_shaft_approx;
		}
	}
	&.p-message-error {
		background: $color_sundown_approx;
		border: 0 none;
		border-width: 0;
		color: $color_mine_shaft_approx;
		.p-message-icon {
			color: $color_mine_shaft_approx;
		}
		.p-message-close {
			color: $color_mine_shaft_approx;
		}
	}
	.p-message-text {
		font-size: $fontSizeDefault;
		font-weight: normal;
	}
	.p-message-icon {
		font-size: 1.5rem;
		margin-right: (0.5rem * ($marginControlFactor));
	}
	.p-message-summary {
		font-weight: 700;
	}
	.p-message-detail {
		margin-left: (0.5rem * ($marginControlFactor));
	}
}
.p-toast {
	opacity: 0.9;
	.p-toast-message {
		margin: 0 0 1rem 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0.188rem 0.375rem 0 $black_16;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
		.p-toast-message-content {
			padding: (1rem *($paddingControlFactor) );
			border-width: 0;
			.p-toast-message-text {
				margin: 0 0 0 0.5rem;
			}
			.p-toast-message-icon {
				font-size: 2rem;
			}
			.p-toast-summary {
				font-weight: 700;
			}
			.p-toast-detail {
				margin: 0.5rem 0 0 0;
			}
		}
		.p-toast-icon-close {
			width: (2rem * ($paddingControlFactor));
			height: (2rem * ($paddingControlFactor));
      padding: (1rem *($paddingControlFactor) );
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			background: transparent;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			&:hover {
				background: $white_30;
			}
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
		}
		&.p-toast-message-info {
			background: $color_jordy_blue_approx;
			border: 0 none;
			border-width: 0;
			color: $color_mine_shaft_approx;
			.p-toast-message-icon {
				color: $color_mine_shaft_approx;
			}
			.p-toast-icon-close {
				color: $color_mine_shaft_approx;
			}
		}
		&.p-toast-message-success {
			background: $color_surf_approx;
			border: 0 none;
			border-width: 0;
			color: $color_mine_shaft_approx;
			.p-toast-message-icon {
				color: $color_mine_shaft_approx;
			}
			.p-toast-icon-close {
				color: $color_mine_shaft_approx;
			}
		}
		&.p-toast-message-warn {
			background: $color_golden_glow_approx;
			border: 0 none;
			border-width: 0;
			color: $color_mine_shaft_approx;
			.p-toast-message-icon {
				color: $color_mine_shaft_approx;
			}
			.p-toast-icon-close {
				color: $color_mine_shaft_approx;
			}
		}
		&.p-toast-message-error {
			background: $color_sundown_approx;
			border: 0 none;
			border-width: 0;
			color: $color_mine_shaft_approx;
			.p-toast-message-icon {
				color: $color_mine_shaft_approx;
			}
			.p-toast-icon-close {
				color: $color_mine_shaft_approx;
			}
		}
	}
}
.p-galleria {
	.p-galleria-close {
		margin: (0.5rem * ($marginControlFactor));
		background: transparent;
		color: $color_athens_gray_approx;
		width: (4rem * $borderFactor);
		height: (4rem * $borderFactor);
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50%;
		.p-galleria-close-icon {
			font-size: 2rem;
		}
		&:hover {
			background: $white_10;
			color: $color_athens_gray_approx;
		}
	}
	.p-galleria-item-nav {
		background: $black_20;
		color: $color_cadet_blue_approx;
		width: (4rem * $widthFactor);
		height: (4rem * $heightFactor);
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
		margin: 0 (0.5rem *($marginControlFactor) );
		.p-galleria-item-prev-icon {
			font-size: 2rem;
		}
		.p-galleria-item-next-icon {
			font-size: 2rem;
		}
		&:not(.p-disabled):hover {
			background: $black_30;
			color: $color_athens_gray_approx;
		}
	}
	.p-galleria-caption {
		background: $black_50;
		color: $color_athens_gray_approx;
		padding: (1rem *($paddingControlFactor) );
	}
	.p-galleria-indicators {
		padding: (1rem *($paddingControlFactor) );
		.p-galleria-indicator {
			button {
				background-color: $wild_sand;
				width: (1rem * $borderFactor);
				height: (1rem * $borderFactor);
				//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
				transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 50%;
				&:hover {
					background: $color_mercury_approx;
				}
			}
			&.p-highlight button {
				background: $color_lochmara_approx;
				color: $white;
			}
		}
	}
	.p-galleria-thumbnail-container {
		background: $black_90;
		padding: 1rem 0.25rem;
		.p-galleria-thumbnail-prev {
			margin: (0.5rem * ($marginControlFactor));
			background-color: transparent;
			color: $color_cadet_blue_approx;
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			&:hover {
				background: $white_10;
				color: $color_cadet_blue_approx;
			}
		}
		.p-galleria-thumbnail-next {
			margin: (0.5rem * ($marginControlFactor));
			background-color: transparent;
			color: $color_cadet_blue_approx;
			width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
			&:hover {
				background: $white_10;
				color: $color_cadet_blue_approx;
			}
		}
		.p-galleria-thumbnail-item-content {
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			&:focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
		}
	}
	&.p-galleria-indicators-bottom .p-galleria-indicator {
		margin-right: (0.5rem * ($marginControlFactor));
	}
	&.p-galleria-indicators-top .p-galleria-indicator {
		margin-right: (0.5rem * ($marginControlFactor));
	}
	&.p-galleria-indicators-left .p-galleria-indicator {
		margin-bottom: (0.5rem *($marginControlFactor) );
	}
	&.p-galleria-indicators-right .p-galleria-indicator {
		margin-bottom: (0.5rem *($marginControlFactor) );
	}
	&.p-galleria-indicator-onitem .p-galleria-indicators {
		background: $black_50;
		.p-galleria-indicator {
			button {
				background: $white_40;
				&:hover {
					background: $white_60;
				}
			}
			&.p-highlight button {
				background: $color_lochmara_approx;
				color: $white;
			}
		}
	}
}
.p-avatar {
	background-color: $color_silver_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&.p-avatar-lg {
		width: 3rem;
		height: 3rem;
		font-size: 1.5rem;
		.p-avatar-icon {
			font-size: 1.5rem;
		}
	}
	&.p-avatar-xl {
		width: 4rem;
		height: 4rem;
		font-size: 2rem;
		.p-avatar-icon {
			font-size: 2rem;
		}
	}
}
.p-badge {
	background: $color_lochmara_approx;
	color: $white;
	font-size: ($fontSizeSmaller);
	font-weight: 700;
	min-width: (1.5rem * ($widthFactor));
	height: (1.5rem * ($heightFactor));
	line-height: (1.5rem * ($heightFactor));
	&.p-badge-secondary {
		background-color: $color_smalt_blue_approx;
		color: $white;
	}
	&.p-badge-success {
		background-color: $color_apple_approx;
		color: $white;
	}
	&.p-badge-info {
		background-color: $color_lochmara_approx;
		color: $white;
	}
	&.p-badge-warning {
		background-color: $color_selective_yellow_approx;
		color: $color_mine_shaft_approx;
	}
	&.p-badge-danger {
		background-color: $color_crimson_approx;
		color: $white;
	}
	&.p-badge-lg {
		font-size: 1.125rem;
		min-width: 2.25rem;
		height: 2.25rem;
		line-height: (2.25rem * ($heightFactor));
	}
	&.p-badge-xl {
		font-size: 1.5rem;
		min-width: 3rem;
		height: (3rem * ($heightFactor));
		line-height: (3rem * ($heightFactor));
	}
}
.p-chip {
	background-color: $color_silver_approx;
	color: $color_mine_shaft_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 10.375rem;
	padding: 0 (0.429rem *($paddingControlFactor) );
	.p-chip-text {
		line-height: (1.5rem * ($heightFactor));
		margin-top: 0.2145rem;
		margin-bottom: 0.2145rem;
	}
	.p-chip-icon {
		margin-right: (0.5rem * ($marginControlFactor));
	}
	.pi-chip-remove-icon {
		margin-left: (0.5rem * ($marginControlFactor));
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
		&:focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
		}
	}
	img {
		width: (1.929rem *$widthFactor);
		height: (1.929rem * $heightFactor);
		margin-left: (-0.429rem * $marginControlFactor);
		margin-right: (0.5rem * ($marginControlFactor));
	}
}
.p-progressbar {
	border: 0 none;
	height: 24px;
	background: $color_mercury_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	.p-progressbar-value {
		border: 0 none;
		margin: 0;
		background: $color_lochmara_approx;
	}
	.p-progressbar-label {
		color: $color_mine_shaft_approx;
		line-height: (24px * $heightFactor) ;
	}
}
.p-scrolltop {
	width: 3rem;
	height: 3rem;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 50%;
	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	box-shadow: 0 0.188rem 0.375rem 0 $black_16;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	&.p-link {
		background: $black_70;
		&:hover {
			background: $black_80;
		}
	}
	.p-scrolltop-icon {
		font-size: 1.5rem;
		color: $wild_sand;
	}
}
.p-skeleton {
	background-color: $color_mercury_approx;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&:after {
		background: linear-gradient(90deg, rgba(255, 255, 255, 0), $white_40, rgba(255, 255, 255, 0));
	}
}
.p-tag {
	background: $color_lochmara_approx;
	color: $white;
	font-size: 0.75rem;
	font-weight: 700;
	padding: (0.25rem * $paddingControlFactor) (0.4rem * $paddingControlFactor);
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	&.p-tag-success {
		background-color: $color_apple_approx;
		color: $white;
	}
	&.p-tag-info {
		background-color: $color_lochmara_approx;
		color: $white;
	}
	&.p-tag-warning {
		background-color: $color_selective_yellow_approx;
		color: $color_mine_shaft_approx;
	}
	&.p-tag-danger {
		background-color: $color_crimson_approx;
		color: $white;
	}
	.p-tag-icon {
		margin-right: 0.25rem;
		font-size: 0.75rem;
	}
}
.p-terminal {
	background: $white;
	color: $color_mine_shaft_approx;
	border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
	padding: (0.571rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
	.p-terminal-input {
		font-size: $fontSizeDefault;
		font-family: $font_0;
	}
}
p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
	border-color: $color_bright_red_approx;
}
p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
	border-color: $color_bright_red_approx;
}
p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
	border-color: $color_bright_red_approx;
}
p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
	border-color: $color_bright_red_approx;
}
.p-chips .p-chips-multiple-container {
	padding: (0.2145rem * ($paddingControlFactor)) (0.429rem *($paddingControlFactor));
	.p-chips-token {
		padding: (0.2145rem * ($paddingControlFactor)) (0.429rem *($paddingControlFactor));
		margin-right: (0.5rem * ($marginControlFactor));
		background: $color_lochmara_approx;
		color: $white;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
		.p-chips-token-icon {
			margin-left: (0.5rem * ($marginControlFactor));
		}
	}
	.p-chips-input-token {
		padding: (0.2145rem * ($paddingControlFactor)) 0;
		input {
			font-family: $font_0;
			font-size: $fontSizeDefault;
			color: $color_mine_shaft_approx;
			padding: 0;
			margin: 0;
		}
	}
	&:not(.p-disabled) {
		&:hover {
			border-color: $color_mine_shaft_approx;
		}
		&.p-focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			border-color: $color_lochmara_approx;
		}
	}
}
p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
	border-color: $color_bright_red_approx;
}
p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
	border-color: $color_bright_red_approx;
}
p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
	border-color: $color_bright_red_approx;
}
p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
	border-color: $color_bright_red_approx;
}
p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
	border-color: $color_bright_red_approx;
}
.p-inputtext-sm .p-inputtext {
	font-size: ($fontSizeSmaller);
	padding: (0.375375rem * $paddingControlFactor) ( 0.375375rem * $paddingControlFactor);
}
.p-inputtext-lg .p-inputtext {
	font-size: $fontSizeBigger;
	padding: (0.53625rem * $paddingControlFactor) (0.53625rem * $paddingControlFactor);
}
p-listbox.ng-dirty.ng-invalid > .p-listbox {
	border-color: $color_bright_red_approx;
}
.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
	padding: (0.2145rem * ($paddingControlFactor)) (0.429rem *($paddingControlFactor));
}
p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
	border-color: $color_bright_red_approx;
}
p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
	border-color: $color_bright_red_approx;
}
.p-selectbutton .p-button {
	background: $color_alto_approx;
	border: (0.063rem * ($borderFactor)) solid $color_alto_approx;
	color: $color_mine_shaft_approx;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	.p-button-icon-left {
		color: $color_dove_gray_approx;
	}
	.p-button-icon-right {
		color: $color_dove_gray_approx;
	}
	&.p-highlight {
		background: $color_lochmara_approx;
		border-color: $color_lochmara_approx;
		color: $white;
		.p-button-icon-left {
			color: $white;
		}
		.p-button-icon-right {
			color: $white;
		}
		&:hover {
			background: $color_denim_approx;
			border-color: $color_denim_approx;
			color: $white;
			.p-button-icon-left {
				color: $white;
			}
			.p-button-icon-right {
				color: $white;
			}
		}
	}
	&:not(.p-disabled):not(.p-highlight):hover {
		background: $color_silver_approx;
		border-color: $color_silver_approx;
		color: $color_mine_shaft_approx;
		.p-button-icon-left {
			color: $color_mine_shaft_approx;
		}
		.p-button-icon-right {
			color: $color_mine_shaft_approx;
		}
	}
}
p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
	border-color: $color_bright_red_approx;
}
.p-togglebutton.p-button {
	background: $color_alto_approx;
	border: (0.063rem * ($borderFactor)) solid $color_alto_approx;
	color: $color_mine_shaft_approx;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	.p-button-icon-left {
		color: $color_dove_gray_approx;
	}
	.p-button-icon-right {
		color: $color_dove_gray_approx;
	}
	&.p-highlight {
		background: $color_lochmara_approx;
		border-color: $color_lochmara_approx;
		color: $white;
		.p-button-icon-left {
			color: $white;
		}
		.p-button-icon-right {
			color: $white;
		}
		&:hover {
			background: $color_denim_approx;
			border-color: $color_denim_approx;
			color: $white;
			.p-button-icon-left {
				color: $white;
			}
			.p-button-icon-right {
				color: $white;
			}
		}
	}
	&:not(.p-disabled):not(.p-highlight):hover {
		background: $color_silver_approx;
		border-color: $color_silver_approx;
		color: $color_mine_shaft_approx;
		.p-button-icon-left {
			color: $color_mine_shaft_approx;
		}
		.p-button-icon-right {
			color: $color_mine_shaft_approx;
		}
	}
}
p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
	border-color: $color_bright_red_approx;
}
.p-scrollpanel .p-scrollpanel-bar {
	background: $color_alabaster_approx;
	border: 0 none;
}
.p-dialog-mask.p-component-overlay {
	background-color: $black_40;
}
.p-sidebar-mask.p-component-overlay {
	background: $black_40;
}
.p-fileupload-choose:not(.p-disabled) {
	&:hover {
		background: $color_denim_approx;
		color: $white;
		border-color: $color_denim_approx;
	}
	&:active {
		background: $color_endeavour_approx;
		color: $white;
		border-color: $color_endeavour_approx;
	}
}
.p-steps .p-steps-item {
	.p-menuitem-link {
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 0.2s, box-shadow 0.2s;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0.188rem;
		background: $white;
		.p-steps-number {
			color: $color_mine_shaft_approx;
			border: (0.063rem * ($borderFactor)) solid $color_iron_approx;
			background: $white;
			min-width: (2rem * ($borderFactor));
			height: (2rem * ($borderFactor));
			line-height: (2rem * ($heightFactor));
			font-size: 1.143rem;
			z-index: 1;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 50%;
		}
		.p-steps-title {
			margin-top: (0.5rem *($marginControlFactor) );
			color: $color_gray_approx;
		}
		&:not(.p-disabled):focus {
			outline: 0 none;
			outline-offset: 0;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
		}
	}
	&.p-highlight {
		.p-steps-number {
			background: $color_lochmara_approx;
			color: $white;
		}
		.p-steps-title {
			font-weight: 700;
			color: $color_mine_shaft_approx;
		}
	}
	&:before {
		content: " ";
		border-top: (0.063rem * ($borderFactor)) solid $color_mischka_approx;
		width: 100%;
		top: 50%;
		left: 0;
		display: block;
		position: absolute;
		margin-top: (-1rem *($marginControlFactor) );
	}
}
.p-tabmenu .p-tabmenu-nav {
	background: transparent;
	border: 0 none;
	border-width: (0.063rem * ($borderFactor));
	.p-tabmenuitem {
		margin-right: 00.125rem;
		.p-menuitem-link {
			border: (0.063rem * ($borderFactor)) solid $color_silver_approx;
			border-width: (0.063rem * ($borderFactor));
			border-color: $color_silver_approx;
			background: $wild_sand;
			color: $color_mine_shaft_approx;
			padding: (0.857rem *($paddingControlFactor) ) (1rem *($paddingControlFactor) );
			font-weight: 700;
			//Instead of the line below you could use @include border-top-right-radius($radius)
			border-top-right-radius: 0.188rem;
			//Instead of the line below you could use @include border-top-left-radius($radius)
			border-top-left-radius: 0.188rem;
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 0.2s, box-shadow 0.2s;
			margin: 0 0 (-(0.063rem * ($borderFactor))) 0;
			.p-menuitem-icon {
				margin-right: (0.5rem * ($marginControlFactor));
			}
			&:not(.p-disabled):focus {
				outline: 0 none;
				outline-offset: 0;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
			}
		}
		&:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
			background: $alto;
			border-color: $alto;
			color: $color_mine_shaft_approx;
		}
		&.p-highlight .p-menuitem-link {
			background: $color_lochmara_approx;
			border-color: $color_lochmara_approx;
			color: $white;
		}
	}
}
.p-galleria-mask.p-component-overlay {
	background-color: $black_90;
}
.p-avatar-group .p-avatar {
	border: 00.125rem solid $white;
}
.p-blockui.p-component-overlay {
	background: $black_40;
}
.p-inplace .p-inplace-display {
	padding: (0.429rem *($paddingControlFactor)) (0.429rem *($paddingControlFactor));
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0.188rem;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	&:focus {
		outline: 0 none;
		outline-offset: 0;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
	}
	&:not(.p-disabled):hover {
		background: $color_mercury_approx;
		color: $color_mine_shaft_approx;
	}
}

.p-treeselect {
  background: $wild_sand;
  border: 1px solid $color_silver_approx;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0.188rem;
}
.p-treeselect:not(.p-disabled):hover {
  border-color: $color_mine_shaft_approx;
}
.p-treeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
  border-color: $color_malibu_approx1;
}
.p-treeselect .p-treeselect-label {
  padding: (0.429rem *($paddingControlFactor) ) (0.429rem *($paddingControlFactor) );
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-treeselect .p-treeselect-label.p-placeholder {
  color: $color_dove_gray_approx
}
.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: (0.2145rem *($paddingControlFactor) ) (0.429rem*($paddingControlFactor) );
  margin-right: (0.5rem * ($marginControlFactor)) ;
  background: $white;
  color: $color_mine_shaft_approx;
  border-radius: 16px;
}
.p-treeselect .p-treeselect-trigger {
  background: transparent;
  color: $color_gray_approx;
  width: (2.357rem * ($widthFactor));
  border-top-right-radius: 0.188rem;
  border-bottom-right-radius: 0.188rem;
}

p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
  border-color: $color_bright_red_approx;
}

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: (0.2145rem *($paddingControlFactor) ) (0.429rem *($paddingControlFactor) );
}

.p-treeselect-panel {
  background: $white;
  color: $color_mine_shaft_approx;
  border: 1px solid $color_mercury_approx;
  border-radius: 0.188rem;
  box-shadow: 0 0.188rem 6px 0 rgba(0, 0, 0, 0.16);
}
.p-treeselect-panel .p-treeselect-header {
  padding: (0.429rem*($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
  border-bottom: 1px solid $color_mercury_approx;
  color: $color_mine_shaft_approx;
  background: $white;
  margin: 0;
  border-top-right-radius: 0.188rem;
  border-top-left-radius: 0.188rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
  margin-right: ( 0.5rem * ($marginControlFactor));
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
  padding-right: (1.429rem *($paddingControlFactor) );
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
  right: (0.429rem *($paddingRightFactor) );
  color: $color_gray_approx;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
  padding-right: (2.858rem *($paddingControlFactor) );
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
  right: ( 1.858rem *($paddingRightFactor) );
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
  width: (2rem * ($borderFactor));
  height: (2rem * ($borderFactor));
  color: $color_silver_approx;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
  color: $color_lochmara_approx;
  border-color: transparent;
  background: transparent;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $color_malibu_approx1;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0 none;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
  padding: (0.429rem *($paddingControlFactor) ) (0.857rem *($paddingControlFactor) );
  color: $mine_shaft;
  background: transparent;
}

.p-input-filled .p-treeselect {
  background: $white;
}
.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background: $color_mercury_approx;
}
.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  background-color: $white;
}

p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
  padding-right: (1.429rem *($paddingRightFactor));
}
p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
  color: $color_gray_approx;
  right: (2.357rem *($paddingRightFactor));
}
