@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.css";
@import "./assets/styles/matStyles.scss";

// bootstrap minimale configuration laden
@import "../node_modules/bootstrap/scss/functions";

@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";
// 5. Include remainder of required parts
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
// @import "../node_modules/bootstrap/scss/utilities";
// @import "../node_modules/bootstrap/scss/reboot";
// @import "../node_modules/bootstrap/scss/type";
// @import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
// @import "../node_modules/bootstrap/scss/grid";
// @import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/alert";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
// @import "../node_modules/bootstrap/scss/utilities/api";

/*Defaults only die überschrieben werden*/
:root {
  /*Background allgemein*/
  --backgroundCommonContent: white;
  /*Background disabled allgemein*/
  --backgroundCommonContentDisabled: #eaeaea;
  --backgroundCommonContentDisabledTransparent: #eaeaea6b;
  /*Background disabled Button*/
  --backgroundButtonDisabled: #8dcdff;
  /*disabled color algemein*/
  --colorCommonContentDisabled: black;
  /*Unter Überschrift in Tabelle */
  --colorTableHeader: darkgray;
  /*Menufarben Navigation*/
  --colorMenu: black;
  --backgroundMenu: white;
  --colorMenuIcon: rgb(48, 46, 46);
  /*Menu Ende*/

  /*Aktives Item */
  --colorItemActive: #05273b;
  /*Backround aktiv*/
  --backgroundActive: #eeebcf;
  /*Trenner Liste */
  --colorDivider: gray;
  /*Aktion wie Add im Header einer Seite z.B. medientipps*/
  --colorActionButtonIcon: darkgray;
}

/*Vergrößern wegen langer Texte workflow Dropdown*/
app-wupview #workflowmenu .p-tieredmenu {
  width: 15.6rem !important;
}

/*titel Icon einer seite*/
.pageTitleIcon {
  margin-top: auto;
  color: var(--colorMenuIcon);
  margin-left: 0.75rem;
  margin-bottom: auto;
}

/*Titel Text Css einer Seite*/
.pageTitleHeader {
  padding-top: 0.3rem;
  padding-left: 0.3rem;
  margin-right: 1.875rem;
  margin-left: 1rem;
}

/*Add Icon auf Page*/
.pageAddButtonIcon {
  border: 1px;
  border-style: solid;
  border-color: var(--colorActionButtonIcon);
  font-size: var(--fontSizeDefault);
  height: (var(--fontSizeDefault) + 3);
  color: var(--colorActionButtonIcon);
  margin-top: auto;
  margin-bottom: auto;
}

/*Header Standard für Lipo*/
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-color);
  background: transparent;
  padding-left: 1rem;
  font-weight: 500;
}

/*Children Panel */
.mat-menu-panel.matpanel {
  background-color: var(--backgroundCommonContent);
  font-size: var(--fontSizeDefault);
}

/*Ende Styling hell/dunkel overwrides*/
.fc .fc-list-day-cushion .fc-list-day-text {
  color: var(--text-color);
  text-decoration: none !important;
}

.fc .fc-col-header-cell-cushion {
  color: var(--text-color);
  text-decoration: none !important;
}

.fc .fc-list-day-cushion {
  color: var(--text-color);
  text-decoration: none !important;
}

.fc .fc-timeline-slot-cushion {
  color: var(--text-color);
  text-decoration: none !important;
}

.fc-widget-header {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  text-decoration: none !important;
}

.fc-toolbar h2 {
  font-size: 1.25rem !important;
  text-decoration: none !important;
  color: var(--text-color);
}

app-calendar fc-resourceTimelineWeek-button fc-button-primary {
  background-color: cyan !important;
}

app-calendar fc-resourceTimelineWeek-button fc-button-primary fc-button-active {
  background-color: grey !important;
}

/*Kalender Ende */

.listbox100prozent .ui-listbox-list {
  width: 100vh;
}

.dropdown100prozent .p-dropdown .p-dropdown-label {
  width: 100vh;
}

html,
body {
  height: 100%;
  margin: 0;
}

html,
head,
body,
footer {
  font-family: Open Sans, sans-serif;
  color: var(--text-color);
  background: var(--backgroundCommonContent);
}

//GRößen Variablen laden
.size_1rem_light {
  @import "./assets/styles/light/_size_1rem_light.scss";
}

.size_1rem_dark {
  @import "./assets/styles/dark/_size_1rem_dark.scss";
}

.size_0875rem_light {
  @import "./assets/styles/light/_size_0875rem_light.scss";
}

.size_0875rem_dark {
  @import "./assets/styles/dark/_size_0875rem_dark.scss";
}

.size_08125rem_light {
  @import "./assets/styles/light/_size_08125rem_light.scss";
}

.size_08125rem_dark {
  @import "./assets/styles/dark/_size_08125rem_dark.scss";
}

.size_09375rem_light {
  @import "./assets/styles/light/_size_09375rem_light.scss";
}

.size_09375rem_dark {
  @import "./assets/styles/dark/_size_09375rem_dark.scss";
}

.p-datatable .p-datatable-thead > tr > th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contentAuto .p-datatable > tr {
  content-visibility: hidden;
}

.stickytable .p-datatable-scrollable-table > .p-datatable-thead {
  position: sticky;
  z-index: 2;
}

/*frozen im Hintergrund checkbox, dank Norbert */
.checkboxtoback .p-datatable-resizable-table > .p-datatable-tbody > tr > td {
  z-index: 1;
}

/*Zeielnumbruch dialog primeng*/
.p-dialog-content {
  white-space: pre-line;
}

/*Badge verhält sich anders seit material 15 --> schmarrn SH*/
.mat-badge {
  font-size: calc(0.9 * var(--fontSizeSmaller));
  overflow: visible !important;
}

.mat-badge-content {
  font-family: "Open Sans", sans-serif;
  font-size: calc(var(--fontSizeSmaller));
}

.mdc-list-item__content {
  overflow: visible !important;
}

.mat-mdc-list-base {
  overflow: visible !important;
}

.mdc-list-item {
  overflow: visible !important;
}

.mdc-list-item__primary-text {
  overflow: visible !important;
}

/**allgemeine invalid Style */
.invalidClass input {
  border-color: red !important;

  .p-inputtext input .p-inputnumber {
    border-color: red;
  }

  .p-calendar {
    border-color: red !important;
  }

  .p-dropdown .p-dropdown-label {
    border-color: red !important;
  }
}

.overlayResetClass {
  background: var(--backgroundActive) !important;
}

.hasOverlayDataClass {
  color: var(--highlightColor) !important;
  background: var(--highlightBackground) !important;

  .div {
    color: var(--highlightColor) !important;
    background: var(--highlightBackground) !important;
  }
  .p-dropdown-label {
    color: var(--highlightColor) !important;
    background: var(--highlightBackground) !important;
  }

  .p-calendar .p-inputtext {
    color: var(--highlightColor) !important;
    background: var(--highlightBackground) !important;
  }

  .p-inputnumber .p-inputnumber-input input {
    color: var(--highlightColor);
    background: var(--highlightBackground);
  }

  .p-inputtext input {
    color: var(--highlightColor) !important;
    background: var(--highlightBackground) !important;
  }
}


.alert-primary
{
    background-color: var(--highlightBackgroundPrimary);
    border-color: var(--highlightBackgroundPrimary);
    color: var(--bs-alert-color);
}

.alert-info
{
    background-color: var(--highlightBackgroundInfo);
    border-color: var(--highlightBackgroundInfo);
    color: var(--bs-alert-color);
}

.alert-success
{
    background-color: var(--highlightBackgroundSuccess);
    border-color: var(--highlightBackgroundSuccess);
    color: var(--bs-alert-color);
}

.buttonmenuStyle {
  line-height: normal;
  bottom: 0.2rem;
  margin-left: 0.2rem;
}
