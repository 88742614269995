/*Material  https://materialtheme.arcsine.dev/*/
@use '@angular/material' as mat;
// https://medium.com/@treviergits/angular-material-theme-color-options-7d5968cb7460




@include mat.core();

$lipotypogr: mat.m2-define-typography-config(
  $font-family: '"Open Sans",sans-serif',
);



$angular-primary: mat.m2-define-palette(mat.$m2-blue-palette, 500);
$angular-accent: mat.m2-define-palette(mat.$m2-green-palette);
$angular-warn: mat.m2-define-palette(mat.$m2-red-palette);

$angular-primary-light: mat.m2-define-palette(mat.$m2-blue-palette, 500);
$angular-accent-light: mat.m2-define-palette(mat.$m2-green-palette);
$angular-warn-light: mat.m2-define-palette(mat.$m2-red-palette);


$angular-primary-dark: mat.m2-define-palette(mat.$m2-light-blue-palette, 500);
$angular-accent-dark: mat.m2-define-palette(mat.$m2-light-green-palette);
$angular-warn-dark: mat.m2-define-palette(mat.$m2-amber-palette);



$angular-dark-theme: mat.m2-define-dark-theme(
  (
    typography: $lipotypogr,

    color: (
      primary: $angular-primary-dark,
      accent: $angular-accent-dark,
      warn: $angular-warn-dark,
      foreground: var(--text-color),
      background: var(--backgroundCommonContent)
    ),

  )
);

.darkMode {
  @include mat.all-component-colors($angular-dark-theme);
}

$angular-light-theme: mat.m2-define-light-theme(
  (
    typography: $lipotypogr,
    color: (
      primary: $angular-primary-light,
      accent: $angular-accent-light,
      warn: $angular-warn-light,
      foreground: var(--text-color),
      background: var(--backgroundCommonContent),
    ),

  )
);


.lightMode {
  @include mat.all-component-colors($angular-light-theme);
}



$angular-default-theme: mat.m2-define-light-theme(
  (
    typography: $lipotypogr,
    color: (
      primary: $angular-primary,
      accent: $angular-accent,
      warn: $angular-warn,
      foreground: var(--text-color),
      background: var(--backgroundCommonContent),
    ),

  )
);


// @include angular-material-theme($angular-default-theme);




